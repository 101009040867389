import { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import TooltipPopover from '../../components/TooltipPopover';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import ConfirmButton from '../../components/ConfirmButton';
import Icon from '../../components/Icon';
import { tr } from '../../common/locale';
import useLnUsersActions from '../../_actions/lnusers.actions';
import { bulkTasksState, bulkShowCampaignState, loadingBulkTasksState } from '../../_states/lnusers.states';
import { userState } from '../../_states/user.states';

function BulkTasks() {
    const loadingBulkTasks = useRecoilValue(loadingBulkTasksState);
    const [bulkShowCampaign, setBulkShowCampaign] = useRecoilState(bulkShowCampaignState);
    const actions = useLnUsersActions();
    const bulkTasks = useRecoilValue(bulkTasksState);
    const user = useRecoilValue(userState);
    const fakeLoader = [];
    for (let i = 0; i <= 20; i++) {
        fakeLoader.push(
            <div key={i} className="list__fake list__fake--team">
                <div className="list__col list__col--spacer" />
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>

                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
            </div>
        );
    }

    const checkTaskQuota = (task) => {
        let result = true;
        if (task.action === 'BULK_MESSAGE') {
            if (user?.quotas?.daily_quota_messages_used >= user?.quotas?.daily_quota_messages_conf) {
                result = false;
            }
        }
        if (task.action === 'BULK_CONNECTION') {
            if (user?.quotas?.daily_quota_invitations_used >= user?.quotas?.daily_quota_invitations_conf) {
                result = false;
            }
        }
        if (task.action === 'BULK_SYNC') {
            if (user?.quotas?.daily_import_standard_used >= user?.quotas?.daily_import_standard_conf) {
                result = false;
            }
        }
        console.log('checkTaskQuota', task.action, result, user);
        return result;
    };
    useEffect(() => {
        actions.getBulkTasks(true);
    }, [bulkShowCampaign]);
    const hasMore = bulkTasks && bulkTasks.count > bulkTasks.items.length;
    return (
        <div className="asynchronous">
            <div className="asynchronous__header">
                <h1>{tr('Processing LinkedIn Tasks')}</h1>
                <Button
                    type="tab"
                    className={`${!bulkShowCampaign ? 'active' : ''}`}
                    label={tr('Bulk actions')}
                    onClick={() => setBulkShowCampaign(false)}
                />
                <Button
                    type="tab"
                    className={`${bulkShowCampaign ? 'active' : ''}`}
                    label={tr('Campaigns')}
                    onClick={() => setBulkShowCampaign(true)}
                />
            </div>
            <div className="list__header">
                <div className="list__cols">
                    <div className="list__col list__col--spacer" />
                    <div className="list__col list__col--larger">{tr('User')}</div>
                    <div className="list__col list__col--large">{tr('Action')}</div>
                    <div className="list__col list__col--fill">{tr('Origin')}</div>
                    <div className="list__col list__col--large">{tr('Scheduled date')}</div>
                    <div className="list__col">{tr('Status')}</div>
                    <div className="list__col list__col--small list__col--action" />
                </div>
            </div>
            <div
                id="asynchronousContent"
                className={`asynchronous__content list--pattern ${
                    loadingBulkTasks || (bulkTasks && bulkTasks.items.length > 0) ? '' : 'list--empty'
                }`}
            >
                {loadingBulkTasks && (!bulkTasks || bulkTasks?.items.length === 0) && fakeLoader}
                {!loadingBulkTasks && bulkTasks && bulkTasks.items.length === 0 && (
                    <div className="empty">
                        <Icon isEmpty icon="empty-automations" size={300} />
                        <h1>{tr('No tasks')}</h1>
                    </div>
                )}
                {bulkTasks?.items.length > 0 && (
                    <InfiniteScroll
                        next={actions.getBulkTasks}
                        dataLength={bulkTasks?.items.length || 0}
                        className="list"
                        hasMore={hasMore}
                        loader={fakeLoader}
                        scrollableTarget="asynchronousContent"
                    >
                        {bulkTasks &&
                            bulkTasks.items.map((task) => (
                                <div className="list__item" key={task.id}>
                                    <div className="list__row">
                                        <div className="list__cols">
                                            <div className="list__col list__col--spacer" />
                                            <div className="list__col list__col--larger">
                                                <div className="team-avatar">
                                                    <Avatar
                                                        className="avatar"
                                                        src={task.lnuser?.lead.picture}
                                                        alt={`${task.lnuser?.lead.firstname} ${task.lnuser?.lead.lastname}`}
                                                    />
                                                    {task.lnuser && (
                                                        <span className="ellipsis">
                                                            {task.lnuser.lead.firstname}&nbsp;
                                                            {task.lnuser.lead.lastname}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="list__col list__col--large bold black">
                                                {task.action === 'BULK_ARCHIVE' ? tr('Archive') : ''}
                                                {task.action === 'BULK_CONNECTION' ? tr('Connection request') : ''}
                                                {task.action === 'BULK_MESSAGE' ? tr('Send message') : ''}
                                                {task.action === 'BULK_UNARCHIVE' ? tr('Unarchive') : ''}
                                                {task.action === 'BULK_WITHDRAW'
                                                    ? tr('Withdraw connection request')
                                                    : ''}
                                                {task.action === 'BULK_READ' ? tr('Mark as read') : ''}
                                                {task.action === 'BULK_UNREAD' ? tr('Mark as unread') : ''}
                                                {task.action === 'BULK_SYNC' ? tr('Sync profile') : ''}
                                                {task.action === 'BULK_ACCEPT_INVITE' ? tr('Accept invitation') : ''}
                                                {task.action === 'BULK_WITHDRAW_INVITE' ? tr('Ignore invitation') : ''}
                                                {task.action === 'VISIT' ? tr('Visit Profile') : ''}
                                                {task.action === 'DEL_INVITE' ? tr('Delete Request') : ''}
                                                {task.action === 'CONNECT' ? tr('Request Connection') : ''}
                                                {task.action === 'FOLLOW' ? tr('Follow Member') : ''}
                                                {task.action === 'LIKE' ? tr('Like Post') : ''}
                                                {task.action === 'MESSAGE1' ? tr('Send message') : ''}
                                                {task.action === 'MESSAGE_1' ? tr('Send message') : ''}
                                                {task.action === 'MESSAGE_OP' ? tr('Send message to OP') : ''}
                                                {task.action === 'MESSAGE_2' ? tr('Send Follow-up 1') : ''}
                                                {task.action === 'MESSAGE_3' ? tr('Send Follow-up 2') : ''}
                                                {task.action === 'MESSAGE_4' ? tr('Send Follow-up 3') : ''}
                                                {task.action === 'MESSAGE_5' ? tr('Send Follow-up 4') : ''}
                                                {task.action === 'MESSAGE_6' ? tr('Send Follow-up 5') : ''}
                                                {task.action === 'MESSAGE_7' ? tr('Send Follow-up 6') : ''}
                                            </div>
                                            <div className="list__col list__col--fill">
                                                {task?.campaign?.name || 'Bulk action'}
                                            </div>
                                            <div className="list__col list__col--large">
                                                {moment(task?.scheduled_at).format('LLL')}
                                            </div>
                                            <div className="list__col">
                                                {task.status === 'WARN' && (
                                                    <TooltipPopover
                                                        text={task.error || tr('NA')}
                                                        placement="left-center"
                                                    >
                                                        <span className="status status--orange">{task.status}</span>
                                                    </TooltipPopover>
                                                )}
                                                {task.status === 'ERROR' && (
                                                    <TooltipPopover
                                                        text={task.error || tr('NA')}
                                                        placement="left-center"
                                                    >
                                                        <span className="status status--red">{task.status}</span>
                                                    </TooltipPopover>
                                                )}
                                                {task.status === 'SCHEDULED' && !checkTaskQuota(task) && (
                                                    <div>
                                                        <span className="status status--orange">
                                                            {tr('Quota - Scheduled')}
                                                        </span>
                                                    </div>
                                                )}
                                                {task.status === 'SCHEDULED' && checkTaskQuota(task) && (
                                                    <div>
                                                        <span className="status status--yellow">{tr('Scheduled')}</span>
                                                    </div>
                                                )}
                                                {task.status === 'DONE' && (
                                                    <div>
                                                        <span className="status status--green">{task.status}</span>
                                                    </div>
                                                )}
                                                {(task.status === 'PROCESSING_BROWSER' ||
                                                    task.status === 'PROCESSING') && (
                                                    <div>
                                                        <span className="status status--processing">{task.status}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="list__col list__col--small list__col--action">
                                                {(task.status === 'PROCESSING_BROWSER' ||
                                                    task.status === 'SCHEDULED') &&
                                                    !task.campaign && (
                                                        <ConfirmButton
                                                            type="link"
                                                            icon="trash-can"
                                                            confirmLabel={tr(
                                                                'Do you confirm you want to delete this task?'
                                                            )}
                                                            onConfirm={() => actions.deleteTask(task.id)}
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
}
export default BulkTasks;
