import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import Icon from './Icon';
import { tr } from '../common/locale';

export default class Button extends Component {
    constructor(props) {
        super(props);
        this.elementRef = React.createRef();
    }

    render() {
        const {
            size,
            type,
            isRound,
            isFillIcon,
            icon,
            iconSize,
            className,
            disabled,
            label,
            onClick,
            onMouseEnter,
            onMouseLeave,
            isLoading,
            active,
            children,
            testId,
            iconRight,
            isToggle,
            isNotification,
            iconLight,
            isBordered,
            ...rest
        } = this.props;
        const classes = cl(
            'btn',
            { 'btn--has-icon': icon || isLoading },
            { 'btn--icon': icon && !label },
            { 'btn--toggle': isToggle },
            { 'btn--round': isRound },
            { 'btn--bordered': isBordered },
            { 'btn--icon-right': iconRight },
            size ? `btn--${size}` : null,
            type ? `btn--${type}` : null,
            { active },
            isNotification ? 'btn--notification' : null,
            { 'btn--loading': isLoading },
            { 'btn--no-label': !label },
            { 'btn--custom-icon-size': iconSize && iconSize !== 22 },
            className
        );
        let iconComponent = icon ? <Icon light={iconLight} fill={isFillIcon} size={iconSize} icon={icon} /> : null;
        if (isLoading) {
            iconComponent = <Icon fill icon="load" />;
        }
        return (
            <button
                ref={this.elementRef}
                type="button"
                aria-label={label}
                disabled={disabled}
                onClick={isLoading || disabled ? undefined : onClick}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
                {...rest}
                className={classes}
                data-testid={testId}
            >
                {!iconRight ? iconComponent : null}
                {label && <span className="btn__label">{isLoading ? tr('Loading...') : label}</span>}
                {iconRight ? iconComponent : null}
                {children}
            </button>
        );
    }
}

Button.defaultProps = {
    testId: 'button',
    isRound: false,
    isToggle: false,
    disabled: false,
    children: null,
    size: undefined,
    type: 'primary',
    className: '',
    label: '',
    icon: null,
    iconSize: 22,
    isLoading: false,
    active: false,
    onClick: null,
    onMouseEnter: null,
    onMouseLeave: null,
    iconRight: false,
    isNotification: false,
    isFillIcon: false,
    iconLight: false,
    isBordered: false,
};

Button.propTypes = {
    testId: PropTypes.string,
    isRound: PropTypes.bool,
    isToggle: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'small', 'mini', 'nano', undefined]),
    type: PropTypes.oneOf([
        'campaign',
        'green',
        'primary',
        'black',
        'switch',
        'secondary',
        'secondary-dark',
        'secondary-blue',
        'secondary-white',
        'important',
        'transparent',
        'bulk',
        'list-action',
        'inverse',
        'link-inline',
        'link-primary',
        'link-red',
        'link-black',
        'link-info',
        'link',
        'group',
        'dropdown-item',
        'tab',
        'tab-switch',
        'sidebar-link',
        'white',
        'yellow',
        'pink',
        'violet',
        'green-sf',
        'openprofile',
        'dark-mode',
        'campagain',
    ]),
    isLoading: PropTypes.bool,
    active: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    children: PropTypes.node,
    iconRight: PropTypes.bool,
    isNotification: PropTypes.bool,
    isFillIcon: PropTypes.bool,
    iconLight: PropTypes.bool,
    isBordered: PropTypes.bool,
};
