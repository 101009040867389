import { useEffect, useState, useRef } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import 'rc-slider/assets/index.css';
import { tr, getLocale } from '../../common/locale';
import useUserActions from '../../_actions/user.actions';
import { previewSubscriptionUpdateLoadingState, previewSubscriptionUpdateState } from '../../_states/user.states';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import utils from '../../common/utils';

function ModalSubscribe({
    closeCb,
    open,
    pricing,
    selectedPlan,
    currentPlan,
    currentQuantity,
    currency,
    currencyIcon,
    defaultSubscriptions,
    defaultSliderPlanValue,
    defaultIsMonth,
}) {
    const actions = useUserActions();
    const plans = ['starter', 'essential', 'pro'];
    const [isMonth, setIsMonth] = useState(false);
    const [plan, setPlan] = useState(currentPlan);
    const [quantity, setQuantity] = useState({});
    const previewSubscriptionUpdateLoading = useRecoilValue(previewSubscriptionUpdateLoadingState);
    const [previewSubscriptionUpdate, setPreviewSubscriptionUpdate] = useRecoilState(previewSubscriptionUpdateState);

    const [sliderPlanValue, setSliderPlanValue] = useState(0);
    const marks = {
        0: '0',
        25: '1 K',
        50: '2 K',
        75: '5 K',
        100: '10 K',
    };
    const locale = getLocale();
    const modal = useRef();
    useEffect(() => {
        if (selectedPlan && selectedPlan !== 'TRIAL') {
            if (defaultSliderPlanValue) {
                setSliderPlanValue(defaultSliderPlanValue);
            }
            if (defaultIsMonth !== undefined) {
                setIsMonth(defaultIsMonth);
            }
            const newQt = {};
            setPlan(selectedPlan);
            plans.forEach((current) => {
                newQt[current] = current === selectedPlan ? 1 : 0;
            });
            setQuantity(newQt);
            modal.current.open();
        }
    }, [selectedPlan]);
    useEffect(() => {
        setPlan(currentPlan);
        const newQt = {};
        plans.forEach((current) => {
            newQt[current] = current === currentPlan ? currentQuantity : 0;
        });
        setQuantity(newQt);
    }, [currentPlan]);

    useEffect(() => {
        if (open && modal.current) {
            modal.current.open();
        }
    }, [open]);
    const getSliderDefaultVal = (subs) => {
        const emails = subs?.find((item) => item.product === 'EMAIL_CREDITS');
        if (emails) {
            const defaultVal = Object.keys(marks).find(
                (idx) => parseInt(marks[idx].replace(',', ''), 10) === emails.quantity
            );
            return defaultVal;
        }
        return 0;
    };
    const getDefaultQt = (subs) => {
        const newQt = {};
        plans.forEach((current) => {
            const sub = subs.find((item) => item.product.toLowerCase() === current);
            newQt[current] = sub ? sub.quantity : 0;
        });
        return newQt;
    };
    useEffect(() => {
        if (defaultSubscriptions) {
            if (defaultIsMonth !== undefined) {
                setIsMonth(defaultIsMonth);
            }
            console.log('defaultSubscriptions', getSliderDefaultVal(defaultSubscriptions));
            setSliderPlanValue(getSliderDefaultVal(defaultSubscriptions));
            const newQt = {};
            plans.forEach((current) => {
                const sub = defaultSubscriptions.find((item) => item.product.toLowerCase() === current);
                newQt[current] = sub ? sub.quantity : 0;
            });
            setQuantity(getDefaultQt(defaultSubscriptions));
        }
    }, [defaultSubscriptions]);
    console.log('isMonth', isMonth, defaultIsMonth);
    const isSubsriptionModified =
        !defaultSubscriptions ||
        (defaultSubscriptions &&
            Object.keys(quantity).length > 0 &&
            (isMonth !== defaultIsMonth ||
                getSliderDefaultVal(defaultSubscriptions) !== sliderPlanValue ||
                !utils.deepEqual(getDefaultQt(defaultSubscriptions), quantity)));
    useEffect(() => {
        if (isSubsriptionModified && defaultSubscriptions) {
            actions.checkout(isMonth, 0, quantity, defaultSubscriptions, true);
        } else {
            setPreviewSubscriptionUpdate(null);
        }
    }, [sliderPlanValue, quantity, isMonth]);

    const changeQuantity = (current, inVal) => {
        const val = parseInt(inVal, 10) || 0;
        const newQt = { ...quantity };

        plans.forEach((item) => {
            newQt[item] = item === current ? val : 0;
        });

        setQuantity(newQt);
    };
    let pricePerMonth = 0;
    if (pricing) {
        plans.forEach((item) => {
            const unit = isMonth ? pricing[item].currency[currency].monthly : pricing[item].currency[currency].yearly;
            pricePerMonth += unit * quantity[item];
        });
    }

    if (!pricing) {
        return null;
    }
    const price = isMonth ? pricePerMonth : pricePerMonth * 12;
    let noQuantity = true;
    plans.forEach((item) => {
        if (quantity[item] > 0) {
            noQuantity = false;
        }
    });
    console.log('current plan', plan, quantity, quantity[plan]);
    return (
        <Modal
            className="subscribe"
            labelValidate={defaultSubscriptions ? tr('Validate') : tr('Checkout')}
            ref={modal}
            title={defaultSubscriptions ? tr('Modify your current subscription') : tr('Define your subscription')}
            disabled={previewSubscriptionUpdateLoading || !isSubsriptionModified || noQuantity}
            validateCb={() => {
                actions.checkout(
                    isMonth,
                    parseInt(marks[sliderPlanValue].replace(',', ''), 10),
                    quantity,
                    defaultSubscriptions
                );
            }}
            closeCb={closeCb}
        >
            <div className="subscribe__wrapper">
                <div className="billing-choice">
                    <div className="btn--tab-switch--group">
                        <Button
                            type="tab-switch"
                            disabled={isMonth}
                            onClick={() => setIsMonth(!isMonth)}
                            label={tr('Pay monthly')}
                        />
                        <Button
                            type="tab-switch"
                            disabled={!isMonth}
                            onClick={() => setIsMonth(!isMonth)}
                            label={tr('Pay annually')}
                        />
                    </div>
                </div>
                <div className="subscribe__content">
                    <div className="subscribe__conditions">
                        <h2 className="title">
                            {isMonth
                                ? 'No commitment, pay monthly, and cancel anytime'
                                : 'Get advantageous rates with an annual subscription'}
                        </h2>
                    </div>
                    <div className="subscribe__plans">
                        <h2 className="title">{tr('Choose a plan')}</h2>
                        <div className="subscribe__plans__tab">
                            {plans.map((current) => (
                                <div
                                    key={current}
                                    className={`subscribe__plan-item ${plan === current ? 'active' : ''}`}
                                    onClick={() => {
                                        setPlan(current);
                                        changeQuantity(current, currentQuantity || 1);
                                    }}
                                    onKeyDown={() => {
                                        setPlan(current);
                                        changeQuantity(current, currentQuantity || 1);
                                    }}
                                    role="button"
                                    tabIndex={-1}
                                >
                                    <div className="subscribe__plan-item__header">
                                        <div className="subscribe__plan-item__name">
                                            {pricing[current].name.toLowerCase()}
                                        </div>
                                        {currencyIcon !== '€' ? (
                                            <div className="subscribe__plan-item__price">
                                                <span>{currencyIcon}</span>
                                                <span>
                                                    {isMonth
                                                        ? pricing[current].currency[currency].monthly
                                                        : pricing[current].currency[currency].yearly}
                                                </span>
                                                <span>{!isMonth ? ' x 12' : ' / month'}</span>
                                            </div>
                                        ) : (
                                            <div className="subscribe__plan-item__price">
                                                <span>
                                                    {isMonth
                                                        ? pricing[current].currency[currency].monthly
                                                        : pricing[current].currency[currency].yearly}
                                                </span>
                                                <span>{currencyIcon}</span>
                                                <span>{!isMonth ? ' x 12' : ' / month'}</span>
                                            </div>
                                        )}
                                    </div>
                                    <ul className="plans-limits">
                                        {pricing.highlight_features.map((feat) => {
                                            if (pricing[current][`features_${locale}`][feat]) {
                                                return (
                                                    <li key={feat}>
                                                        <Icon size="10" icon="check" />
                                                        <span>{pricing[current][`features_${locale}`][feat]}</span>
                                                    </li>
                                                );
                                            }
                                            return null;
                                        })}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="subscribe__seats">
                        <h2 className="title">{tr('Choose the number of seats')}</h2>

                        <div className="subscribe__seats-qty">
                            <Button
                                isBordered
                                icon="minus"
                                type="white"
                                disabled={quantity[plan] === 0}
                                onClick={() => changeQuantity(plan, quantity[plan] - 1)}
                            />
                            <Input
                                type="number"
                                value={`${quantity[plan]}`}
                                onChange={(e) => changeQuantity(plan, parseInt(e.target.value, 10))}
                            />
                            <Button
                                icon="plus"
                                isBordered
                                type="white"
                                onClick={() => changeQuantity(plan, quantity[plan] + 1)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {noQuantity && (
                <div className="subscribe__amount alert">
                    <div className="subscribe__month-amount">{tr('Please, select at least one plan.')}</div>
                </div>
            )}
            {!previewSubscriptionUpdateLoading &&
                !noQuantity &&
                !!previewSubscriptionUpdate?.email_credits_prorated && (
                    <div
                        className={`subscribe__amount ${
                            previewSubscriptionUpdate?.email_credits_prorated > 0 ? 'credit' : 'prorata'
                        }`}
                    >
                        <div className="subscribe__month-amount">
                            <div className="prorata__text">
                                {tr('Your account will be immediatly updated with {{count}} email credits.', {
                                    count: previewSubscriptionUpdate?.email_credits_prorated,
                                })}
                            </div>

                            <div className="subscribe__total-amount">
                                {previewSubscriptionUpdate?.email_credits_prorated} credits
                            </div>
                        </div>
                    </div>
                )}
            {!previewSubscriptionUpdateLoading && !noQuantity && !!previewSubscriptionUpdate?.amount_due && (
                <div className="subscribe__amount prorata">
                    <div className="subscribe__month-amount">
                        <div className="prorata__text">
                            {tr('An invoice with the following prorated amount is going to be issued.')}
                        </div>
                        <div className="subscribe__total-amount">
                            {currencyIcon} {previewSubscriptionUpdate?.amount_due / 100}
                        </div>
                    </div>
                </div>
            )}

            {!previewSubscriptionUpdateLoading && !noQuantity && previewSubscriptionUpdate?.ending_balance < 0 && (
                <div className="subscribe__amount credit">
                    <div className="subscribe__month-amount">
                        <div className="prorata__text">
                            {tr(
                                'The difference will be credited to your account and deducted from your upcoming invoices.'
                            )}
                        </div>
                        <div className="subscribe__total-amount">
                            {currencyIcon} {previewSubscriptionUpdate?.ending_balance / 100}
                        </div>
                    </div>
                </div>
            )}
            <div className={`subscribe__amount ${previewSubscriptionUpdate?.amount_due && 'small'}`}>
                <div className="subscribe__month-amount">
                    <div>{tr('Total price: ')}</div>
                    <div className="subscribe__total-amount">
                        {currencyIcon} {price}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default ModalSubscribe;
