import { useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { useNavigate } from 'react-router-dom';
import useFetchWrapper from '../_helpers/fetchWrapper.helpers';
import {
    campaignState,
    campaignsState,
    selectedCampaignState,
    loadingState,
    loadingTasksState,
    tasksState,
    filterProcessedState,
    launchingState,
    statsState,
    loadingStatsState,
    sourcecountState,
    autocompleteState,
    removedMembersState,
} from '../_states/campaign.states';
import { tr } from '../common/locale';
import { snackMessageState } from '../_states/alert.states';
import { userState } from '../_states/user.states';
import useError from '../_helpers/errorsWrapper.helpers';

export default function useLeadActions() {
    const fetchWrapper = useFetchWrapper();
    const setCampaign = useSetRecoilState(campaignState);
    const setSelectedCampaign = useSetRecoilState(selectedCampaignState);
    const navigate = useNavigate();
    const setCampaigns = useSetRecoilState(campaignsState);
    const setTasks = useSetRecoilState(tasksState);
    const setSourcecount = useSetRecoilState(sourcecountState);
    const setAutocomplete = useSetRecoilState(autocompleteState);
    const setRemovedMembers = useSetRecoilState(removedMembersState);
    const setLoading = useSetRecoilState(loadingState);
    const setLaunching = useSetRecoilState(launchingState);
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const setLoadingTasks = useSetRecoilState(loadingTasksState);

    const setStats = useSetRecoilState(statsState);
    const setLoadingStats = useSetRecoilState(loadingStatsState);

    const { fetchNetworkError } = useError();
    const setCampaignAndSelected = (data) => {
        setCampaign(data);
        setSelectedCampaign(JSON.parse(JSON.stringify(data)));
    };

    function updateInList(campaign) {
        const campaigns = getRecoil(campaignsState);
        if (campaigns) {
            const newCampaigns = [...campaigns];
            const index = newCampaigns.findIndex((item) => item.id === campaign?.id);
            if (index !== -1) {
                newCampaigns[index] = campaign;
            }
            setCampaigns(newCampaigns);
        }
    }

    function get(navToId) {
        setLoading(true);
        return fetchWrapper
            .get('campaigns')
            .then((data) => {
                setCampaigns(data);
                if (navToId) {
                    setTimeout(() => {
                        navigate(`/campaigns/${navToId}`);
                    }, 0);
                }

                if (data.length === 0) {
                    setLoading(false);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function getTasks(reset) {
        if (reset) {
            setTasks(null);
        }
        const campaign = getRecoil(campaignState);
        const filterProcessed = getRecoil(filterProcessedState);
        const tasks = getRecoil(tasksState);
        const offset = reset ? 0 : tasks?.items.length || 0;
        setLoadingTasks(true);
        return fetchWrapper
            .get(`campaigns/${campaign.id}/tasks?offset=${offset}&is_processed=${filterProcessed ? 'true' : 'false'}`)
            .then((data) => {
                const currentItems = reset ? [] : getRecoil(tasksState)?.items || [];
                const newItems = { count: data.count, items: [...currentItems, ...data?.items] };
                setTasks(newItems);
                setLoadingTasks(false);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function save() {
        const campaign = getRecoil(campaignState);
        const user = getRecoil(userState);
        if (campaign.id) {
            return fetchWrapper
                .put(`campaigns/${campaign.id}`, { ...campaign, users: [user.id] })
                .then((data) => {
                    get(data.id);
                    setSnackMessage({ text: tr('Campaign updated'), type: 'info' });
                })
                .catch((e) => {
                    fetchNetworkError(null, e);
                    setSnackMessage({ text: tr('The campaign could not be updated'), type: 'error' });
                });
        }
        return fetchWrapper
            .post('campaigns', { ...campaign, users: [user.id] })
            .then((data) => {
                get(data.id);
                setSnackMessage({ text: tr('Campaign created'), type: 'info' });
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setSnackMessage({ text: tr('The campaign could not be created'), type: 'error' });
            });
    }

    function launch() {
        const campaign = getRecoil(campaignState);
        if (!campaign) {
            return null;
        }
        setLaunching(true);
        return fetchWrapper
            .post(`campaigns/${campaign.id}/launch`)
            .then((data) => {
                setCampaignAndSelected(data);
                get();
                setLaunching(false);
                setSnackMessage({ text: tr('Campaign launched'), type: 'info' });
            })
            .catch((e) => {
                setLaunching(false);
                fetchNetworkError(null, e);
                setSnackMessage({ text: tr('The campaign could not be launched'), type: 'error' });
            });
    }

    function getStats(id) {
        setLoadingStats(true);
        return fetchWrapper
            .get(`campaigns/${id}/stats`)
            .then((data) => {
                setStats(data);
                setLoadingStats(false);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function getCampaign(id) {
        return fetchWrapper
            .get(`campaigns/${id}`)
            .then((data) => {
                setCampaignAndSelected(data);
                updateInList(data);
                setLoading(false);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function refreshStatsTasks() {
        const currentCampaign = getRecoil(campaignState);
        if (currentCampaign && currentCampaign.id && currentCampaign?.status === 'PLAYING') {
            getStats(currentCampaign.id);
            getTasks();
            const stats = getRecoil(statsState);
            if (stats && stats.source_count === stats.finished) {
                getCampaign(currentCampaign.id);
            }
        }
    }

    function getSourceCount(labelId, labelIgnoreId, searchId) {
        if (labelId || labelIgnoreId || searchId) {
            return fetchWrapper
                .get(
                    `campaigns/sourcecount?${labelId ? `label_id=${labelId}` : ''}${
                        labelIgnoreId ? `&label_ignore_id=${labelIgnoreId}` : ''
                    }&${searchId ? `search_id=${searchId}` : ''}`
                )
                .then((data) => {
                    setSourcecount(data);
                })
                .catch((e) => fetchNetworkError(null, e));
        }
        return null;
    }

    function getRemovedMembers(campaign) {
        return fetchWrapper
            .get(`campaigns/${campaign.id}/removemember`)
            .then((data) => {
                setRemovedMembers(data);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function removeMember(campaign, leadId) {
        return fetchWrapper
            .post(`campaigns/${campaign.id}/removemember?lead_id=${leadId}`)
            .then(() => {
                getRemovedMembers(campaign);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function notRemoveMember(campaign, leadId) {
        return fetchWrapper
            .post(`campaigns/${campaign.id}/notremovemember?lead_id=${leadId}`)
            .then(() => {
                getRemovedMembers(campaign);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function autocomplete(labelId, searchId, q, callback) {
        return fetchWrapper
            .get(
                `campaigns/autocomplete?${labelId ? `label_id=${labelId}` : ''}&${
                    searchId ? `search_id=${searchId}` : ''
                }&${q ? `q=${q}` : ''}`
            )
            .then((data) => {
                setAutocomplete(data);
                const result = data.map((item) => ({
                    value: item.id,
                    name: `${item.firstname} ${item.lastname}`,
                    label: `${item.firstname} ${item.lastname}`,
                    firstname: item.firstname,
                    lastname: item.lastname,
                    icebreaker: item.icebreaker,
                    headline: item.headline,
                    industry: item.industry,
                    location: item.location,
                    job: item.job,
                    company: item.company,
                    picture: item.picture,
                }));
                callback(result);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function stop(id) {
        return fetchWrapper
            .post(`campaigns/${id}/stop`)
            .then((data) => {
                get();
                setCampaignAndSelected(data);
                setSnackMessage({ text: tr('Campaign stopped'), type: 'info' });
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setSnackMessage({ text: tr('The campaign could not be stopped'), type: 'error' });
            });
    }

    function finish(id) {
        return fetchWrapper
            .post(`campaigns/${id}/finished`)
            .then((data) => {
                get();
                setCampaignAndSelected(data);
                setSnackMessage({ text: tr('Campaign finished'), type: 'info' });
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setSnackMessage({ text: tr('The campaign could not be finished'), type: 'error' });
            });
    }

    function archive(id) {
        return fetchWrapper
            .post(`campaigns/${id}/archive`)
            .then((data) => {
                get();
                setCampaignAndSelected(data);
                setSnackMessage({ text: tr('Campaign archived'), type: 'info' });
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setSnackMessage({ text: tr('The campaign could not be archived'), type: 'error' });
            });
    }

    function sortCampaigns() {
        const currentCampaigns = getRecoil(campaignsState);
        if (currentCampaigns) {
            const clonedCampaigns = JSON.parse(JSON.stringify(currentCampaigns));
            const status = ['PLAYING', 'QUEUED', 'DRAFT', 'FINISHED', 'ARCHIVED'];
            clonedCampaigns?.sort((a, b) => {
                const result = status.indexOf(a.status) - status.indexOf(b.status);
                if (result === 0) {
                    return a.id - b.id;
                }
                return result;
            });
            return clonedCampaigns;
        }
        return [];
    }

    function deleteCampaign(id) {
        return fetchWrapper
            .delete(`campaigns/${id}`)
            .then(() => {
                const sortedCampaigns = sortCampaigns();
                if (sortedCampaigns?.length > 0) {
                    let idToNavTo = sortedCampaigns[0].id;
                    if (sortedCampaigns[0].id === id) {
                        idToNavTo = sortedCampaigns?.length > 1 ? sortedCampaigns[1].id : 'new';
                    }
                    navigate(`/campaigns/${idToNavTo}`);
                } else {
                    navigate('/campaigns/new');
                }
                get();
                setSnackMessage({ text: tr('Campaign deleted'), type: 'info' });
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setSnackMessage({ text: tr('The campaign could not be deleted'), type: 'error' });
            });
    }

    return {
        autocomplete,
        removeMember,
        notRemoveMember,
        getRemovedMembers,
        sortCampaigns,
        refreshStatsTasks,
        getSourceCount,
        getStats,
        getTasks,
        getCampaign,
        launch,
        get,
        save,
        deleteCampaign,
        finish,
        archive,
        stop,
    };
}
