import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import Select, { components } from 'react-select';
import { Colors } from '../lnuser/LabelsList';
import Label from '../../components/Label';
import Input from '../../components/Input';

import Avatar from '../../components/Avatar';
import Flash from '../../components/Flash';
import { tr } from '../../common/locale';
import { campaignState, sourcecountState } from '../../_states/campaign.states';
import { userState } from '../../_states/user.states';
import useLeadsActions from '../../_actions/leads.actions';
import useCampaignActions from '../../_actions/campaign.actions';
import { searchesState } from '../../_states/leads.states';
import { labelsState } from '../../_states/label.states';

import Dropdown from '../../components/Dropdown';

const LabelOption = (props) => {
    const { data } = props;
    return (
        <components.Option {...props}>
            <Label
                noBg
                key={data.id}
                label={data.name}
                color={Colors[data.color].light}
                iconColor={Colors[data.color].dark}
            />
        </components.Option>
    );
};

const UserOption = (props) => {
    const { data } = props;
    // const leadLabel = data.is_lead ? `${tr('Lead')} ` : '';
    return (
        <components.Option {...props}>
            {data.showAvatar && (
                <div className="campaign__recipients__avatar">
                    <Avatar
                        className="campaign__recipients__avatar__img"
                        src={data.picture}
                        alt={`${data.firstname} ${data.lastname}`}
                    />
                </div>
            )}
            <div className="campaign__recipients__leads__info">
                <div className="campaign__recipients__leads__name">
                    <span>
                        {data.name} ({data.count})
                    </span>
                </div>
            </div>
        </components.Option>
    );
};

function CampaignRecipients() {
    const actions = useLeadsActions();
    const campaignActions = useCampaignActions();
    const resetSourcecount = useResetRecoilState(sourcecountState);
    const user = useRecoilValue(userState);
    const isTeamShared = user?.team.users_count > 1 && user?.team.is_shared_leads;
    const [campaign, setCampaign] = useRecoilState(campaignState);
    const searches = useRecoilValue(searchesState);
    const labels = useRecoilValue(labelsState);
    const getLabel = (from) => {
        if (from === 'leads') {
            return tr('List of leads');
        }
        if (from === 'contacts') {
            return tr('Members with the label');
        }
        if (from === 'connections') {
            return tr('New connections');
        }
        return '';
    };
    const getSelectedSearch = () => {
        if (campaign.recipient_from_list_id && searches?.items) {
            return searches?.items.find((search) => search.id === campaign.recipient_from_list_id);
        }
        return null;
    };

    useEffect(() => {
        actions.list(true);
    }, [campaign]);
    const selectedLabel = campaign.recipient_from_label_id
        ? labels.find((item) => item.id === campaign.recipient_from_label_id)
        : null;
    return (
        <div className="campaign__config">
            <h1 className="title">
                <div className="flex mbi">
                    <span>{tr('Recipients')}</span>{' '}
                </div>
            </h1>
            <div className="recipients-info">
                {tr("Recipients who might be already in a running campaign won't be added to this campaign")}
            </div>
            <div className="campaign__config-wrapper">
                <div className="autom">
                    <div className="autom-line stepped">
                        <div className="autom-desc">{tr('From')}</div>

                        <Dropdown
                            iconRight
                            btnFace="secondary"
                            disabled={
                                (!!campaign?.board && campaign?.status !== 'DRAFT') ||
                                campaign?.status === 'ARCHIVED' ||
                                campaign?.status === 'FINISHED'
                            }
                            btnClassName="btn--bordered"
                            label={getLabel(campaign.recipient_from) || tr('Choose leads source')}
                            onClick={(e) => e.stopPropagation()}
                            onDoubleClick={(e) => e.stopPropagation()}
                        >
                            <Dropdown.Menu direction="s">
                                <Dropdown.ButtonItem
                                    btnLabel={getLabel('leads')}
                                    onClick={() => {
                                        resetSourcecount();
                                        setCampaign({ ...campaign, recipient_from: 'leads' });
                                    }}
                                />
                                <Dropdown.ButtonItem
                                    btnLabel={getLabel('contacts')}
                                    onClick={() => {
                                        resetSourcecount();
                                        setCampaign({ ...campaign, recipient_from: 'contacts' });
                                    }}
                                />
                                <Dropdown.ButtonItem
                                    btnLabel={getLabel('connections')}
                                    onClick={() => {
                                        resetSourcecount();
                                        setCampaign({ ...campaign, recipient_from: 'connections' });
                                    }}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {campaign.recipient_from === 'connections' && (
                        <Flash color="blue">
                            {tr(
                                'Only new connections, added after the creation date of this campaign and which are not in another campaign, will be processed.'
                            )}
                        </Flash>
                    )}
                </div>
                {campaign.recipient_from !== 'connections' && (
                    <div className="autom" key={getSelectedSearch()}>
                        {campaign.recipient_from === 'leads' && (
                            <div className="autom-line stepped" key={campaign.recipient_from_list_id}>
                                <div>{tr('List')}</div>

                                <Select
                                    closeMenuOnSelect
                                    className="react-select-container lead-picker"
                                    classNamePrefix="react-select"
                                    isDisabled={
                                        (!!campaign?.board && campaign?.status !== 'DRAFT') ||
                                        campaign?.status === 'ARCHIVED' ||
                                        campaign?.status === 'FINISHED'
                                    }
                                    defaultValue={
                                        getSelectedSearch()
                                            ? {
                                                  value: getSelectedSearch().id,
                                                  name: getSelectedSearch().name,
                                                  label: getSelectedSearch().name,
                                                  ownerimage: getSelectedSearch().ownerimage,
                                                  ownerfirstname: getSelectedSearch().ownerfirstname,
                                                  ownerlastname: getSelectedSearch().ownerlastname,
                                              }
                                            : null
                                    }
                                    components={{ Option: UserOption }}
                                    onChange={(selected) => {
                                        if (selected) {
                                            campaignActions.getSourceCount(
                                                null,
                                                campaign.recipient_from_label_ignore_id,
                                                selected.value
                                            );
                                        }
                                        setCampaign({ ...campaign, recipient_from_list_id: selected.value });
                                    }}
                                    options={searches?.items.map((search) => ({
                                        value: search.id,
                                        name: search.name,
                                        count: search.total_count,
                                        label: search.name,
                                        picture: search.user.picture,
                                        showAvatar: isTeamShared,
                                        firstname: search.user.firstname,
                                        lastname: search.user.lastname,
                                    }))}
                                />
                            </div>
                        )}
                        {campaign.recipient_from === 'contacts' && (
                            <div className="autom-line stepped">
                                <div>{tr('Label')}</div>
                                <Select
                                    closeMenuOnSelect
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    isDisabled={
                                        (!!campaign?.board && campaign?.status !== 'DRAFT') ||
                                        campaign?.status === 'ARCHIVED' ||
                                        campaign?.status === 'FINISHED'
                                    }
                                    onChange={(selected) => {
                                        if (selected) {
                                            campaignActions.getSourceCount(
                                                selected.value,
                                                campaign.recipient_from_label_ignore_id,
                                                null
                                            );
                                        }
                                        setCampaign({
                                            ...campaign,
                                            recipient_from_label_id: selected ? selected.value : null,
                                        });
                                    }}
                                    defaultValue={
                                        selectedLabel
                                            ? {
                                                  value: selectedLabel.id,
                                                  name: selectedLabel.name,
                                                  label: selectedLabel.name,
                                              }
                                            : null
                                    }
                                    components={{ Option: LabelOption }}
                                    isClearable
                                    options={labels?.map((label) => ({
                                        value: label.id,
                                        name: label.name,
                                        color: label.color,
                                        label: label.name,
                                    }))}
                                    styles={{
                                        multiValue: (styles, { data }) => ({
                                            ...styles,
                                            backgroundColor: Colors[data.color].light,
                                        }),
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
                <div className="autom">
                    <div className="autom-line stepped">
                        <div>{tr('Exclude members with a Label')}</div>
                        <Select
                            closeMenuOnSelect
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isDisabled={
                                (!!campaign?.board && campaign?.status !== 'DRAFT') ||
                                campaign?.status === 'ARCHIVED' ||
                                campaign?.status === 'FINISHED'
                            }
                            onChange={(selected) => {
                                campaignActions.getSourceCount(
                                    campaign.recipient_from_label_id,
                                    selected ? selected.value : null,
                                    campaign.recipient_from_list_id
                                );

                                setCampaign({
                                    ...campaign,
                                    recipient_from_label_ignore_id: selected ? selected.value : null,
                                });
                            }}
                            defaultValue={
                                selectedLabel
                                    ? {
                                          value: selectedLabel.id,
                                          name: selectedLabel.name,
                                          label: selectedLabel.name,
                                      }
                                    : null
                            }
                            components={{ Option: LabelOption }}
                            isClearable
                            options={labels?.map((label) => ({
                                value: label.id,
                                name: label.name,
                                color: label.color,
                                label: label.name,
                            }))}
                            styles={{
                                multiValue: (styles, { data }) => ({
                                    ...styles,
                                    backgroundColor: Colors[data.color].light,
                                }),
                            }}
                        />
                    </div>
                </div>
                <div className="autom">
                    <div className="autom-line stepped">
                        <div>{tr('Add recipients to column')}</div>
                        <Input
                            value={campaign.recipient_from_step_name}
                            disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                            onChange={(e) => setCampaign({ ...campaign, recipient_from_step_name: e.target.value })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CampaignRecipients;
