import { useState, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { tr } from '../../common/locale';
import Modal from '../../components/Modal';
import useLinkdInActions from '../../_actions/linkedin.actions';
import Flash from '../../components/Flash';
import { boardLaneAllUsersState, boardLaneAllUsersLoadingState } from '../../_states/lnusers.states';
import useBoardAction from '../../_actions/board.actions';

function BulkSyncAction({ boardId, laneId, selectedUsers, closeCb, actionCb }) {
    const boardActions = useBoardAction();
    const modalRef = useRef();
    const [usersToProcess, setUsersToProcess] = useState(selectedUsers);
    const linkedInActions = useLinkdInActions();

    const boardLaneAllUsers = useRecoilValue(boardLaneAllUsersState);
    const boardLaneAllUsersLoading = useRecoilValue(boardLaneAllUsersLoadingState);
    const bulkSync = () => {
        if (actionCb) {
            actionCb();
        }
        linkedInActions.bulkSync(usersToProcess);
        modalRef.current.close();
    };

    useEffect(() => {
        if (!boardLaneAllUsersLoading && boardLaneAllUsers.length > 0) {
            setUsersToProcess(boardLaneAllUsers);
        }
    }, [boardLaneAllUsersLoading, boardLaneAllUsers]);
    useEffect(() => {
        if (boardId && laneId && !usersToProcess) {
            boardActions.getUsersAll(boardId, laneId);
        }
    }, []);

    return (
        <Modal
            defaultIsOpen
            ref={modalRef}
            closeCb={closeCb}
            overflow
            className="bulk-actions-modal__message"
            title={tr('Synchronizing selected members')}
            labelValidate={tr('Synchronize')}
            validateCb={bulkSync}
        >
            <Flash color="blue" icon={boardLaneAllUsersLoading ? 'load' : null} className="mb--20">
                <p>
                    {tr('{{count}} members selected', {
                        count: boardLaneAllUsers.length,
                    })}
                </p>
            </Flash>
        </Modal>
    );
}
export default BulkSyncAction;
