import { useEffect, useRef, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { getRecoil } from 'recoil-nexus';
import cl from 'classnames';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import Select from 'react-select';
import { tr } from '../../common/locale';
import utils from '../../common/utils';
import Button from '../../components/Button';
import Flash from '../../components/Flash';
import ConfirmButton from '../../components/ConfirmButton';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import Avatar from '../../components/Avatar';
import Filter from './filter';
import Icon from '../../components/Icon';
import TooltipPopover from '../../components/TooltipPopover';
import useLeadsActions from '../../_actions/leads.actions';
import useUserActions from '../../_actions/user.actions';
import { userState } from '../../_states/user.states';
import {
    leadsState,
    loadingLeadsState,
    createListState,
    csvLeadExportState,
    searchState,
    savingState,
    selectedLeadsState,
    deleteLeadsState,
    lastSelectState,
    filterSearchLeadsState,
    searchesState,
    selectedLeadState,
} from '../../_states/leads.states';
import Tooltip from '../../components/Tooltip';
import Lead from './Lead';
import LeadLine from './LeadLine';
import AddInboxModal from './AddInboxModal';

function Leads() {
    const actions = useLeadsActions();
    const userActions = useUserActions();
    const createListRef = useRef();
    const searches = useRecoilValue(searchesState);
    const user = useRecoilValue(userState);
    const [searchText, setSearchText] = useState('');
    const savingList = useRecoilValue(savingState);
    const createList = useRecoilValue(createListState);
    const deleteLeads = useRecoilValue(deleteLeadsState);
    const [exportState, setExportState] = useState(null);
    const [selectedSearch, setSelectedSearch] = useState(null);
    const [selectedLead, setSelectedLead] = useRecoilState(selectedLeadState);
    const [selectedLeads, setSelectedLeads] = useRecoilState(selectedLeadsState);
    const [filterSearchLeads, setFilterSearchLeads] = useRecoilState(filterSearchLeadsState);
    const setLastSelect = useSetRecoilState(lastSelectState);
    const [listName, setListName] = useState('');
    const [filters, setFilters] = useState({
        leadsearch__: [],
        duplicate_: [],
        lnusersteam_: [],
        lnusers_: [],
        pipeline_: [],
        scraped_: [],
        lnuser_: [],
        lastname: [],
        languages: [],
        headline: [],
        location: [],
        skills: [],
        company: [],
        connections: [],
        job: [],
        year_position: [],
        year_company: [],
        company_year_founded: [],
        company_employee_count: [],
        company_industry: [],
        email_enrich: [],
        phone: [],
        '': [],
    });
    useEffect(() => {
        if (!createList) {
            createListRef.current.close();
        }
    }, [createList]);
    const searchFunc = (value) => {
        setFilterSearchLeads(value);
    };
    const debouncedSearch = useCallback(debounce(searchFunc, 300), []);
    useEffect(() => {
        if (!searches) {
            actions.list(true);
            setSelectedLeads([]);
        }
    }, []);
    const hasFilter = () => Object.values(filters).find((elem) => elem.length > 0);
    const hasFilterByStr = (str, prop) =>
        Object.values(filters).find((elem) =>
            elem.find((item) => item.operator.indexOf(str) !== -1 && (!prop || prop === item.property))
        );

    const clearFilters = () => {
        const newFilters = {};
        Object.keys(filters).forEach((elem) => {
            newFilters[elem] = [];
        });
        setFilters(newFilters);
    };

    const [showAddFilters, setShowAddFilters] = useState([]);
    const toggleAdd = (filterName) => {
        const index = showAddFilters.indexOf(filterName);
        const newFilters = [...showAddFilters];
        if (index !== -1) {
            newFilters.splice(index, 1);
        } else {
            newFilters.push(filterName);
        }
        setShowAddFilters(newFilters);
    };
    const addFilter = (filter) => {
        const newFilters = { ...filters };
        if (
            !newFilters[filter.property].find(
                (current) => current.operator === filter.operator && current.value === filter.value
            )
        ) {
            newFilters[filter.property].push(filter);
            setFilters(newFilters);
        }
        toggleAdd(filter.property);
    };
    const removeFilter = (filter) => {
        const newFilters = { ...filters };
        const index = filters[filter.property].findIndex(
            (current) => current.operator === filter.operator && current.value === filter.value
        );
        if (index !== -1) {
            newFilters[filter.property].splice(index, 1);
            setFilters(newFilters);
        }
    };

    const selectLeadCb = (e, connection) => {
        e.stopPropagation();
        const connects = getRecoil(leadsState);
        const selectedUsersList = getRecoil(selectedLeadsState);
        const lastSelect = getRecoil(lastSelectState);
        const isSelected = selectedUsersList?.find((item) => item.id === connection.id);
        const indexOfElem = connects.items.findIndex((item) => item.id === connection.id);
        const newSelectedUsers = [...selectedUsersList];

        if (!isSelected) {
            if (e.nativeEvent.shiftKey && lastSelect !== -1) {
                const min = indexOfElem < lastSelect ? indexOfElem : lastSelect;
                const max = indexOfElem < lastSelect ? lastSelect : indexOfElem;
                for (let i = min; i < max; i++) {
                    if (!newSelectedUsers.find((item) => item.id === connects.items[i].id)) {
                        newSelectedUsers.push(connects.items[i]);
                    }
                }
            }
            newSelectedUsers.push(connection);
        } else {
            if (e.nativeEvent.shiftKey && lastSelect !== -1) {
                const min = indexOfElem < lastSelect ? indexOfElem : lastSelect;
                const max = indexOfElem < lastSelect ? lastSelect : indexOfElem;
                for (let i = min; i < max; i++) {
                    const indexCurrent = newSelectedUsers.findIndex((item) => item.id === connects.items[i].id);
                    if (indexCurrent !== -1) {
                        newSelectedUsers.splice(indexCurrent, 1);
                    }
                }
            }
            const currentIndex = selectedUsersList?.findIndex((item) => item.id === connection.id);
            if (currentIndex !== -1) {
                newSelectedUsers.splice(currentIndex, 1);
            }
        }
        setSelectedLeads(newSelectedUsers);
        setLastSelect(indexOfElem);
    };

    const scrollRef = useRef();
    const saveListModalRef = useRef();
    const [loading, setLoading] = useRecoilState(loadingLeadsState);
    const [leads, setLeads] = useRecoilState(leadsState);
    const csvExport = useRecoilValue(csvLeadExportState);
    const [search, setSearch] = useRecoilState(searchState);
    const { searchId } = useParams();
    useEffect(() => {
        actions.get(searchId);
        if (saveListModalRef) {
            saveListModalRef.current.close();
            setListName('');
        }
        setSelectedLeads([]);
        setSearchText('');
        setFilterSearchLeads('');
    }, [searchId]);

    useEffect(() => {
        if (search) {
            actions.listLeads(true, filters, filterSearchLeads);
        }
        if (searchId === 'all') {
            actions.listLeads(true, filters, filterSearchLeads, true);
        }

        if (scrollRef?.current) {
            scrollRef.current.pageLoaded = 0;
            scrollRef.current.el.scrollTop = 0;
        }
    }, [search, filters, filterSearchLeads]);
    useEffect(
        () => () => {
            setSearch(null);
            setLeads(null);
            setLoading(true);
        },
        []
    );
    const lists = searches?.items?.filter((item) => item.name !== 'Individual scrapes');
    const hasMore = !leads || (leads.count !== 0 && leads.items.length < leads.count);
    const fakeLoader = [];
    for (let i = 0; i <= 20; i++) {
        fakeLoader.push(
            <div key={i} className="list__fake list__fake--leads">
                <div className="list__col list__col--spacer" />
                <div className="list__col list__col--checkbox">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--large">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--large">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--large">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--large">
                    <div className="list__fake__item " />
                </div>
                <div className="list__col list__col--large">
                    <div className="list__fake__item " />
                </div>
                <div className="list__col list__col--fill">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col list__col--center list__col--small">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col list__col--center list__col--small">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col list__col--center list__col--mini">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col list__col--hidden-action">
                    <div className="list__fake__item" />
                </div>
            </div>
        );
    }

    const generateFilter = (label, property, operators) => {
        const clickAction = () =>
            (filters[property].length > 0 &&
                (property === 'leadsearch__' ||
                    property === 'lnusers_' ||
                    property === 'pipeline_' ||
                    property === 'lnusersteam_')) ||
            (filters[property].length === 3 && property === '')
                ? ''
                : toggleAdd(property);
        return (
            <li className="leads__filter">
                <div
                    role="button"
                    tabIndex={0}
                    className={`leads__filter__header ${
                        (filters[property].length > 0 &&
                            (property === 'leadsearch__' ||
                                property === 'lnusers_' ||
                                property === 'pipeline_' ||
                                property === 'lnusersteam_')) ||
                        (filters[property].length === 3 && property === '')
                            ? 'cursor-default'
                            : ''
                    }`}
                    onClick={clickAction}
                    onKeyDown={clickAction}
                >
                    <div>{label}</div>
                    <Button
                        type="link"
                        iconSize={22}
                        disabled={
                            (filters[property].length > 0 &&
                                (property === 'leadsearch__' ||
                                    property === 'lnusers_' ||
                                    property === 'pipeline_' ||
                                    property === 'lnusersteam_' ||
                                    property.indexOf('null') !== -1)) ||
                            (filters[property].length === 3 && property === '') ||
                            (filters.phone.length === 1 && property === 'phone')
                        }
                        icon="plus"
                    />
                </div>
                {(filters[property].length > 0 || showAddFilters.indexOf(property) !== -1) && (
                    <div className="leads__filter__body">
                        <div className="leads__filter__activated">
                            {filters[property].map((filter) => (
                                <Filter
                                    key={filter.value + filter.operator}
                                    {...filter}
                                    read
                                    removeCb={() => removeFilter(filter)}
                                />
                            ))}
                        </div>

                        {showAddFilters.indexOf(property) !== -1 && (
                            <Filter
                                property={property}
                                operatorChoices={operators}
                                addCb={(filter) => addFilter(filter)}
                                removeFilterCb={() => toggleAdd(property)}
                            />
                        )}
                    </div>
                )}
            </li>
        );
    };
    let labelImport = tr('Add to my Inbox / Add a label');
    if (hasFilter() || selectedLeads.length > 0) {
        labelImport = tr('Add to my Inbox / Add a label ({{count}})', {
            count: selectedLeads.length > 0 ? selectedLeads.length : leads?.count,
        });
    }
    let labelPipeline = tr('Add to a Pipeline');
    if (hasFilter() || selectedLeads.length > 0) {
        labelPipeline = tr('Add to a Pipeline ({{count}})', {
            count: selectedLeads.length > 0 ? selectedLeads.length : leads?.count,
        });
    }

    let origin = search?.is_salesnav ? 'Sales Navigator' : 'Linkedin';
    if (search?.profiles) {
        origin = 'CSV';
    }
    const isTeamShared = user?.team.users_count > 1 && user?.team.is_shared_leads;
    const totalCount = searchId === 'all' ? leads?.count : search?.total_count;
    return (
        <div className="content leads">
            <Lead search={search} lead={selectedLead} closeCb={() => setSelectedLead(null)} />
            {exportState && (
                <AddInboxModal
                    exportState={exportState}
                    searchId={searchId === 'all' ? 'all' : search?.id}
                    hasFilter={hasFilter() || selectedLeads.length > 0}
                    filters={filters}
                    closeCb={() => {
                        setExportState(null);
                        actions.listLeads(true, filters, filterSearchLeads, searchId === 'all');
                    }}
                    count={selectedLeads.length > 0 ? selectedLeads.length : leads?.count}
                />
            )}
            <Modal
                ref={createListRef}
                disabled={!listName}
                validateCb={() => {
                    actions.createSearchFromList(listName, []);
                }}
                closeCb={() => {
                    setListName('');
                }}
                title={tr('Create an empty list')}
                labelValidate={tr('Create')}
            >
                <Input
                    label={tr('Name your list')}
                    placeholder={tr('Type in a name')}
                    value={listName}
                    onChange={(e) => setListName(e.target.value)}
                />
            </Modal>
            <Modal
                ref={saveListModalRef}
                title={tr('Move to a list')}
                disabled={!selectedSearch && !listName}
                labelValidate={lists?.length === 0 ? tr('Create and move') : tr('Move')}
                overflow
                footer={
                    <div className={cl('modal__footer')}>
                        <Button
                            label={tr('Cancel')}
                            type="transparent"
                            onClick={() => saveListModalRef.current.close()}
                        />

                        <Button
                            type="white"
                            isBordered
                            label={tr('Move and go to list')}
                            isLoading={savingList}
                            onClick={() => {
                                actions.saveList(
                                    searchId === 'all' ? 'all' : search?.id,
                                    listName,
                                    filters,
                                    selectedSearch,
                                    true
                                );
                                saveListModalRef.current.close();
                            }}
                            disabled={!selectedSearch && !listName}
                        />
                        <Button
                            type="primary"
                            label={tr('Move')}
                            isLoading={savingList}
                            onClick={() => {
                                actions.saveList(
                                    searchId === 'all' ? 'all' : search?.id,
                                    listName,
                                    filters,
                                    selectedSearch
                                );
                                saveListModalRef.current.close();
                            }}
                            disabled={!selectedSearch && !listName}
                        />
                    </div>
                }
            >
                <Flash wrap color="blue" className="mb--15">
                    <strong>{tr('Information:')}</strong>
                    <br />
                    {tr(
                        'Duplicated leads will be moved from this list, but their occurrences in other lists will not be affected.'
                    )}
                </Flash>
                {lists?.length === 0 && <p className="red mt--30 mb--15">{tr('You do not have any list yet.')}</p>}
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder={tr('Select a list')}
                    onChange={(selected) => {
                        setSelectedSearch(selected.value);
                    }}
                    isDisabled={lists?.length === 0}
                    options={lists
                        ?.filter((list) => list.id !== search?.id)
                        .map((current) => ({
                            value: current.id,
                            name: current.name,
                            label: current.name,
                        }))}
                />
                <div className="my--20 center">OR</div>
                <Input
                    label={tr('Create a new list')}
                    placeholder={tr('List name')}
                    value={listName}
                    onChange={(e) => setListName(e.target.value)}
                />
            </Modal>

            <div className="content__sidebar content__sidebar--large">
                <div className="content__sidebar__header">
                    <div className="content__sidebar__header__title content__sidebar__header__title--inverse">
                        <Link
                            className="btn btn--has-icon btn--icon btn--bordered btn--white btn--no-label"
                            to="/searches"
                        >
                            <Icon size={26} icon="caret-left" />
                        </Link>
                        <h1 className="title">{tr('Leads')}</h1>
                    </div>
                    <div
                        className="content__sidebar__header__info"
                        title={searchId === 'all' ? tr('All leads') : search?.name}
                    >
                        <div className="content__sidebar__header__info__title">
                            <Icon size={28} icon={utils.searchTypeIcon(search)} />{' '}
                            <span>{searchId === 'all' ? tr('All leads') : search?.name}</span>
                        </div>
                        {searchId !== 'all' && (
                            <div className="content__sidebar__header__info__desc">
                                {searchId !== 'all' && <span>{tr('{{count}} Lead', { count: totalCount })}</span>}
                                {!hasFilter() && searchId !== 'all' && (
                                    <>
                                        {user?.team?.email_credits < 0.7 * totalCount &&
                                            !search?.is_enrich_processing && (
                                                <TooltipPopover
                                                    text={tr(
                                                        "You don't have enough credits. Split yout list or buy credits."
                                                    )}
                                                >
                                                    <Button
                                                        className="btn--primary ml--auto"
                                                        icon="arobase"
                                                        label={tr('Find emails')}
                                                    />
                                                </TooltipPopover>
                                            )}
                                        {(user?.team?.email_credits >= 0.7 * totalCount ||
                                            search?.is_enrich_processing) && (
                                            <ConfirmButton
                                                className="btn--primary ml--auto"
                                                icon={search?.is_enrich_processing ? 'cog-alt' : 'arobase'}
                                                type={search?.is_enrich_processing ? 'processing' : 'primary'}
                                                label={
                                                    search?.is_enrich_processing
                                                        ? tr('Finding emails...')
                                                        : tr('Find emails')
                                                }
                                                disabled={search?.is_enrich_processing}
                                                confirmLabel={tr(
                                                    'When the email enrichment process is initiated, it cannot be stopped. It will try to find the email addresses of all the leads in the list which are not already enriched. Do you want to continue?'
                                                )}
                                                onConfirm={() => {
                                                    actions.enrich(search);
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                        {searchId !== 'all' && isTeamShared && (
                            <div className="list-owner">
                                <Avatar
                                    className="avatar"
                                    src={search?.user?.picture}
                                    alt={`${search?.user?.firstname} ${search?.user?.lastname}`}
                                />
                                {search?.user?.firstname}&nbsp;{search?.user?.lastname} ({tr('owner')})
                            </div>
                        )}
                        {searchId !== 'all' && search?.is_enrich && (
                            <div className="content__sidebar__header__info blue mbi--small flex normal">
                                <span className="enriched-profile">
                                    <Icon size={9} icon="check" />
                                </span>
                                <span>{tr('Find emails process achieved')}</span>
                            </div>
                        )}
                        {searchId !== 'all' && (
                            <div className="content__sidebar__header__info__meta">
                                <span>
                                    {!search?.is_salesnav && !search?.is_free_search
                                        ? tr('List created on {{date}}', {
                                              date: moment(search?.created_at).format('LL'),
                                              origin,
                                          })
                                        : tr('Extracted from {{origin}} on {{date}}', {
                                              date: moment(search?.created_at).format('LL'),
                                              origin,
                                          })}
                                </span>
                            </div>
                        )}
                        {searchId !== 'all' && search?.search_url && (
                            <div className="content__sidebar__header__info__link">
                                <a href={search?.search_url} target="_blank" rel="noreferrer">
                                    <Icon size={16} icon="arrow-right" />
                                    <span>{tr('Import lead from same search')}</span>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                <div className="content__sidebar__body content__sidebar__body--bordered">
                    <div className="content__sidebar__body__section">
                        <div className="content__sidebar__body__section__search">
                            <Input
                                isFilter
                                icon="search"
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    debouncedSearch(e.target.value);
                                }}
                                placeholder={tr('Search by name')}
                                type="search"
                            />
                        </div>
                        <div>
                            <h5 className="title">
                                <span>{tr('Filters')}</span>
                                {hasFilter() && (
                                    <Button
                                        icon="close"
                                        type="yellow"
                                        size="nano"
                                        label={tr('Clear all')}
                                        onClick={() => clearFilters()}
                                    />
                                )}
                            </h5>
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            {searchId !== 'all' &&
                                generateFilter(tr('Match'), 'leadsearch__', ['is_match', 'is_not_match'])}
                            {searchId !== 'all' &&
                                generateFilter(tr('Duplicated in other lists'), 'duplicate_', [
                                    'search_exists',
                                    'search_not_exists',
                                ])}
                            {generateFilter(tr('Added to my Inbox'), 'lnusers_', ['exists', 'not_exists'])}
                            {generateFilter(tr('Added to a Pipeline'), 'pipeline_', ['exists', 'not_exists'])}
                            {isTeamShared &&
                                generateFilter(tr('Added to an Inbox of my team'), 'lnusersteam_', [
                                    'exists',
                                    'not_exists',
                                ])}
                            {generateFilter(tr('Connection / Conversation'), 'lnuser_', [
                                'relation_exists',
                                'relation_not_exists',
                                'conv_exists',
                                'conv_not_exists',
                            ])}
                            {generateFilter(tr('Enriched Email Address'), 'email_enrich', [
                                !hasFilterByStr('null', 'email_enrich') && '=null',
                                !hasFilterByStr('null', 'email_enrich') && '!=null',
                                '~',
                                '!~',
                            ])}
                            {generateFilter(tr('Scraped Email Address'), 'scraped_', [
                                'email_exists',
                                'email_not_exists',
                            ])}
                            {generateFilter(tr('Phone Number'), 'phone', [
                                !hasFilterByStr('null', 'phone') && '=null',
                                !hasFilterByStr('null', 'phone') && '!=null',
                            ])}
                            {generateFilter(tr('LinkedIn account'), '', [
                                !hasFilterByStr('open_profile') && 'is_open_profile:true',
                                !hasFilterByStr('open_profile') && 'is_open_profile!:true',
                                !hasFilterByStr('picture') && 'picture=null',
                                !hasFilterByStr('picture') && 'picture!=null',
                                !hasFilterByStr('opentowork') && 'is_opentowork:true',
                                !hasFilterByStr('opentowork') && 'is_opentowork!:true',
                                !hasFilterByStr('premium') && 'is_premium:true',
                                !hasFilterByStr('premium') && 'is_premium!:true',
                            ])}
                            {generateFilter(tr('Language'), 'languages', ['~', '!~'])}
                            {generateFilter(tr('Job title'), 'job', ['~', '!~'])}
                            {generateFilter(tr('Number of connections'), 'connections', ['>', '<'])}
                            {generateFilter(tr('Skills'), 'skills', ['~', '!~'])}
                            {generateFilter(tr('Years in job'), 'year_position', ['>', '<'])}
                            {generateFilter(tr('Years in company'), 'year_company', ['>', '<'])}
                            {generateFilter(tr('Headline'), 'headline', ['~', '!~'])}
                            {generateFilter(tr('Location'), 'location', ['~', '!~'])}
                            {generateFilter(tr('Company name'), 'company', ['~', '!~'])}
                            {generateFilter(tr('Company employees count'), 'company_employee_count', ['>', '<'])}
                            {generateFilter(tr('Company founded since'), 'company_year_founded', ['>', '<'])}
                            {generateFilter(tr('Company industry'), 'company_industry', ['~', '!~'])}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="content__body leads-in-list">
                <div className={cl('list__filter list__filter list__filter--actions')}>
                    <div className="list__filter__lead-count">
                        {selectedLeads.length > 0 && (
                            <>
                                <div className="select-indicator">
                                    <div>{tr('{{count}} selected', { count: selectedLeads.length })}</div>
                                </div>
                                <Tooltip className="tooltip" text={tr('Unselect all')} direction="s">
                                    <Button
                                        type="link"
                                        icon="close"
                                        iconSize={16}
                                        onClick={() => setSelectedLeads([])}
                                    />
                                </Tooltip>
                            </>
                        )}
                        {selectedLeads.length === 0 && (
                            <span className="ellipsis">
                                {hasFilter()
                                    ? tr('Filtered results {{count}} / {{total}}', {
                                          count: leads?.count,
                                          total: totalCount,
                                      })
                                    : tr('{{count}} Lead', { count: totalCount })}
                            </span>
                        )}
                    </div>
                    {search?.is_processing && !search?.is_paused && (
                        <div className="list__filter--actions__btn list__filter--actions__btn--processing">
                            <Button icon="cog-alt" type="processing" label={tr('Importing members')} disabled />
                        </div>
                    )}
                    <div className="list__filter--actions__btn">
                        <Tooltip
                            className="csv-button"
                            text={
                                hasFilter() || selectedLeads.length > 0
                                    ? tr('Download CSV File ({{count}})', {
                                          count: selectedLeads.length > 0 ? selectedLeads.length : leads?.count,
                                      })
                                    : tr('Download CSV File')
                            }
                            direction="s"
                        >
                            <Button
                                icon="download"
                                type="bulk"
                                isLoading={csvExport}
                                onClick={() => {
                                    actions.csv(
                                        searchId === 'all' || !search ? 'all' : search?.id,
                                        searchId === 'all' ? tr('All leads') : search?.name,
                                        filters,
                                        selectedLeads.length > 0 ? selectedLeads.length : leads?.count,
                                        0
                                    );
                                }}
                            />
                        </Tooltip>
                    </div>

                    {(selectedLeads.length > 0 || leads?.count > 0) && (
                        <div className="list__filter--actions__btn">
                            <Tooltip
                                text={
                                    hasFilter() || selectedLeads.length > 0
                                        ? tr('Move to a list ({{count}})', {
                                              count: selectedLeads.length > 0 ? selectedLeads.length : leads?.count,
                                          })
                                        : tr('Move to a list')
                                }
                                direction="s"
                            >
                                <Button
                                    icon="folder-arrow"
                                    type="bulk"
                                    isLoading={savingList}
                                    onClick={() => {
                                        saveListModalRef.current.open();
                                    }}
                                />
                            </Tooltip>
                        </div>
                    )}
                    <div className="list__filter--actions__btn">
                        <Tooltip text={labelImport} direction="s">
                            <Button
                                icon="inbox-plus"
                                type="bulk"
                                isLoading={false}
                                onClick={() => {
                                    setExportState('inbox');
                                }}
                            />
                        </Tooltip>
                    </div>
                    <div className="list__filter--actions__btn">
                        <Tooltip text={labelPipeline} direction="s">
                            <Button
                                type="bulk"
                                icon="board-plus"
                                isLoading={false}
                                onClick={() => {
                                    setExportState('pipeline');
                                }}
                            />
                        </Tooltip>
                    </div>
                    <div className="list__filter--actions__btn">
                        <Tooltip direction="s" text={tr('Create an empty list')}>
                            <Button
                                type="bulk"
                                icon="folder-plus"
                                className="new-folder"
                                onClick={() => {
                                    createListRef.current.open();
                                }}
                            />
                        </Tooltip>
                    </div>
                    {user?.tours && user?.tours.indexOf('legend_leads') === -1 && (
                        <div className="list__filter--legend">
                            <span>{tr('Legend:')}</span>
                            <div className="legend legend-inbox">
                                <Icon icon="inbox" />
                                <span>{tr('Already in your Inbox / Pipeline')}</span>
                            </div>
                            <div className="legend legend-duplicated">
                                <Icon icon="copy" />
                                <span>{tr('Duplicated')}</span>
                            </div>
                            <Tooltip direction="s" text={tr('Hide Legend')}>
                                <Button
                                    type="transparent"
                                    icon="close"
                                    onClick={() => userActions.updateTour('legend_leads')}
                                />
                            </Tooltip>
                        </div>
                    )}
                    {selectedLeads.length > 0 && (
                        <div className="list__filter--actions__btn">
                            <Tooltip
                                text={tr('Delete Leads ({{count}})', {
                                    count: selectedLeads.length,
                                })}
                                direction="s"
                            >
                                <ConfirmButton
                                    type="bulk"
                                    icon="trash-can"
                                    confirmLabel={
                                        <>
                                            <h4>{tr('Confirm deletion ?')}</h4>
                                            <br />
                                            {searchId !== 'all' && (
                                                <p>
                                                    {tr(
                                                        'Are you sure you want to delete the selected leads from the list?'
                                                    )}
                                                    <br />
                                                    <br />
                                                    <strong>{tr('Information:')}</strong>
                                                    <br />
                                                    {tr(
                                                        'Duplicated leads will be removed from this list but will still remain in other lists.'
                                                    )}
                                                </p>
                                            )}
                                            {searchId === 'all' && (
                                                <p>
                                                    {tr(
                                                        'Are you sure you want to delete the selected leads from all the lists?'
                                                    )}
                                                </p>
                                            )}
                                        </>
                                    }
                                    isLoading={deleteLeads}
                                    onConfirm={() => {
                                        actions.removeAllFromList(searchId);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div
                    id="connectionsContent"
                    className={`connections--list ${
                        loading || (leads && leads?.items.length > 0) ? '' : 'list--empty'
                    }`}
                >
                    {loading && (!leads || leads?.items.length === 0) && fakeLoader}
                    {leads && leads?.items.length > 0 ? (
                        <InfiniteScroll
                            dataLength={leads?.items.length || 0}
                            next={() =>
                                search || searchId === 'all'
                                    ? actions.listLeads(false, filters, filterSearchLeads, searchId === 'all')
                                    : null
                            }
                            className="list list--actions-on-hover list--clickable"
                            hasMore={hasMore}
                            loader={fakeLoader}
                            ref={scrollRef}
                            scrollableTarget="connectionsContent"
                        >
                            <div className="list__header splitted-list__header">
                                <div className="list__cols splitted-list__header__sticky">
                                    <div className="list__col list__col--spacer" />
                                    <div className="list__col list__col--checkbox" />
                                    <div className="list__col list__col--large col-name target">{tr('Name')}</div>
                                </div>
                                <div className="list__cols">
                                    <div className="list__col list__col--large col-job target">{tr('Current Job')}</div>
                                    <div className="list__col list__col--large col-email target">
                                        {tr('Enriched Email')}
                                    </div>
                                    <div className="list__col list__col--large col-email target">
                                        {tr('Scraped Email')}
                                    </div>

                                    <div className="list__col list__col--large col-headline target">
                                        {tr('Headline')}
                                    </div>
                                    <div className="list__col list__col list__col--large col-company target">
                                        {tr('Company')}
                                    </div>
                                    <div className="list__col list__col list__col--large col-company target">
                                        {tr('Company industry')}
                                    </div>
                                    <div className="list__col list__col list__col--center list__col--small">
                                        {tr('Connections')}
                                    </div>
                                    <div className="list__col list__col list__col--center list__col--small">
                                        {tr('Open profile')}
                                    </div>
                                    <div className="list__col list__col list__col--center list__col--mini">
                                        {tr('Match')}
                                    </div>
                                    {/* <div className="list__col list__col list__col--center list__col--mini">{tr('Notes')}</div> */}
                                    <div className="list__col list__col list__col--hidden-action" />
                                </div>
                            </div>
                            <div className="splitted-list__container">
                                <div className="splitted-list__left">
                                    {leads &&
                                        leads.items.map((lead, index) => (
                                            <LeadLine
                                                isFull={false}
                                                lead={lead}
                                                key={lead.id}
                                                index={index}
                                                search={search}
                                                setSelectedLead={setSelectedLead}
                                                selectLeadCb={selectLeadCb}
                                                isSelected={!!selectedLeads?.find((item) => item.id === lead.id)}
                                            />
                                        ))}
                                </div>
                                <div className="splitted-list__right">
                                    {leads &&
                                        leads.items.map((lead, index) => (
                                            <LeadLine
                                                isFull
                                                lead={lead}
                                                key={lead.id}
                                                index={index}
                                                search={search}
                                                setSelectedLead={setSelectedLead}
                                                selectLeadCb={selectLeadCb}
                                                isSelected={!!selectedLeads?.find((item) => item.id === lead.id)}
                                            />
                                        ))}
                                </div>
                            </div>
                        </InfiniteScroll>
                    ) : (
                        <>
                            {!loading && (
                                <div className="empty">
                                    <Icon isEmpty icon="empty-connection" size={400} />
                                    <h1>{tr('Nothing here')}</h1>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
export default Leads;
