import { useRef, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tr } from '../../common/locale';
import Button from '../../components/Button';

import Popover from '../../components/Popover';
import { bulkLabelProgressState, bulkBoardProgressState } from '../../_states/lnusers.states';
import { snackMessageState } from '../../_states/alert.states';

function BulkStatus() {
    const ref = useRef();
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const bulkLabelProgress = useRecoilValue(bulkLabelProgressState);
    const bulkBoardProgress = useRecoilValue(bulkBoardProgressState);
    useEffect(() => {
        if (bulkLabelProgress === 'done') {
            setSnackMessage({ text: tr('Labels updated!'), type: 'info' });
        }
    }, [bulkLabelProgress]);
    useEffect(() => {
        if (bulkBoardProgress === 'done') {
            setSnackMessage({ text: tr('Members processed!'), type: 'info' });
        }
    }, [bulkBoardProgress]);

    const show = bulkLabelProgress || bulkBoardProgress;
    const renderContent = () => (
        <ul>
            {bulkLabelProgress && bulkLabelProgress !== 'done' && (
                <li>
                    {tr('Updating labels in progress: ')}
                    <strong>
                        {tr('{{count}} members updated', {
                            count: bulkLabelProgress,
                        })}
                    </strong>
                </li>
            )}
            {bulkBoardProgress && bulkBoardProgress !== 'done' && (
                <li>
                    {tr('Adding members to Pipeline in progress: ')}
                    <strong>
                        {tr('{{count}} members updated', {
                            count: bulkBoardProgress,
                        })}
                    </strong>
                </li>
            )}
        </ul>
    );
    if (!show) {
        return null;
    }
    return (
        <div className="list__filter--actions__btn">
            <Popover ref={ref} content={renderContent()}>
                <Button
                    className="btn--processing"
                    type="bulk"
                    iconSize={20}
                    icon="cog-alt"
                    onClick={(e) => {
                        e.stopPropagation();
                        ref?.current?.toggle();
                    }}
                />
            </Popover>
        </div>
    );
}
export default BulkStatus;
