import { useRecoilValue } from 'recoil';
import {
    PieChart,
    ResponsiveContainer,
    Label,
    Pie,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Bar,
    LabelList,
} from 'recharts';
import { tr } from '../../common/locale';
import { campaignState, statsState, loadingStatsState } from '../../_states/campaign.states';

function CampaignStats() {
    const campaign = useRecoilValue(campaignState);
    const stats = useRecoilValue(statsState);
    const loading = useRecoilValue(loadingStatsState);
    const percentProcessed = stats?.source_count ? Math.round((stats?.finished * 100) / stats?.source_count) : 0;
    const startedData = [
        {
            name: tr('Not yet started'),
            value: stats?.source_count ? stats?.source_count - stats?.finished : 1,
            fill: '#eee',
        },
        {
            name: tr('Finished'),
            value: stats?.finished,
            fill: '#88ff39',
        },
    ];
    const percentConnected = stats?.connect ? Math.round((stats?.accepted * 100) / stats?.connect) : 0;
    const connectedData = [
        {
            name: tr('Not yet accepted'),
            value: stats?.connect ? stats?.connect - stats?.accepted : 1,
            fill: '#eee',
        },
        {
            name: tr('Accepted invitation'),
            value: stats?.accepted,
            fill: '#88ff39',
        },
    ];
    const percentAnswered = stats?.answered ? Math.round((stats?.answered * 100) / stats?.messaged) : 0;
    const answeredData = [
        {
            name: tr('Not answered'),
            value: stats?.answered && stats?.messaged ? stats?.messaged - stats?.answered : 1,
            fill: '#eee',
        },
        {
            name: tr('Answered'),
            value: stats?.answered && stats?.messaged ? stats?.answered : 0,
            fill: '#88ff39',
        },
    ];
    return (
        <div className="content__body campaign-stats">
            <div className="campaign__header">
                <h2 className="title">{tr('Campaign stats')}</h2>
            </div>
            <div className="campaign__stats">
                {loading && <div>{tr('Loading')}</div>}

                <BarChart width={920} height={360} data={[stats]}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis />
                    <YAxis padding={{ top: 20 }} />
                    {campaign?.action_visit && (
                        <Bar barSize={90} dataKey="visit" fill="#efb700" label={stats?.visit && { fill: 'black' }}>
                            <LabelList position="top" fill="black">
                                {tr('Profile visits')}
                            </LabelList>
                        </Bar>
                    )}
                    {campaign?.action_follow && (
                        <Bar barSize={90} dataKey="follow" fill="#ff720c" label={stats?.follow && { fill: '#fff' }}>
                            <LabelList position="top" fill="black">
                                {tr('Followed')}
                            </LabelList>
                        </Bar>
                    )}
                    {campaign?.action_like && (
                        <Bar barSize={90} dataKey="like" fill="#ff720c" label={stats?.like && { fill: '#fff' }}>
                            <LabelList position="top" fill="black">
                                {tr('Post Liked')}
                            </LabelList>
                        </Bar>
                    )}
                    {campaign?.action_connect && (
                        <Bar barSize={90} dataKey="connect" fill="#3474FF" label={stats?.connect && { fill: '#fff' }}>
                            <LabelList position="top" fill="black">
                                {tr('Invited')}
                            </LabelList>
                        </Bar>
                    )}
                    {campaign?.action_message_1 && (
                        <Bar
                            barSize={90}
                            dataKey="message_1"
                            fill="#7f4aea"
                            label={stats?.message_1 && { fill: '#fff' }}
                        >
                            <LabelList position="top" fill="black">
                                {tr('Message')}
                            </LabelList>
                        </Bar>
                    )}
                    {campaign?.action_message_2 && (
                        <Bar
                            barSize={90}
                            dataKey="message_2"
                            fill="#2f7b15"
                            label={stats?.message_2 && { fill: '#fff' }}
                        >
                            <LabelList position="top" fill="black">
                                {tr('Follow-up 1')}
                            </LabelList>
                        </Bar>
                    )}
                    {campaign?.action_message_3 && (
                        <Bar
                            barSize={90}
                            dataKey="message_3"
                            fill="#2f7b15"
                            label={stats?.message_3 && { fill: '#fff' }}
                        >
                            <LabelList position="top" fill="black">
                                {tr('Follow-up 2')}
                            </LabelList>
                        </Bar>
                    )}
                    {campaign?.action_message_4 && (
                        <Bar
                            barSize={80}
                            dataKey="message_4"
                            fill="#2f7b15"
                            label={stats?.message_4 && { fill: '#fff' }}
                        >
                            <LabelList position="top" fill="black">
                                {tr('Follow-up 3')}
                            </LabelList>
                        </Bar>
                    )}
                    {campaign?.action_message_5 && (
                        <Bar
                            barSize={80}
                            dataKey="message_5"
                            fill="#2f7b15"
                            label={stats?.message_5 && { fill: '#fff' }}
                        >
                            <LabelList position="top" fill="black">
                                {tr('Follow-up 4')}
                            </LabelList>
                        </Bar>
                    )}
                    {campaign?.action_message_6 && (
                        <Bar
                            barSize={80}
                            dataKey="message_6"
                            fill="#2f7b15"
                            label={stats?.message_6 && { fill: '#fff' }}
                        >
                            <LabelList position="top" fill="black">
                                {tr('Follow-up 5')}
                            </LabelList>
                        </Bar>
                    )}
                    {campaign?.action_message_7 && (
                        <Bar
                            barSize={80}
                            dataKey="message_7"
                            fill="#2f7b15"
                            label={stats?.message_7 && { fill: '#fff' }}
                        >
                            <LabelList position="top" fill="black">
                                {tr('Follow-up 6')}
                            </LabelList>
                        </Bar>
                    )}
                </BarChart>

                <div className="campaign__stats__donuts">
                    {stats?.source_count && (
                        <div className="donut__section">
                            <h2 className="donut__legend">{tr('Processed members')}</h2>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={startedData}
                                        nameKey="name"
                                        dataKey="value"
                                        innerRadius="60%"
                                        outerRadius="68%"
                                        fill="black"
                                        label={!!stats?.source_count}
                                    >
                                        <Label width={50} fill="black" position="center">
                                            {`${percentProcessed}%`}
                                        </Label>
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                    {campaign?.action_connect && (
                        <div className="donut__section">
                            <h2 className="donut__legend">{tr('Connections accepted')}</h2>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={connectedData}
                                        nameKey="name"
                                        dataKey="value"
                                        innerRadius="60%"
                                        outerRadius="68%"
                                        fill="#8884d8"
                                        label={!!stats?.connect}
                                    >
                                        <Label width={50} fill="black" position="center">
                                            {`${percentConnected}%`}
                                        </Label>
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                    {campaign?.action_message_1 && (
                        <div className="donut__section">
                            <h2 className="donut__legend">{tr('Messages answered')}</h2>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={answeredData}
                                        nameKey="name"
                                        dataKey="value"
                                        innerRadius="60%"
                                        outerRadius="68%"
                                        fill="#8884d8"
                                        label={!!(stats?.answered && stats?.messaged)}
                                    >
                                        <Label width={50} fill="black" position="center">
                                            {`${percentAnswered}%`}
                                        </Label>
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default CampaignStats;
