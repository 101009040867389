import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { getRecoil } from 'recoil-nexus';
import useFetchWrapper from '../_helpers/fetchWrapper.helpers';
import {
    authState,
    userState,
    invoicesState,
    extensionState,
    usersState,
    subscriptionsState,
    creditsState,
    stripeState,
    plansState,
    loadingUsersState,
    filterUsersState,
    errorAppsumoState,
    successAppsumoState,
    affiliateLinkState,
    affiliateLinkLoadingState,
    affiliateAccountState,
    affiliateAccountLoadingState,
    affiliateUsersState,
    affiliateProgramState,
    affiliateUsersLoadingState,
    previewSubscriptionUpdateLoadingState,
    changeSubscriptionUserState,
    previewSubscriptionUpdateState,
    shareSearchesLoadingState,
    teamUsersState,
    saveEmailLoadingState,
    saveEmailDoneState,
    teamUsersLoadingState,
    savingQuotasState,
    filterTypesState,
    quotasState,
} from '../_states/user.states';
import { snackMessageState } from '../_states/alert.states';
import useBoardsAction from './board.actions';
import useLabelsAction from './label.actions';
import useTemplatesAction from './template.actions';
import { tr } from '../common/locale';
import history from '../_helpers/history.helpers';
import useError from '../_helpers/errorsWrapper.helpers';
import utils from '../common/utils';

let checkoutController;

export default function useUserActions(fromForm) {
    const fetchWrapper = useFetchWrapper();
    const boardsActions = useBoardsAction();
    const labelsAction = useLabelsAction();
    const templatesAction = useTemplatesAction();
    const setAuth = useSetRecoilState(authState);
    const setPlans = useSetRecoilState(plansState);
    const setSavingQuotas = useSetRecoilState(savingQuotasState);
    const setChangeSubscriptionUser = useSetRecoilState(changeSubscriptionUserState);
    const setInvoices = useSetRecoilState(invoicesState);
    const setSubscriptions = useSetRecoilState(subscriptionsState);
    const setCredits = useSetRecoilState(creditsState);
    const setShareSearchesLoading = useSetRecoilState(shareSearchesLoadingState);
    const setPreviewSubscriptionUpdateLoading = useSetRecoilState(previewSubscriptionUpdateLoadingState);
    const setPreviewSubscriptionUpdate = useSetRecoilState(previewSubscriptionUpdateState);
    const setStripe = useSetRecoilState(stripeState);
    const setUsers = useSetRecoilState(usersState);
    const setAffiliateUsers = useSetRecoilState(affiliateUsersState);
    const setExtensionState = useSetRecoilState(extensionState);
    const setUsersLoading = useSetRecoilState(loadingUsersState);
    const { fetchNetworkError } = useError();
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const setAffiliateProgram = useSetRecoilState(affiliateProgramState);
    const setTeamUsers = useSetRecoilState(teamUsersState);
    const setTeamUsersLoading = useSetRecoilState(teamUsersLoadingState);
    const setAffiliateLink = useSetRecoilState(affiliateLinkState);
    const setAffiliateLinkLoading = useSetRecoilState(affiliateLinkLoadingState);
    const setAffiliateAccount = useSetRecoilState(affiliateAccountState);
    const setAffiliateAccountLoading = useSetRecoilState(affiliateAccountLoadingState);
    const setAffiliateUsersLoading = useSetRecoilState(affiliateUsersLoadingState);
    const setErrorAppsumo = useSetRecoilState(errorAppsumoState);
    const setSuccessAppsumo = useSetRecoilState(successAppsumoState);
    const setSaveEmailLoading = useSetRecoilState(saveEmailLoadingState);
    const setSaveEmailDone = useSetRecoilState(saveEmailDoneState);
    const setUser = useSetRecoilState(userState);
    const navigate = useNavigate();

    function checkout(isMonth, emailCredits, quantities, update = false, preview = false, coupon = '') {
        setPreviewSubscriptionUpdateLoading(true);
        const { access } = fetchWrapper.getAuth();
        if (access) {
            const quantitiesParam = Object.keys(quantities).map((qt) => `${qt}=${quantities[qt]}`);
            const url = `payment/${update ? 'update' : 'checkout'}?interval=${
                isMonth ? 'month' : 'year'
            }&email_credits_recurring=${
                emailCredits > 0 ? 'true' : 'false'
            }&email_credits=${emailCredits}&${quantitiesParam.join('&')}&coupon=${coupon}`;
            if (update) {
                if (checkoutController) {
                    checkoutController.abort();
                }
                checkoutController = new AbortController();
                return fetchWrapper
                    .get(`${url}${preview ? '&preview=true' : ''}`, null, { signal: checkoutController.signal })
                    .then((data) => {
                        if (preview) {
                            setPreviewSubscriptionUpdateLoading(false);
                            setPreviewSubscriptionUpdate(data);
                        } else {
                            setTimeout(() => {
                                window.location.reload();
                            }, 5000);
                        }
                    })
                    .catch((e) => {
                        setPreviewSubscriptionUpdateLoading(false);
                        fetchNetworkError(fromForm, e);
                    });
            }
            window.location.href = `${process.env.REACT_APP_API_URL}/${url}&token=${access}`;
        }
        return null;
    }

    function checkoutPack(pack) {
        const { access } = fetchWrapper.getAuth();
        if (access) {
            const url = `payment/checkout?interval=&email_credits_recurring=false&email_credits=${pack}`;
            window.location.href = `${process.env.REACT_APP_API_URL}/${url}&token=${access}`;
        }
        return null;
    }

    function checkoutAutomations(isMonth) {
        const { access } = fetchWrapper.getAuth();
        if (access) {
            const url = `payment/checkout?interval=${
                isMonth ? 'month' : 'year'
            }&email_credits_recurring=false&email_credits=0&automations=1`;
            window.location.href = `${process.env.REACT_APP_API_URL}/${url}&token=${access}`;
        }
        return null;
    }

    function portal() {
        const { access } = fetchWrapper.getAuth();
        if (access) {
            const url = 'payment/portal';
            window.open(`${process.env.REACT_APP_API_URL}/${url}?token=${access}`, '_blank');
        }
        return null;
    }

    function getPlans() {
        return fetchWrapper
            .get('payment/newplans')
            .then((data) => {
                setPlans(data);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function getAffiliateProgram() {
        return fetchWrapper
            .get('payment/affiliate')
            .then((data) => {
                setAffiliateProgram(data);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function getSubscriptions() {
        fetchWrapper
            .get('users/me/subscriptions')
            .then((data) => {
                setSubscriptions(data);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
        fetchWrapper
            .get('users/me/credits')
            .then((data) => {
                setCredits(data);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function getStripe() {
        return fetchWrapper
            .get('users/me/stripe')
            .then((data) => {
                setStripe(data);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function getInvoices() {
        return fetchWrapper
            .get('users/me/invoices')
            .then((data) => {
                setInvoices(data);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function accessAutomations() {
        return fetchWrapper
            .post('users/me/automations')
            .then((data) => {
                setUser(data);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function login({ email, password }) {
        return fetchWrapper
            .post('auth/login', { email, password })
            .then((data) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                setAuth(data.token);
                setUser(data.user);

                localStorage.setItem('auth', JSON.stringify(data.token));

                // get return url from location state or default to home page
                const { from } = history.location.state || { from: { pathname: '/' } };
                navigate(from);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function listTeam(reset) {
        setTeamUsersLoading(true);
        const teamUsers = getRecoil(teamUsersState);
        const limit = 25;
        let currentOffset = teamUsers?.items?.length || 0;
        if (reset) {
            currentOffset = 0;
        }
        fetchWrapper
            .get(`team/users?limit=${limit}&offset=${currentOffset}`)
            .then((data) => {
                const currentItems = reset ? [] : teamUsers?.items || [];
                const newConnections = { count: data.count, items: [...currentItems] };
                data.items.forEach((aff) => {
                    const index = newConnections.items.findIndex((item) => item.id === aff.id);
                    if (index !== -1) {
                        newConnections.items[index] = aff;
                    } else {
                        newConnections.items.push(aff);
                    }
                });
                setTeamUsers(newConnections);
                setTeamUsersLoading(false);
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setTeamUsersLoading(false);
            });
    }

    function affiliateLink() {
        setAffiliateLinkLoading(true);
        return fetchWrapper
            .get('users/affiliate/link')
            .then((data) => {
                setAffiliateLink(data);
                setAffiliateLinkLoading(false);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function affiliateAccount() {
        setAffiliateAccountLoading(true);
        return fetchWrapper
            .get('users/me/affiliate')
            .then((data) => {
                setAffiliateAccount(data);
                setAffiliateAccountLoading(false);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function redeem(code) {
        setErrorAppsumo(false);
        setSuccessAppsumo(false);
        return fetchWrapper
            .post(`users/redeem?code=${code}`)
            .then((data) => {
                setUser(data);
                setSuccessAppsumo(true);
            })
            .catch(() => {
                setErrorAppsumo(true);
            });
    }

    function loginGoogle(google) {
        return fetchWrapper
            .post('auth/googlelogin', google)
            .then((data) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                setAuth(data.token);
                setUser(data.user);

                localStorage.setItem('auth', JSON.stringify(data.token));

                // get return url from location state or default to home page
                const { from } = history.location.state || { from: { pathname: '/' } };
                navigate(from);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function updateTour(tourId) {
        const user = getRecoil(userState);
        const newUser = { ...user };
        newUser.tours = user.tours ? [...user.tours] : [];
        if (newUser.tours.indexOf(tourId) === -1) {
            newUser.tours.push(tourId);
        }
        setUser(newUser);
        return fetchWrapper.put(`users/me/tour?tour=${tourId}`).catch(() => fetchNetworkError(fromForm));
    }

    function listAffiliates(reset) {
        setAffiliateUsersLoading(true);
        const affiliateUsers = getRecoil(affiliateUsersState);
        const limit = 25;
        let currentOffset = affiliateUsers?.items?.length || 0;
        if (reset) {
            currentOffset = 0;
        }
        fetchWrapper
            .get(`users/my-affiliates?limit=${limit}&offset=${currentOffset}`)
            .then((data) => {
                const currentItems = reset ? [] : affiliateUsers?.items || [];
                const newConnections = { count: data.count, items: [...currentItems] };
                data.items.forEach((aff) => {
                    const index = newConnections.items.findIndex((item) => item.id === aff.id);
                    if (index !== -1) {
                        newConnections.items[index] = aff;
                    } else {
                        newConnections.items.push(aff);
                    }
                });
                setAffiliateUsers(newConnections);
                setAffiliateUsersLoading(false);
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setAffiliateUsersLoading(false);
            });
    }

    function list(reset) {
        setUsersLoading(true);
        const users = getRecoil(usersState);
        const filterUsers = getRecoil(filterUsersState);
        const filterTypes = getRecoil(filterTypesState);
        const limit = 25;
        let currentOffset = users?.items?.length || 0;
        if (reset) {
            currentOffset = 0;
        }
        fetchWrapper
            .get(`users/search?limit=${limit}&offset=${currentOffset}&filter=${filterUsers}&types=${filterTypes}`)
            .then((data) => {
                const currentItems = reset ? [] : users?.items || [];
                const newConnections = { count: data.count, items: [...currentItems] };
                data.items.forEach((lnuser) => {
                    const index = newConnections.items.findIndex((item) => item.id === lnuser.id);
                    if (index !== -1) {
                        newConnections.items[index] = lnuser;
                    } else {
                        newConnections.items.push(lnuser);
                    }
                });
                setUsers(newConnections);
                setUsersLoading(false);
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setUsersLoading(false);
            });
    }

    function removeSubscription(userId) {
        fetchWrapper
            .post(`users/${userId}/removesubscription`)
            .then(() => {
                list(true);
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setUsersLoading(false);
            });
    }

    function trialAutomations(userId) {
        fetchWrapper
            .post(`users/${userId}/automationstrial`)
            .then(() => {
                list(true);
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setUsersLoading(false);
            });
    }
    function deleteUser(userId) {
        fetchWrapper
            .delete(`users/${userId}`)
            .then(() => {
                list(true);
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setUsersLoading(false);
            });
    }

    function loginLinkedIn({ checking, logging }) {
        const extension = getRecoil(extensionState);
        if (checking) {
            if (extension === undefined) {
                setExtensionState('checking_extension');
                setTimeout(() => loginLinkedIn({ checking, logging }), checking);
                return;
            }
        }
        // eslint-disable-next-line no-undef
        if (typeof chrome === 'undefined') {
            if (utils.isMobile() || !window.isChromium) {
                setExtensionState('not_compatible');
                return;
            }
            setExtensionState('not_installed');
            return;
        }
        // eslint-disable-next-line no-undef
        if (!chrome.runtime) {
            if (utils.isMobile()) {
                setExtensionState('not_compatible');
                return;
            }
            setExtensionState('not_installed');
            // eslint-disable-next-line no-undef
            if (!chrome.runtime) {
                window.open('https://chrome.google.com/webstore/detail/bodcackmmefldjpmeefapllhcpdlhfhp');
            }

            return;
        }
        if (logging) {
            if (extension === 'checking_extension') {
                setExtensionState('logging');
                setTimeout(() => loginLinkedIn({ checking, logging }), logging);
                return;
            }
        }
        // eslint-disable-next-line no-undef
        chrome.runtime.sendMessage(
            `${process.env.REACT_APP_CHROME_EXTENSION_ID}`,
            {
                login: true,
            },
            (response) => {
                if (!response) {
                    setExtensionState('not_installed');
                    window.open('https://chrome.google.com/webstore/detail/bodcackmmefldjpmeefapllhcpdlhfhp');
                } else if (response.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    setAuth(response.token);
                    setUser(response.user);
                    if (
                        utils.getCookie('kanbox-affiliate') &&
                        !response.user.affiliate &&
                        !response.user.affiliate_partner_code
                    ) {
                        // attach affiliate
                        fetchWrapper.put(`/users/me/affiliate/${utils.getCookie('kanbox-affiliate')}`).catch(() => {
                            // do nothing
                        });
                    }
                    if (
                        (utils.getCookie('utm_campaign') && !response.user.utm_campaign) ||
                        (utils.getCookie('utm_source') && !response.user.utm_source) ||
                        (utils.getCookie('utm_referer') && !response.user.utm_referer)
                    ) {
                        // attach campaign
                        fetchWrapper
                            .put(
                                `/users/me/campaign?utm_campaign=${utils.getCookie('utm_campaign') || ''}&utm_source=${
                                    utils.getCookie('utm_source') || ''
                                }&utm_referer=${utils.getCookie('utm_referer') || ''}`
                            )
                            .catch(() => {
                                // do nothing
                            });
                    }
                    if (response.signed_up) {
                        boardsActions.createSalesBoard(tr('Demo Pipeline'));
                        labelsAction.createDefaultTags();
                        templatesAction.createDefaultTemplates(response.user.language);
                    }
                    if (response.token) {
                        localStorage.setItem('auth', JSON.stringify(response.token));
                    }
                    if (response.signed_up) {
                        setExtensionState('syncing');
                    } else {
                        setExtensionState('ok');
                    }
                } else {
                    navigate('/login');
                    setExtensionState('no_auth');
                }
            }
        );
    }

    function resetPassword({ email }) {
        return fetchWrapper
            .post('auth/requestpassword/reset', { email })
            .then(() => {
                navigate('/resetpasswordsent');
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function resetPasswordSend(reset) {
        return fetchWrapper
            .post('auth/reset_password', reset)
            .then(() => {
                navigate('/login');
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function validateEmail(token) {
        const user = getRecoil(userState);
        return fetchWrapper
            .post('auth/validateemail', { token })
            .then(() => {
                const newUser = { ...user };
                newUser.email_validated = true;
                setUser(newUser);
                setSnackMessage({ text: tr('Your email is validated'), type: 'info' });
                navigate('/');
            })
            .catch(() => {
                setSnackMessage({ text: tr('Your email could not be validated'), type: 'error' });
            });
    }

    function sendValidateEmail() {
        return fetchWrapper
            .post('users/me/validateemail')
            .then(() => {
                setSnackMessage({ text: tr('You will receive an email to validate your address'), type: 'info' });
            })
            .catch(() => {
                setSnackMessage({ text: tr('Could not send email confirmation.'), type: 'error' });
            });
    }

    function refreshMe() {
        return fetchWrapper.get('users/me').then((data) => {
            setUser(data);
        });
    }

    function toggleApi() {
        return fetchWrapper.post('team/api/toggle').then((data) => {
            const currentUser = getRecoil(userState);
            const newUser = { ...currentUser };
            newUser.team = data;
            setUser(newUser);
        });
    }

    function decreaseQuotas(type) {
        return fetchWrapper.post(`users/me/decreasequota?quota=${type}`);
    }

    function updateQuotas(isSchedule, message, messageError) {
        const mess = message || (isSchedule ? tr('Schedule updated') : tr('Quotas updated'));
        const messError =
            messageError || (isSchedule ? tr('Could not update schedule.') : tr('Could not update quotas.'));
        setSavingQuotas(true);
        const data = getRecoil(quotasState);
        return fetchWrapper
            .put('users/me/quotas', { ...data })
            .then((result) => {
                setUser(result);
                setSavingQuotas(false);
                setSnackMessage({ text: mess, type: 'info' });
            })
            .catch(() => {
                setSavingQuotas(false);
                setSnackMessage({
                    text: messError,
                    type: 'error',
                });
            });
    }

    function updateShareSearches(shareSearches) {
        setShareSearchesLoading(true);
        return fetchWrapper
            .put('team', { is_shared_leads: shareSearches })
            .then(() => {
                setShareSearchesLoading(false);
                refreshMe();
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function joinTeam(teamId) {
        return fetchWrapper
            .post(`team/join/${teamId}`)
            .then(() => {
                refreshMe();
                setSnackMessage({ text: tr("You're now part of a team."), type: 'info' });
                localStorage.removeItem('team');
            })
            .catch((e) => {
                localStorage.removeItem('team');
                setSnackMessage({ text: e, type: 'error' });
            });
    }

    function changeSubscription(product, maxCampaigns) {
        const teamMember = getRecoil(changeSubscriptionUserState);
        return fetchWrapper
            .post(`team/subscribe/${teamMember.id}?product=${product}&max_campaigns=${maxCampaigns}`)
            .then(() => {
                getSubscriptions();
                refreshMe();
                setChangeSubscriptionUser(null);
                listTeam(true);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function removeFromTeam(userId) {
        return fetchWrapper
            .delete(`team/users/${userId}`)
            .then(() => {
                listTeam(true);
            })
            .catch((e) => {
                setSnackMessage({ text: e, type: 'error' });
            });
    }

    function updateMe(me, redirect) {
        return fetchWrapper
            .put('users/me', me)
            .then((data) => {
                const newUser = { ...data };
                setUser(newUser);
                if (redirect) {
                    navigate(redirect);
                }
            })
            .catch(() => fetchNetworkError(fromForm));
    }

    function saveEmail(email) {
        setSaveEmailLoading(true);
        return fetchWrapper
            .post('auth/saveemail', { email })
            .then(() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'mail',
                });
                setSaveEmailLoading(false);
                setSaveEmailDone(true);
            })
            .catch(() => {
                fetchNetworkError();
                setSaveEmailLoading(false);
            });
    }

    function confirmEmail(token) {
        return fetchWrapper.post('auth/confirmemail', { token }).catch(() => {
            fetchNetworkError();
        });
    }

    function updateBrowsing() {
        return fetchWrapper.put('users/me/browse');
    }

    function logout() {
        // remove user from local storage, set auth state to null and redirect to login page
        localStorage.removeItem('user');
        setAuth(null);
        setUser(null);
    }

    function deleteAccount() {
        fetchWrapper
            .delete('/users/me')
            .then(() => {
                navigate('/delete');
            })
            .catch((e) => {
                setSnackMessage({ text: e, type: 'error' });
            });
    }

    function signup(userForm) {
        return fetchWrapper
            .post('auth/signup', userForm)
            .then((data) => {
                setAuth(data.token);
                setUser(data.user);

                localStorage.setItem('auth', JSON.stringify(data.token));

                const { from } = history.location.state || { from: { pathname: '/' } };
                navigate(from);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    function signupGoogle(google) {
        return fetchWrapper
            .post('auth/googlesignup', google)
            .then((data) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                setAuth(data.token);
                setUser(data.user);

                localStorage.setItem('auth', JSON.stringify(data.token));

                // get return url from location state or default to home page
                const { from } = history.location.state || { from: { pathname: '/' } };
                navigate(from);
            })
            .catch((e) => fetchNetworkError(fromForm, e));
    }

    return {
        confirmEmail,
        saveEmail,
        toggleApi,
        checkoutAutomations,
        decreaseQuotas,
        accessAutomations,
        updateShareSearches,
        changeSubscription,
        portal,
        checkoutPack,
        getStripe,
        checkout,
        joinTeam,
        removeFromTeam,
        listTeam,
        getPlans,
        listAffiliates,
        affiliateAccount,
        getAffiliateProgram,
        redeem,
        affiliateLink,
        getSubscriptions,
        getInvoices,
        list,
        updateTour,
        updateQuotas,
        login,
        loginGoogle,
        loginLinkedIn,
        updateBrowsing,
        logout,
        signup,
        signupGoogle,
        deleteAccount,
        refreshMe,
        updateMe,
        resetPassword,
        resetPasswordSend,
        validateEmail,
        sendValidateEmail,
        removeSubscription,
        trialAutomations,
        deleteUser,
    };
}
