import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import Switch from '../../components/Switch';
import { userState } from '../../_states/user.states';
import { tr } from '../../common/locale';
import useUserActions from '../../_actions/user.actions';
import Flash from '../../components/Flash';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { snackMessageState } from '../../_states/alert.states';

function Api() {
    const actions = useUserActions();
    const [show, setShow] = useState(false);
    const user = useRecoilValue(userState);
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const isTeamOwner = user && user.team && user.team.owner === user.id;
    const copy = () => {
        navigator.clipboard.writeText(user?.team?.api_key);
        setSnackMessage({ text: tr('Your API key is copied! '), type: 'success' });
    };

    return (
        <div className="content__body">
            <div className="api__header">
                <div className="flex gap">
                    <h2 className="title">{tr('API')}</h2>
                    {!isTeamOwner && (
                        <Flash color="yellow">{tr('The key is managed by your team administrator.')}</Flash>
                    )}
                </div>

                <a
                    href="https://api.kanbox.io/docs"
                    className="btn btn--primary btn--has-icon"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Icon icon="book" />
                    <span className="btn__label">{tr('Documentation')}</span>
                </a>
            </div>
            <div className="settings__content api">
                <p className="mb--20 bold">
                    {tr(
                        'Using the API, you can add leads in lists and update contacts. You can access the API documentation'
                    )}
                    &nbsp;
                    <a href="https://api.kanbox.io/docs" target="_blank" rel="noreferrer">
                        {tr('here')}.
                    </a>
                </p>
                {!user?.plan.has_webhooks && (
                    <Flash color="yellow" className="mb--30">
                        {tr('You cannot use the API with your subscription.')}&nbsp;
                        <Link to="/subscription">{tr('Please upgrade your subscription.')}</Link>
                    </Flash>
                )}
                {user?.plan.has_webhooks && (
                    <div>
                        <div className="mb--20">
                            <Switch
                                text={tr('Enable API key')}
                                id="saturday"
                                disabled={!isTeamOwner}
                                checked={user?.team?.is_api_enabled}
                                onChange={() => actions.toggleApi()}
                            />
                        </div>
                        {user?.team?.is_api_enabled && (
                            <>
                                <div
                                    className="api__link mb--20"
                                    tabIndex="-1"
                                    role="button"
                                    onKeyDown={copy}
                                    onClick={copy}
                                >
                                    <span className="api__link__key">
                                        {show
                                            ? user?.team?.api_key
                                            : `**********************************${user?.team?.api_key.substring(
                                                  user?.team?.api_key.length - 5,
                                                  user?.team?.api_key.length
                                              )}`}
                                    </span>
                                    <Button
                                        type="white"
                                        isBordered
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShow(!show);
                                        }}
                                        icon={!show ? 'show' : 'hide'}
                                    />
                                </div>
                                <div>
                                    <span
                                        className="btn btn--primary copy"
                                        tabIndex="-1"
                                        role="button"
                                        onKeyDown={copy}
                                        onClick={copy}
                                    >
                                        {tr('Copy Key')}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
export default Api;
