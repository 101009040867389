import { useRecoilState, useSetRecoilState } from 'recoil';
import { errorsState } from '../_states/form.states';
import { snackMessageState } from '../_states/alert.states';
import { tr } from '../common/locale';
import Log from '../common/Log';
import useAlertActions from '../_actions/alert.actions';

export default function useError() {
    const [errors, setErrors] = useRecoilState(errorsState);
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const { clear } = useAlertActions();
    const fetchNetworkError = (fromForm, e, errorMessage) => {
        if (e?.name !== 'AbortError') {
            Log.error(e);
            const newErrors = { ...errors };
            const from = fromForm || 'default';
            newErrors[from] = { ...errors[from] };
            newErrors[from].default = errorMessage || tr('An error has occured');
            console.log('e, errorMessage', e, errorMessage);
            if (from === 'default') {
                if (e === 'Too Many Requests') {
                    setSnackMessage({ text: tr('Could not complete request. Quota exceeded.'), type: 'error' });
                } else if (e === 'Could not complete request. Quota exceeded.') {
                    setSnackMessage({ text: tr('Could not complete request. Not enough credits.'), type: 'error' });
                } else if (e && e.startsWith && e.startsWith('Could not parse')) {
                    setSnackMessage({ text: tr('An error has occured'), type: 'error' });
                } else if (e && e.startsWith && e.startsWith('Search is being processed')) {
                    setSnackMessage({ text: tr('Search is being processed'), type: 'error' });
                } else if (e && e.startsWith && e.startsWith('Monthly limit reached')) {
                    setSnackMessage({ text: tr('Monthly limit reached'), type: 'error' });
                } else if (e === 'Not Found') {
                    // do nothing
                } else {
                    setSnackMessage({ text: errorMessage || tr('Connection recovery... Please wait'), type: 'error' });
                }
            }
            setErrors(newErrors);
        }
    };
    const showError = (errorMessage) => {
        setSnackMessage({ text: errorMessage, type: 'error' });
    };
    const clearNetworkError = (fromForm) => {
        const newErrors = { ...errors };
        const from = fromForm || 'default';
        if (newErrors[from]) {
            delete newErrors[from];
            if (from === 'default') {
                clear();
            }
            setErrors(newErrors);
        }
    };
    return {
        showError,
        fetchNetworkError,
        clearNetworkError,
    };
}
