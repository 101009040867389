import cl from 'classnames';
import { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Favico from 'favico.js';
import { bulkTasksCountState } from '../_states/lnusers.states';
import Icon from '../components/Icon';
import Button from '../components/Button';
import TooltipPopover from '../components/TooltipPopover';
import { tr } from '../common/locale';
import { userState, notifsState } from '../_states/user.states';
import useAlertActions from '../_actions/alert.actions';
import Loader from '../components/Loader';
import utils from '../common/utils';

const favicon = new Favico({
    animation: 'pop',
    position: 'upleft',
});

export default function Sidebar() {
    const user = useRecoilValue(userState);
    const notifs = useRecoilValue(notifsState);
    const bulkTasksCount = useRecoilValue(bulkTasksCountState);
    const { warn, info } = useAlertActions();
    const [screenMode, setScreenMode] = useState(localStorage.getItem('screenMode') || 'light');

    useEffect(() => {
        localStorage.setItem('screenMode', screenMode);
        document.body.className = '';
        document.body.className = screenMode;
        if (notifs?.version) {
            const currentVersion = localStorage.getItem('kanbox');
            if (currentVersion && JSON.parse(currentVersion) !== notifs.version) {
                warn(tr('A new version of Kanbox is available. Please, refresh your page.'));
                setTimeout(() => {
                    window.location.reload();
                }, 600 * 1000);
                localStorage.setItem('kanbox', JSON.stringify(notifs.version));
            } else {
                localStorage.setItem('kanbox', notifs.version);
            }
        }
        if (notifs?.inbox > 0) {
            favicon.badge(notifs.inbox);
        } else {
            favicon.reset();
        }
    }, [notifs, screenMode]);
    useEffect(() => {
        if (user?.plan?.product === 'TRIAL' && utils.trialEnd(user) <= 6 && utils.trialEnd(user) > 0) {
            info(
                <span>
                    <span>
                        {tr('Your trial is going to end in {{count}} day.', { count: utils.trialEnd(user) })}&nbsp;
                    </span>
                    <Link className="black" to="/subscription">
                        {tr('Click here to subscribe')}
                    </Link>
                </span>
            );
        }
    }, [user]);
    return (
        <div className={cl('sidebar')}>
            <div className={cl('sidebar__header')}>
                <div className={cl('sidebar__header__logo')}>
                    <NavLink to="/">
                        <Loader isChat isQuick isSmall />
                    </NavLink>
                </div>
                <div className={cl('sidebar__header__tasks')}>
                    <TooltipPopover direction="e" text={tr('Asynchronous tasks')}>
                        <NavLink className="btn--sidebar-link" to="/tasks">
                            <div className={`loader-cloud-wrapper ${bulkTasksCount > 0 ? 'background' : ''}`}>
                                <div className="loader-cloud" />
                            </div>
                        </NavLink>
                    </TooltipPopover>
                </div>
            </div>
            {!user && (
                <div className="sidebar__main">
                    <Icon fill icon="load" />
                </div>
            )}
            {user && (
                <>
                    <div className="sidebar__main">
                        <ul className="sidebar__main__list">
                            <li className="sidebar__main__list__item">
                                <TooltipPopover direction="e" text={tr('Inbox')}>
                                    <NavLink
                                        className={`btn btn--sidebar-link ${
                                            notifs.inbox > 0 ? 'btn--notification' : ''
                                        }`}
                                        to="/"
                                    >
                                        <Icon size={26} icon="message" />
                                    </NavLink>
                                </TooltipPopover>
                            </li>
                            <li className="sidebar__main__list__item tour-lead-manager">
                                <TooltipPopover direction="e" text={tr('Leads')}>
                                    <NavLink
                                        className="btn btn--sidebar-link"
                                        to="/searches"
                                        state={{ key: Math.random() }}
                                    >
                                        <Icon size={26} icon="crosshair" />
                                    </NavLink>
                                </TooltipPopover>
                            </li>
                            <li className="sidebar__main__list__item tour-campaigns">
                                <TooltipPopover direction="e" text={tr('Automations')}>
                                    <NavLink className="btn btn--sidebar-link" to="/campaigns">
                                        <Icon size={26} icon="play-square" />
                                    </NavLink>
                                </TooltipPopover>
                            </li>
                            <li className="sidebar__main__list__item tour-pipelines">
                                <TooltipPopover direction="e" text={tr('Pipelines')}>
                                    <NavLink className="btn btn--sidebar-link" to="/board">
                                        <Icon size={26} icon="board" />
                                    </NavLink>
                                </TooltipPopover>
                            </li>
                            <li className="sidebar__main__list__item tour-templates">
                                <TooltipPopover direction="e" text={tr('Template Messages')}>
                                    <NavLink className="btn btn--sidebar-link" to="/templates">
                                        <Icon size={26} icon="document" />
                                    </NavLink>
                                </TooltipPopover>
                            </li>
                        </ul>
                    </div>
                    <div className="sidebar__footer">
                        <ul className="sidebar__footer__list">
                            {user?.is_admin && (
                                <li className="sidebar__main__list__item">
                                    <TooltipPopover direction="e" text={tr('Administration')}>
                                        <NavLink className="btn btn--sidebar-link" to="/admin">
                                            <Icon size={26} icon="private" />
                                        </NavLink>
                                    </TooltipPopover>
                                </li>
                            )}
                            {user?.plan.owner === user.id && (
                                <li className="sidebar__footer__list__item">
                                    <TooltipPopover direction="e" text={tr('Subscription')}>
                                        <NavLink className="btn btn--sidebar-link" to="/subscription">
                                            <Icon size={26} icon="credit-card" />
                                        </NavLink>
                                    </TooltipPopover>
                                </li>
                            )}
                            <li className="sidebar__footer__list__item">
                                <TooltipPopover direction="e" text={tr('Settings')}>
                                    <NavLink className="btn btn--sidebar-link" to="/settings">
                                        <Icon size={26} icon="equalizer" />
                                    </NavLink>
                                </TooltipPopover>
                            </li>
                            <li className="sidebar__footer__list__item">
                                <Button
                                    type="dark-mode"
                                    iconSize={16}
                                    icon={screenMode === 'light' ? 'moon' : 'sun'}
                                    onClick={() =>
                                        screenMode === 'light' ? setScreenMode('dark') : setScreenMode('light')
                                    }
                                />
                            </li>
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
}
