import { useEffect, useState, useRef } from 'react';
import { Outlet, Navigate, useLocation, Link } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Crisp } from 'crisp-sdk-web';
import moment from 'moment';
import { tr, getLocale } from './locale';
import Sidebar from '../pages/Sidebar';
import LnUser from '../pages/lnuser/LnUser';
import { authState, userState, upgradeState } from '../_states/user.states';
import { newMessageState, newReactionState } from '../_states/lnuser.states';
import useUserActions from '../_actions/user.actions';
import useLabelActions from '../_actions/label.actions';
import useBoardActions from '../_actions/board.actions';
import useLinkedInActions from '../_actions/linkedin.actions';
import useTemplateActions from '../_actions/template.actions';
import useLnUsersActions from '../_actions/lnusers.actions';
import utils from './utils';
import initRealTime from './LinkedInRealtime';
import Modal from '../components/Modal';

export default function PrivateRoute({ redirectPath = '/login', children }) {
    const auth = useRecoilValue(authState);
    const location = useLocation();
    const user = useRecoilValue(userState);
    const newMessage = useRecoilValue(newMessageState);
    const newReaction = useRecoilValue(newReactionState);
    const [upgrade, setUpgrade] = useRecoilState(upgradeState);
    const { loginLinkedIn, joinTeam } = useUserActions();
    const labelActions = useLabelActions();
    const lnusersActions = useLnUsersActions();
    const boardActions = useBoardActions();
    const templateActions = useTemplateActions();
    const actions = useLinkedInActions();
    const [pollingStarted, setPollingStarted] = useState(false);
    const team = localStorage.getItem('team');
    const upgradeRef = useRef();
    useEffect(() => {
        if (!user && auth) {
            loginLinkedIn({ checking: undefined, logging: undefined, syncing: undefined });
        }
        if (auth) {
            labelActions.get();
            boardActions.getBoards();
            templateActions.get();
            initRealTime();
        }
        if (user) {
            if (window.Produktly) {
                window.Produktly.identifyUser(user.id, {
                    plan: user.plan?.product,
                    appsumo: !!user.appsumo_code1,
                    automations: user.plan?.has_automations,
                    language: getLocale(),
                    createdAt: moment(user.created_at).unix(),
                });
            }
            Crisp.user.setEmail(user.email);
            Crisp.user.setNickname(`${user.firstname} ${user.lastname}`);

            Crisp.session.setData({
                user_id: user.id,
                plan: user.plan?.product,
            });
            actions.getNotifs();
        }
    }, []);
    useEffect(() => {
        if (newMessage) {
            actions.startConversationsSync('unread', 1);
            actions.updateMessages(newMessage);
            lnusersActions.list(false, true, 0);
        }
    }, [newMessage]);
    useEffect(() => {
        actions.updateReaction(newReaction);
    }, [newReaction]);
    useEffect(() => {
        if (upgrade) {
            upgradeRef.current.open();
        }
    }, [upgrade]);
    useEffect(() => {
        if (user && auth && !pollingStarted) {
            actions.polling();
            setPollingStarted(true);
        }
        if (user && team && (!user.team || user.team.code !== team)) {
            joinTeam(team);
        }
    }, [auth, user]);
    if (
        user &&
        user.plan?.product === 'TRIAL' &&
        location.pathname.indexOf('subscription') === -1 &&
        location.pathname.indexOf('settings') === -1 &&
        location.pathname.indexOf('team') === -1 &&
        location.pathname.indexOf('appsumo') === -1 &&
        location.pathname.indexOf('affiliate') === -1 &&
        utils.trialEnd(user) <= 0
    ) {
        return <Navigate to="/subscription" replace />;
    }
    if (!auth) {
        return <Navigate to={redirectPath} replace />;
    }

    const redirect = localStorage.getItem('redirect');
    if (user && redirect && redirect !== 'null') {
        localStorage.removeItem('redirect');
        return <Navigate to={redirect} replace />;
    }

    return (
        <div className="wrapper">
            <Modal
                closesWithEscape
                ref={upgradeRef}
                title={tr('Upgrade subscription')}
                closeCb={() => setUpgrade(false)}
                labelValidate={tr('Close')}
            >
                {tr('This feature is not available with your current plan.')}
                <br />
                <Link to="/subscription" onClick={() => upgradeRef.current.close()}>
                    {tr('Please upgrade your subscription.')}
                </Link>
            </Modal>
            <LnUser />
            <Sidebar />
            {children || <Outlet />}
        </div>
    );
}
