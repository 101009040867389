import { useEffect, useState, useRef } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { tr } from '../../common/locale';
import { boardsState } from '../../_states/lnusers.states';
import { userState } from '../../_states/user.states';
import Flash from '../../components/Flash';
import {
    webhooksState,
    loadingState,
    creatingState,
    addingboardState,
    sendingState,
    removingboardState,
} from '../../_states/webhook.states';
import useWebhooksActions from '../../_actions/webhook.actions';
import ConfirmButton from '../../components/ConfirmButton';
import Dropdown from '../../components/Dropdown';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import Icon from '../../components/Icon';
import Button from '../../components/Button';

function Webhooks() {
    const actions = useWebhooksActions();
    const createRef = useRef();
    const pipelinesRef = useRef();
    const managePipelinesRef = useRef();
    const webhooks = useRecoilValue(webhooksState);
    const user = useRecoilValue(userState);
    const sending = useRecoilValue(sendingState);
    const [creating, setCreating] = useRecoilState(creatingState);
    const [addingboard, setAddingboard] = useRecoilState(addingboardState);
    const [removingboard, setRemovingboard] = useRecoilState(removingboardState);
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const boards = useRecoilValue(boardsState);
    const loading = useRecoilValue(loadingState);
    const [board, setBoard] = useState(null);
    const [webhook, setWebhook] = useState(null);
    const [step, setStep] = useState(null);
    useEffect(() => {
        actions.get();
    }, []);
    useEffect(() => {
        if (creating) {
            createRef.current?.open();
        } else {
            createRef.current?.close();
        }
    }, [creating]);
    useEffect(() => {
        if (addingboard) {
            pipelinesRef.current?.open();
        } else {
            pipelinesRef.current?.close();
        }
    }, [addingboard]);
    useEffect(() => {
        if (removingboard) {
            managePipelinesRef.current?.open();
        } else {
            managePipelinesRef.current?.close();
        }
    }, [removingboard]);

    const hasMore = !webhooks || webhooks?.count > webhooks?.items?.length;
    const fakeLoader = [];
    for (let i = 0; i <= 20; i++) {
        fakeLoader.push(
            <div key={i} className="webhook__item webhook__item--fake">
                <div className="webhook__info">
                    <div className="webhook__created">
                        <span />
                    </div>
                    <div className="webhook__name">
                        <span />
                    </div>
                    <div className="webhook__url">
                        <span />
                    </div>
                    <div className="webhook__trigger">
                        <div className="webhook__trigger__item">
                            <span />
                        </div>
                        <div className="webhook__trigger__item">
                            <span />
                        </div>
                        <div className="webhook__trigger__item">
                            <span />
                        </div>
                    </div>
                    <span className="fake-btn" />
                </div>
                <div className="webhook__actions">
                    <span />
                </div>
            </div>
        );
    }

    return (
        <div className="content team">
            <Modal
                ref={createRef}
                disabled={!name || !url}
                validateCb={() => {
                    actions.add(name, url);
                }}
                closeCb={() => {
                    setCreating(false);
                    setUrl('');
                    setName('');
                }}
                title={tr('Create a webhook')}
                labelValidate={tr('Create')}
            >
                <Input
                    label={tr('Name your webhook')}
                    placeholder={tr('Type in a name')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <div className="flex-bottom mt--30 mb--30 mbi--small">
                    <Input
                        label={tr('Url of your webhook')}
                        placeholder={tr('Type in a Url')}
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                    <Button
                        type="white"
                        isBordered
                        label={tr('Test Send')}
                        disabled={!url}
                        onClick={() => actions.testsend(url)}
                        isLoading={sending}
                    />
                </div>
            </Modal>
            <Modal
                ref={managePipelinesRef}
                closeCb={() => {
                    setRemovingboard(false);
                    setUrl('');
                    setName('');
                }}
                title={tr('Manage triggers')}
                labelValidate={tr('Close')}
            >
                {webhook?.step_set.map((currentStep) => (
                    <div>
                        {boards.find((current) => current.id === currentStep.board).name}&nbsp;
                        {currentStep.title}
                        <ConfirmButton
                            type="link"
                            icon="trash-can"
                            confirmLabel={tr('Do you confirm you want to remove this trigger?')}
                            onConfirm={() => actions.removeBoard(webhook, currentStep)}
                        />
                    </div>
                ))}
            </Modal>
            <Modal
                ref={pipelinesRef}
                disabled={!board || !step}
                overflow
                validateCb={() => {
                    actions.addBoard(webhook, board, step);
                }}
                closeCb={() => {
                    setAddingboard(false);
                    setBoard(null);
                    setStep(null);
                    setWebhook(null);
                }}
                title={tr('Choose a pipeline column')}
                labelValidate={tr('Create')}
            >
                <div className="mb--20">
                    {tr('Select the pipeline column you want to trigger the webhook')} <strong>{webhook?.name}</strong>
                </div>
                <div className="btn--group">
                    {boards?.length > 0 && (
                        <>
                            <Dropdown
                                iconRight
                                btnFaceIconSize={16}
                                btnFace="secondary-dark"
                                btnClassName="btn--group-item"
                                onClick={(e) => e.stopPropagation()}
                                label={board?.name || tr('Select a Pipeline')}
                                onDoubleClick={(e) => e.stopPropagation()}
                            >
                                <Dropdown.Menu>
                                    {boards.map((element) => (
                                        <Dropdown.ButtonItem
                                            key={element?.id}
                                            btnLabel={element.name}
                                            onClick={() => {
                                                setBoard(element);
                                            }}
                                        />
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown
                                iconRight
                                btnFaceIconSize={16}
                                btnFace="secondary-dark"
                                btnClassName="btn--group-item"
                                label={step?.title || tr('Select a step')}
                                onClick={(e) => e.stopPropagation()}
                                onDoubleClick={(e) => e.stopPropagation()}
                            >
                                <Dropdown.Menu>
                                    {board?.lanes
                                        ?.map((element) => element)
                                        .sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10))
                                        .map((element) => (
                                            <Dropdown.ButtonItem
                                                key={element?.id}
                                                btnLabel={element.title}
                                                onClick={() => {
                                                    setStep(element);
                                                }}
                                            />
                                        ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    )}
                </div>
            </Modal>
            <div className="content__body">
                <div className="webhook__wrapper">
                    <div className="webhook__header">
                        <div className="webhook__header__title">
                            <h2 className="title">{tr('Webhooks')}</h2>
                        </div>
                        <Button icon="plus" label={tr('Add')} onClick={() => setCreating(true)} />
                    </div>

                    <div
                        id="webhookList"
                        className={`webhook__list-container ${
                            loading || (webhooks && webhooks?.items?.length > 0) ? '' : 'list--empty'
                        }`}
                    >
                        <div>
                            <p className="mb--20 bold">
                                {tr(
                                    'Using webhooks, you can send contacts to external tools. You can access the Webhooks documentation'
                                )}
                                &nbsp;
                                <a
                                    href="https://help.kanbox.io/en/category/integrations-and-webhooks"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {tr('here')}.
                                </a>
                            </p>
                            {!user?.plan.has_webhooks && (
                                <Flash color="yellow" className="mb--30">
                                    {tr('You cannot use webhooks with your subscription.')}&nbsp;
                                    <Link to="/subscription">{tr('Please upgrade your subscription.')}</Link>
                                </Flash>
                            )}
                        </div>
                        {loading && (!webhooks || webhooks?.items.length === 0) && fakeLoader}
                        {user?.plan.has_webhooks && (
                            <InfiniteScroll
                                next={actions.listTeam}
                                dataLength={webhooks?.items.length || 0}
                                className="webhook__list"
                                hasMore={hasMore}
                                loader={fakeLoader}
                                scrollableTarget="webhookList"
                            >
                                {webhooks &&
                                    webhooks.items.map((currentWebhook) => (
                                        <div className="webhook__item" key={currentWebhook.id}>
                                            <div className="webhook__info">
                                                <div className="webhook__created">
                                                    {moment(currentWebhook.created_at).format('LL')}
                                                </div>
                                                <div className="webhook__name">{currentWebhook.name}</div>
                                                <div className="webhook__url">{currentWebhook.url}</div>
                                                <div className="webhook__trigger">
                                                    {currentWebhook?.step_set.map((currentStep) => (
                                                        <div className="webhook__trigger__item">
                                                            <div className="webhook__trigger__item__path">
                                                                <span className="ellipsis">
                                                                    {
                                                                        boards?.find(
                                                                            (current) =>
                                                                                current.id === currentStep.board
                                                                        ).name
                                                                    }
                                                                </span>
                                                                <Icon size="14" icon="caret-right" />
                                                                <span className="ellipsis">{currentStep.title}</span>
                                                            </div>
                                                            <ConfirmButton
                                                                type="link"
                                                                icon="close-circle"
                                                                iconSize={18}
                                                                confirmLabel={tr(
                                                                    'Do you confirm you want to remove this trigger?'
                                                                )}
                                                                onConfirm={() =>
                                                                    actions.removeBoard(currentWebhook, currentStep)
                                                                }
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <Button
                                                    type="link-primary"
                                                    label={tr('Add new pipeline column trigger')}
                                                    icon="plus-circle"
                                                    iconSize={18}
                                                    onClick={() => {
                                                        setWebhook(currentWebhook);
                                                        setAddingboard(true);
                                                    }}
                                                />
                                            </div>
                                            <div className="webhook__actions">
                                                <ConfirmButton
                                                    type="link"
                                                    icon="trash-can"
                                                    confirmLabel={tr('Do you confirm you want to remove this Webhook?')}
                                                    onConfirm={() => actions.deleteWebhook(currentWebhook.id)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </InfiniteScroll>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Webhooks;
