import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../components/Loader';
import Input from '../components/Input';
import Button from '../components/Button';
import { tr } from '../common/locale';
import { extensionState, authState, saveEmailLoadingState, saveEmailDoneState } from '../_states/user.states';
import Logo from '../images/logo.svg';
import LogoDark from '../images/logo-dark.svg';
import ChromeButton from '../images/kanbox-extension-button-blue.svg';
import KanboxHero from '../images/scrape-linkedin-sales-navigator.webp';
import KanboxHeroDark from '../images/scrape-linkedin-sales-navigator-dark.webp';
import SocialLinkedin from '../images/social-linkedin.svg';
import SocialKanbox from '../images/social-kanbox.svg';
import useUserActions from '../_actions/user.actions';
import useLinkedInActions from '../_actions/linkedin.actions';
import utils from '../common/utils';

function LoginForm() {
    const userActions = useUserActions('login');
    const actions = useLinkedInActions();
    const [email, setEmail] = useState('');
    const auth = useRecoilValue(authState);
    const [searchParams] = useSearchParams();
    const redirect = searchParams.get('redirect');
    const team = searchParams.get('team');
    const utmSource = searchParams.get('utm_source');
    if (utmSource) {
        const cookieName = 'utm_source';
        const cookie = utils.getCookie(cookieName);
        if (!cookie) {
            const cookieValue = utmSource;
            const myDate = new Date();
            myDate.setMonth(myDate.getMonth() + 1);
            document.cookie = `${cookieName}=${cookieValue};expires=${myDate};domain=.kanbox.io;path=/`;
        }
    }

    const extension = useRecoilValue(extensionState);
    const saveEmailLoading = useRecoilValue(saveEmailLoadingState);
    const saveEmailDone = useRecoilValue(saveEmailDoneState);
    const navigate = useNavigate();
    useEffect(() => {
        if (redirect) {
            localStorage.setItem('redirect', redirect);
        }
        if (team) {
            localStorage.setItem('team', team);
        }
        userActions.loginLinkedIn({ checking: 1000, logging: 1000 });
    }, []);
    useEffect(() => {
        if (auth && extension === 'syncing') {
            actions.initialSync();
        }
    }, [auth, extension]);
    useEffect(() => {
        if (extension === 'ok') {
            setTimeout(() => {
                const { from } = { from: { pathname: '/' } };
                navigate(from);
            }, 3000);
        }
    }, [extension]);
    let content = null;
    const mobilebrand = (
        <div className="mobile">
            <img
                className="login__logo"
                src={Logo}
                alt={tr('Scraping Linkedin, Overpowered Linkedin Messaging Inbox and CRM Pipelines')}
            />
            <img
                className="login__logo--dark"
                src={LogoDark}
                alt={tr('Scraping Linkedin, Overpowered Linkedin Messaging Inbox and CRM Pipelines')}
            />
            <p className="login__par login__par__title">{tr('Welcome in Kanbox App')}</p>
        </div>
    );
    const sidebar = (
        <div className="login__left">
            <img
                className="login__logo"
                src={Logo}
                alt={tr('Scraping Linkedin, Overpowered Linkedin Messaging Inbox and CRM Pipelines')}
            />
            <img
                className="login__logo--dark"
                src={LogoDark}
                alt={tr('Scraping Linkedin, Overpowered Linkedin Messaging Inbox and CRM Pipelines')}
            />
            <p className="login__par login__par__title">{tr('Welcome in Kanbox App')}</p>
            <p className="login__par">{tr('Allow Kanbox to guide you')}</p>
            <img className="login__hero" src={KanboxHero} alt={tr('Welcome in Kanbox App')} />
            <img className="login__hero--dark" src={KanboxHeroDark} alt={tr('Welcome in Kanbox App')} />
            <img
                alt="track"
                src="https://ct.capterra.com/capterra_tracker.gif?vid=2304653&vkey=6d5fc615727e5ddd7f87ee1138babd36"
            />
        </div>
    );
    if (extension === 'checking_extension') {
        content = (
            <div className="login desktop">
                {sidebar}
                <div className="login__right">
                    {mobilebrand}
                    <div className="login__step">
                        <div className="login__step__header">
                            <span className="login__step__label">{tr('Step 1')}</span>
                            <div className="bullet-container">
                                <span className="bullet current" />
                                <span className="bullet" />
                                <span className="bullet" />
                            </div>
                        </div>
                        <div className="login__step__body">
                            <h1>{tr('Seaching Kanbox Chrome extension...')}</h1>
                            <Loader isChat text={tr('Please wait...')} />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (extension === 'not_compatible') {
        content = (
            <div className="login__right get-email">
                {mobilebrand}
                <div className="login__step">
                    {!saveEmailDone && (
                        <>
                            <div className="login__step__header">
                                <span className="login__step__label">{tr('Signup')}</span>
                            </div>
                            <div className="login__step__body">
                                <h1>{tr('Your email address')}</h1>
                                <div className="login__form">
                                    <p className="par">
                                        {tr('Please, fill in your email address to continue the signup process')}
                                    </p>
                                    <Input
                                        size="large"
                                        value={email}
                                        placeholder={tr('Your email')}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Button
                                        size="large"
                                        onClick={() => userActions.saveEmail(email)}
                                        label={tr('Submit')}
                                        isLoading={saveEmailLoading}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {saveEmailDone && (
                        <>
                            <div className="login__step__header">
                                <span className="login__step__label">{tr('Email sent')}</span>
                            </div>
                            <div className="login__step__body">
                                <div className="login__form">
                                    <h1>{tr('Check your inbox and verify your email')}</h1>
                                    <p className="par">
                                        {tr(
                                            'You should have received an email in your inbox. Please, click the link in the email to verify it.'
                                        )}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    } else if (extension === 'not_installed') {
        content = (
            <div className="login desktop">
                {sidebar}
                <div className="login__right">
                    {mobilebrand}
                    <div className="login__step">
                        <div className="login__step__header">
                            <span className="login__step__label">{tr('Step 1')}</span>
                            <div className="bullet-container">
                                <span className="bullet current error" />
                                <span className="bullet" />
                                <span className="bullet" />
                            </div>
                        </div>
                        <div className="login__step__body">
                            <h3 className="not-found">{tr('Chrome extension not found')}</h3>
                            <h1>{tr('Please use Chrome and install the extension to access this application.')}</h1>
                            <p className="par">{tr('Click the button below to install Kanbox Extension')}</p>
                            <a
                                target="_blank"
                                href="https://chrome.google.com/webstore/detail/bodcackmmefldjpmeefapllhcpdlhfhp"
                                rel="noreferrer"
                            >
                                <img
                                    className="chrome-extension-button"
                                    src={ChromeButton}
                                    alt="Install Chrome Extension"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (extension === 'logging') {
        content = (
            <div className="login desktop">
                {sidebar}
                <div className="login__right">
                    {mobilebrand}
                    <div className="login__step">
                        <div className="login__step__header">
                            <span className="login__step__label">{tr('Step 2')}</span>
                            <div className="bullet-container">
                                <span className="bullet current" />
                                <span className="bullet current" />
                                <span className="bullet" />
                            </div>
                        </div>
                        <div className="login__step__body">
                            <h1>{tr('Connecting to your Linkedin Account')}</h1>
                            <Loader isChat text="Please wait while logging in..." />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (extension === 'no_auth') {
        content = (
            <div className="login desktop">
                {sidebar}
                <div className="login__right">
                    {mobilebrand}
                    <div className="login__step">
                        <div className="login__step__header">
                            <span className="login__step__label">{tr('Step 2')}</span>
                            <div className="bullet-container">
                                <span className="bullet current" />
                                <span className="bullet current error" />
                                <span className="bullet" />
                            </div>
                        </div>
                        <div className="login__step__body">
                            <h3 className="not-found">{tr('Could not authenticate with LinkedIn')}</h3>
                            <h1>{tr('Please log in to LinkedIn in another tab and click "retry".')}</h1>
                            <Button size="large" label={tr('Retry')} onClick={() => document.location.reload()} />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (extension === 'syncing') {
        content = (
            <div className="login desktop">
                {sidebar}
                <div className="login__right">
                    {mobilebrand}
                    <div className="login__step">
                        <div className="login__step__header">
                            <span className="login__step__label">{tr('Step 3')}</span>
                            <div className="bullet-container">
                                <span className="bullet current" />
                                <span className="bullet current" />
                                <span className="bullet current" />
                            </div>
                        </div>
                        <div className="login__step__body">
                            <h1>{tr('Synchronizing Linkedin members')}</h1>
                            <p className="par">{tr('Please wait, it can take more than one minute')}</p>
                            <div className="linkedin-sync">
                                <img className="linkedin-sync-images" src={SocialLinkedin} alt="LinkedIn" />
                                <span className="loader-bullets" />
                                <img className="linkedin-sync-images" src={SocialKanbox} alt="Kanbox" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="login desktop">
                {sidebar}
                <div className="login__right">
                    {mobilebrand}
                    <div className="login__step">
                        <div className="login__step__header">
                            <span className="login__step__label success">{tr('Success')}</span>
                            <div className="bullet-container">
                                <span className="bullet done" />
                                <span className="bullet done" />
                                <span className="bullet done" />
                            </div>
                        </div>
                        <div className="login__step__body">
                            <h1>{tr("Alright, let's go!")}</h1>
                            <span className="loader-love loader-love--large" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return content;
}

export default LoginForm;
