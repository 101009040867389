import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { getRecoil } from 'recoil-nexus';
import useFetchWrapper from '../_helpers/fetchWrapper.helpers';
import {
    loadingState,
    searchesState,
    searchState,
    loadingLeadsState,
    leadsState,
    savingState,
    csvLeadExportState,
    toLnUsersState,
    searchToImportState,
    toLnUserState,
    selectedLeadState,
    deleteLeadsState,
    searchesOfLeadState,
    leadSearchState,
    filterSearchLeadsState,
    moveLeadSearchState,
    filterArchiveState,
    selectedLeadsState,
    createListState,
    statusImportLeadsState,
    filterOwnerState,
    filterSearchState,
} from '../_states/leads.states';
import { tr } from '../common/locale';
import { snackMessageState } from '../_states/alert.states';
import useError from '../_helpers/errorsWrapper.helpers';

export default function useLeadsActions() {
    const fetchWrapper = useFetchWrapper();
    const { fetchNetworkError } = useError();
    const navigate = useNavigate();
    const setLoading = useSetRecoilState(loadingState);
    const setCsvExport = useSetRecoilState(csvLeadExportState);
    const setLoadingLeads = useSetRecoilState(loadingLeadsState);
    const setSearches = useSetRecoilState(searchesState);
    const setLeads = useSetRecoilState(leadsState);
    const setSelectedLead = useSetRecoilState(selectedLeadState);
    const setDeleteLeads = useSetRecoilState(deleteLeadsState);
    const setMoveLeadSearch = useSetRecoilState(moveLeadSearchState);
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const setSaving = useSetRecoilState(savingState);
    const setToLnUsers = useSetRecoilState(toLnUsersState);
    const setSearchesOfLead = useSetRecoilState(searchesOfLeadState);
    const setLeadSearch = useSetRecoilState(leadSearchState);
    const setSearchToImport = useSetRecoilState(searchToImportState);
    const setToLnUser = useSetRecoilState(toLnUserState);
    const setSearch = useSetRecoilState(searchState);
    const setSelectedLeads = useSetRecoilState(selectedLeadsState);
    const setCreateList = useSetRecoilState(createListState);
    const setStatusImportLeads = useSetRecoilState(statusImportLeadsState);

    function updateInSearches(paramSearch) {
        const searches = getRecoil(searchesState);
        if (searches) {
            const newSearches = { count: searches.count, items: [...searches.items] };

            const index = newSearches.items.findIndex((item) => item.id === paramSearch?.id);
            const filterArchive = getRecoil(filterArchiveState);
            if (index !== -1) {
                if (paramSearch.is_archived !== filterArchive) {
                    newSearches.items.splice(index, 1);
                } else {
                    newSearches.items[index] = paramSearch;
                }
            }

            setSearches(newSearches);
        }
    }

    function refreshSearch(id) {
        fetchWrapper
            .get(`searches/${id}`)
            .then((data) => {
                updateInSearches(data);
                const currentSearch = getRecoil(searchState);
                if (currentSearch && data.id === currentSearch.id) {
                    setSearch(data);
                }
                if (data.is_enrich_processing) {
                    setTimeout(() => {
                        refreshSearch(data.id);
                    }, 30000);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function list(reset) {
        if (reset) {
            setLoading(true);
        }
        const limit = 100;
        const filterArchive = getRecoil(filterArchiveState);
        const filterSearch = getRecoil(filterSearchState);
        const filterOwner = getRecoil(filterOwnerState);
        const searches = getRecoil(searchesState);
        fetchWrapper
            .get(
                `searches?limit=${limit}&offset=${reset ? 0 : searches?.items?.length || 0}${
                    filterOwner ? `&owner=${filterOwner.id}` : ''
                }&q=${filterSearch || ''}&is_archived=${filterArchive}`
            )
            .then((data) => {
                const currentItems = reset ? [] : searches?.items || [];
                const newSearches = { count: data.count, items: [...currentItems, ...data.items] };
                newSearches.items.forEach((item) => {
                    if (item.is_enrich_processing) {
                        setTimeout(() => {
                            refreshSearch(item.id);
                        }, 30000);
                    }
                });
                setSearches(newSearches);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                fetchNetworkError(null, e);
            });
    }

    function updateSearch(id, searchToUpdate) {
        const search = getRecoil(searchState);
        fetchWrapper
            .put(`searches/${id}`, searchToUpdate)
            .then((data) => {
                updateInSearches(data);
                if (search?.id === data.id) {
                    setSearch(data);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function stopSearch(id) {
        const search = getRecoil(searchState);
        fetchWrapper
            .post(`searches/${id}/stopprocess`)
            .then((data) => {
                console.log('data', data);
                updateInSearches(data);
                if (search?.id === data.id) {
                    setSearch(data);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function getSearchesOfLead(leadId) {
        setSearchesOfLead(null);
        fetchWrapper
            .get(`searches?lead_id=${leadId}`)
            .then((data) => {
                if (data && data.items.length > 0) {
                    setSearchesOfLead(data.items);
                }
            })
            .catch((e) => {
                fetchNetworkError(null, e);
            });
    }

    function getLeadSearch(leadId, searchId) {
        setLeadSearch(null);
        fetchWrapper
            .get(`searches/${searchId}/leads/${leadId}/leadsearch`)
            .then((data) => {
                setLeadSearch(data);
            })
            .catch((e) => {
                fetchNetworkError(null, e);
            });
    }

    function get(id) {
        if (id === 'all') {
            return null;
        }
        return fetchWrapper
            .get(`searches/${id}`)
            .then((data) => {
                setSearch(data);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function getLead(searchId, id) {
        return fetchWrapper
            .get(`searches/${searchId}/leads/${id}`)
            .then((data) => {
                const leads = getRecoil(leadsState);
                const selectedLead = getRecoil(selectedLeadState);
                const currentItems = leads?.items || [];
                const newLeads = { count: data.count, items: [...currentItems] };
                const index = newLeads.items.findIndex((item) => item.id === id);
                if (index !== -1) {
                    newLeads.items[index] = data;
                    setLeads(newLeads);
                }
                setLeads(newLeads);
                if (selectedLead?.id === data.id) {
                    setSelectedLead(data);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function deleteSearch(searchToDelete) {
        return fetchWrapper
            .delete(`searches/${searchToDelete.id}`)
            .then(() => {
                const searches = getRecoil(searchesState);
                if (searches) {
                    const newSearches = { count: searches.count, items: [...searches.items] };
                    const index = newSearches.items.findIndex((item) => item.id === searchToDelete.id);
                    if (index !== -1) {
                        newSearches.items.splice(index, 1);
                        setSearches(newSearches);
                    }
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function enrich(searchToEnrich) {
        return fetchWrapper
            .post(`searches/${searchToEnrich.id}/enrich`)
            .then((data) => {
                if (data) {
                    const currentSearch = getRecoil(searchState);
                    if (currentSearch && data.id === currentSearch.id) {
                        setSearch(data);
                    }
                    const searches = getRecoil(searchesState);
                    const newSearches = { count: searches.count, items: [...searches.items] };
                    const index = newSearches.items.findIndex((item) => item.id === data.id);
                    if (index !== -1) {
                        newSearches.items[index] = data;
                        setSearches(newSearches);
                    }
                    refreshSearch(searchToEnrich.id);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function getQuery(filters) {
        let query = '';
        if (filters) {
            Object.values(filters).forEach((filtersByKey) => {
                filtersByKey.forEach((filter) => {
                    let val = filter.value ? filter.value.replace(/[^a-zA-Z0-9 éèà]+/g, '') : filter.value;
                    if (filter.operator.indexOf('~') !== -1) {
                        val = `"${val}"`;
                    }
                    query = `${query}${query ? ' and ' : ''}${filter.property}${filter.operator}${val}`;
                });
            });
        }
        return query;
    }

    function createSearchFromLeads(name, selectedSearch, mappings, data) {
        const leads = [];
        const errors = [];
        const linkedinIds = [];
        data.forEach((lead) => {
            const mapped = {};
            Object.keys(mappings).forEach((key) => {
                let value = lead[mappings[key]];
                if (value && key === 'linkedin_public_id') {
                    if (value.indexOf('linkedin.com/in/') > 0) {
                        const publicId = value.slice(value.indexOf('linkedin.com/in/') + 16).replaceAll('/', '');
                        if (publicId) {
                            value = publicId;
                        }
                    }
                }
                mapped[key] = value;
            });
            if (!mapped.firstname || !mapped.lastname || !mapped.linkedin_public_id) {
                errors.push(mapped);
            } else if (linkedinIds.indexOf(mapped.linkedin_public_id) === -1) {
                leads.push(mapped);
                linkedinIds.push(mapped.linkedin_public_id);
            }
        });
        const uploadLeads = (searchId) =>
            fetchWrapper
                .post(`searches/${searchId}/createleads`, leads)
                .then(() => {
                    setStatusImportLeads({ errors, success: leads.length });
                    list(true);
                })
                .catch((e) => {
                    setStatusImportLeads({ errors, success: 0 });
                    fetchNetworkError(null, e);
                });
        if (!name && selectedSearch) {
            return uploadLeads(selectedSearch);
        }
        return fetchWrapper
            .post('searches', {
                name,
                profiles: null,
                list_id: null,
                is_salesnav: false,
                filter: null,
                search_url: '',
                search_api_url: null,
                linkedin_count: data.length,
                is_processing: false,
            })
            .then((search) => uploadLeads(search.id))
            .catch((e) => {
                setStatusImportLeads({ errors, success: 0 });
                fetchNetworkError(null, e);
            });
    }

    function createSearchFromList(name, profiles) {
        setCreateList(true);
        return fetchWrapper
            .post('searches', {
                name,
                profiles,
                list_id: null,
                is_salesnav: false,
                filter: null,
                search_url: '',
                search_api_url: null,
                linkedin_count: profiles.length,
            })
            .then(() => {
                setCreateList(false);
                list(true);
            })
            .catch((e) => {
                setCreateList(false);
                fetchNetworkError(null, e);
            });
    }

    function createSearchFromConnections(name) {
        setCreateList(true);
        return fetchWrapper
            .post('searches?from_connections=true', {
                name,
                list_id: null,
                is_salesnav: false,
                filter: null,
                search_url: '',
                search_api_url: null,
            })
            .then(() => {
                setCreateList(false);
                list(true);
            })
            .catch((e) => {
                setCreateList(false);
                fetchNetworkError(null, e);
            });
    }

    function listLeads(reset, filters, searchLeads, all) {
        if (reset) {
            setLoadingLeads(true);
        }
        const query = getQuery(filters);
        const limit = 50;
        const leads = getRecoil(leadsState);
        let searchId = 'all';
        if (!all) {
            const search = getRecoil(searchState);
            if (!search) {
                return;
            }
            searchId = search.id;
        }
        fetchWrapper
            .get(
                `searches/${searchId}/leads?q=${query}&name_search=${searchLeads || ''}&limit=${limit}&offset=${
                    reset ? 0 : leads?.items?.length || 0
                }`
            )
            .then((data) => {
                const currentItems = reset ? [] : leads?.items || [];
                const newLeads = { count: data.count, items: [...currentItems, ...data.items] };
                setLeads(newLeads);
                setLoadingLeads(false);
            })
            .catch((e) => {
                setLoadingLeads(false);
                fetchNetworkError(null, e);
            });
    }

    function saveList(searchToSaveId, name, filters, targetSearchId, doNavigate) {
        setSaving(true);
        const query = getQuery(filters);
        const selectedLeads = getRecoil(selectedLeadsState);
        const filterSearchLeads = getRecoil(filterSearchLeadsState);
        const ids = selectedLeads.map((lead) => lead.id);
        return fetchWrapper
            .post(
                `searches/${searchToSaveId}/leads?ids=${ids.join(',')}&q=${query}&name_search=${
                    filterSearchLeads || ''
                }&name=${name || ''}${targetSearchId ? `&new_search_id=${targetSearchId}` : ''}`
            )
            .then((data) => {
                setSaving(false);
                if (doNavigate) {
                    navigate(`/searches/${data.id}`);
                } else {
                    setSnackMessage({ text: tr('Leads moved to list') });
                    listLeads(true, filters, filterSearchLeads, searchToSaveId === 'all');
                }
            })
            .catch((e) => {
                setSaving(false);
                fetchNetworkError(null, e);
            });
    }

    function toLnUser(searchToProcess, lead, board, step, labelId) {
        setToLnUser(true);
        const query = `id=${lead.id}`;
        const selectedLeads = getRecoil(selectedLeadsState);
        const ids = selectedLeads.map((item) => item.id);
        const filterSearchLeads = getRecoil(filterSearchLeadsState);
        return fetchWrapper
            .post(
                `searches/${searchToProcess.id}/lnusers?ids=${ids.join(
                    ','
                )}&q=${query}&name_search=${filterSearchLeads}${board ? `&board_id=${board.id}` : ''}${
                    step ? `&step_id=${step.id}` : ''
                }${labelId ? `&label_id=${labelId}` : ''}`
            )
            .then(() => {
                setToLnUser(false);
                getLead(searchToProcess.id, lead.id);
            })
            .catch((e) => {
                setToLnUser(false);
                fetchNetworkError(null, e);
            });
    }

    function toLnUsers(searchId, filters, board, step, labelId, leadId) {
        setToLnUsers(true);
        const query = getQuery(filters);
        const selectedLeads = getRecoil(selectedLeadsState);
        const filterSearchLeads = getRecoil(filterSearchLeadsState);
        const ids = leadId ? [leadId] : selectedLeads.map((lead) => lead.id);
        return fetchWrapper
            .post(
                `searches/${searchId}/lnusers?ids=${ids}&name_search=${filterSearchLeads}&q=${query}${
                    board ? `&board_id=${board.id}` : ''
                }${step ? `&step_id=${step.id}` : ''}${labelId ? `&label_id=${labelId}` : ''}`
            )
            .then(() => {
                setToLnUsers(false);
                setSearchToImport(null);
                setSnackMessage({ text: tr('Leads added!'), type: 'info' });
                list(true);
            })
            .catch((e) => {
                setToLnUsers(false);
                fetchNetworkError(null, e);
                setSnackMessage({ text: tr('Could not add leads!'), type: 'error' });
            });
    }

    function toggleMatch(searchToProcess, lead) {
        return fetchWrapper
            .put(`searches/${searchToProcess.id}/leads/${lead.id}/togglematch`)
            .then((data) => {
                const leads = getRecoil(leadsState);
                const selectedLead = getRecoil(selectedLeadState);
                const currentItems = leads?.items || [];
                const newLeads = { count: data.count, items: [...currentItems] };
                const index = newLeads.items.findIndex((item) => item.id === data.id);
                if (index !== -1) {
                    newLeads.items[index] = data;
                    setLeads(newLeads);
                }
                setLeads(newLeads);
                if (selectedLead?.id === data.id) {
                    setSelectedLead(data);
                }
            })
            .catch((e) => {
                fetchNetworkError(null, e);
            });
    }

    function removeFromList(searchToProcessId, lead, cb) {
        return fetchWrapper
            .delete(`searches/${searchToProcessId}/leads/${lead.id}`)
            .then(() => {
                const leads = getRecoil(leadsState);
                const newLeads = { count: leads.count, items: [...leads.items] };
                const index = newLeads.items.findIndex((item) => item.id === lead.id);
                if (index !== -1) {
                    newLeads.items.splice(index, 1);
                    setLeads(newLeads);
                }
                get(searchToProcessId);
                if (cb) {
                    cb(searchToProcessId, lead);
                }
            })
            .catch((e) => {
                fetchNetworkError(null, e);
            });
    }

    function removeAllFromList(searchToProcessId) {
        const selectedLeads = getRecoil(selectedLeadsState);
        const selectedIds = selectedLeads.map((lead) => lead.id);
        setDeleteLeads(true);
        return fetchWrapper
            .delete(`searches/${searchToProcessId}/leads`, { lead_ids: selectedIds })
            .then(() => {
                setDeleteLeads(false);
                setSelectedLeads([]);
                const leads = getRecoil(leadsState);
                const newLeads = { count: leads.count, items: [] };

                newLeads.items = leads.items.filter((item) => selectedIds.indexOf(item.id) === -1);
                setLeads(newLeads);
                get(searchToProcessId);
            })
            .catch((e) => {
                setDeleteLeads(false);
                fetchNetworkError(null, e);
            });
    }
    function changeList(searchToProcess, lead, newSearchId, move) {
        if (searchToProcess.id === newSearchId) {
            return null;
        }
        return fetchWrapper
            .post(`searches/${searchToProcess.id}/leads/${lead.id}/move/${newSearchId}`)
            .then(() => {
                const searches = getRecoil(searchesState);
                const newSearches = { count: searches.count, items: [...searches.items] };
                const index = newSearches.items.findIndex((item) => item.id === lead.id);
                if (index !== -1) {
                    newSearches.items.splice(index, 1);
                    setSearches(newSearches);
                }
                get(searchToProcess.id);
                setMoveLeadSearch(newSearchId);
                setSnackMessage({ text: tr('Lead moved'), type: 'info' });
                if (move) {
                    navigate(`/searches/${newSearchId}`);
                }
            })
            .catch((e) => {
                fetchNetworkError(null, e);
            });
    }

    function createList(searchToProcess, lead, name, move) {
        return fetchWrapper
            .post(`searches/${searchToProcess.id}/leads/${lead.id}/create?name=${name}`)
            .then((data) => {
                const searches = getRecoil(searchesState);
                const newSearches = { count: searches.count, items: [...searches.items] };
                const index = newSearches.items.findIndex((item) => item.id === lead.id);
                if (index !== -1) {
                    newSearches.items.splice(index, 1);
                    setSearches(newSearches);
                }
                get(searchToProcess.id);
                setMoveLeadSearch(data.id);
                setSnackMessage({ text: tr('Lead moved'), type: 'info' });
                if (move) {
                    navigate(`/searches/${data.id}`);
                }
            })
            .catch((e) => {
                fetchNetworkError(null, e);
            });
    }

    function csv(seatchToExportId, seatchToExportName, filters, size, offset) {
        setCsvExport(true);
        const query = getQuery(filters);
        const selectedLeads = getRecoil(selectedLeadsState);
        const filterSearchLeads = getRecoil(filterSearchLeadsState);
        const ids = selectedLeads.map((lead) => lead.id);
        const name = offset + 10000 < size ? `${offset + 1}-${offset + 10000}` : `${offset + 1}-${size}`;
        return fetchWrapper
            .download(
                `searches/${seatchToExportId}/csv?ids=${ids}&name_search=${filterSearchLeads}&q=${query}&offset=${offset}`,
                `${seatchToExportName}_${name}.csv`
            )
            .then(() => {
                if (offset + 10000 >= size) {
                    setCsvExport(false);
                } else {
                    csv(seatchToExportId, seatchToExportName, filters, size, offset + 10000);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    return {
        createSearchFromConnections,
        createSearchFromList,
        createSearchFromLeads,
        toggleMatch,
        stopSearch,
        updateSearch,
        getSearchesOfLead,
        getLeadSearch,
        changeList,
        createList,
        toLnUsers,
        toLnUser,
        enrich,
        saveList,
        removeFromList,
        removeAllFromList,
        deleteSearch,
        list,
        csv,
        get,
        listLeads,
    };
}
