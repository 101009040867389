import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import DatePicker from 'react-datepicker';
import { tr } from '../../common/locale';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Avatar from '../../components/Avatar';
import Popover from '../../components/Popover';
import Dropdown from '../../components/Dropdown';
import Icon from '../../components/Icon';
import MoveBoard from './MoveBoard';
import useUserActions from '../../_actions/user.actions';
import useLnUsersActions from '../../_actions/lnusers.actions';
import Labels from '../lnuser/Labels';
import gif from '../../images/tips/multiselect.gif';
import PopoverNote from './PopoverNote';
import { lnUserState, loadingRemindState } from '../../_states/lnuser.states';
import { userState } from '../../_states/user.states';
import Tooltip from '../../components/Tooltip';
import messageUtils from '../../common/messageUtils';
import utils from '../../common/utils';

function userRelation(connection) {
    if (connection.is_connection && !connection.is_sponsor) {
        if (connection.is_lead) {
            return tr('Connection');
        }
        return tr('Connection');
    }
    if (connection.is_connection && connection.is_sponsor) {
        return tr('Sponsor Connection');
    }
    if (!connection.is_connection && connection.is_sponsor) {
        return tr('Sponsor');
    }
    if (connection.is_lead) {
        return tr('Contact');
    }
    return tr('Contact');
}
function ConnectionLine({ connection, index, selectUserCb, isSelected }) {
    const userActions = useUserActions();
    const lnUsersActions = useLnUsersActions();
    const popoverRef = useRef();
    const dropdownMenuRef = useRef();
    const user = useRecoilValue(userState);
    const loadingRemind = useRecoilValue(loadingRemindState);
    const [remind, setRemind] = useState({ type: null, date: new Date() });

    const setLnUser = useSetRecoilState(lnUserState);
    useEffect(() => {
        if (remind.type === 'remove') {
            lnUsersActions.updateRemind(connection, null);
        } else if (remind.type) {
            let { date } = remind;
            if (remind.type === '1day') {
                date = moment().add(1, 'days').startOf('day');
            } else if (remind.type === '3days') {
                date = moment().add(3, 'days').startOf('day');
            } else if (remind.type === '1week') {
                date = moment().add(7, 'days').startOf('day');
            } else if (remind.type === '1month') {
                date = moment().add(1, 'months').startOf('day');
            }
            lnUsersActions.updateRemind(connection, date);
        }
    }, [remind]);
    const renderCheckbox = () => {
        const hasPopover = index === 0 && (!user?.tours || user.tours?.indexOf('multiselect') === -1);
        const div = (
            <div
                className="list__col list__col--checkbox"
                onClick={(e) => selectUserCb(e, connection)}
                onMouseDown={(e) => e.preventDefault()}
                onMouseEnter={
                    hasPopover
                        ? () => {
                              if (popoverRef.current) {
                                  popoverRef.current.open();
                              }
                          }
                        : null
                }
                onKeyDown={() => null}
                role="button"
                tabIndex={0}
                title={index === 0 ? '' : tr('Use Shift key to select multiple members at once')}
            >
                <Input
                    type="checkbox"
                    stopPropagation
                    checked={isSelected}
                    onChange={(e) => selectUserCb(e, connection)}
                />
            </div>
        );
        if (hasPopover) {
            return (
                <Popover
                    contentClassName="tips"
                    ref={popoverRef}
                    placement="right"
                    content={
                        <>
                            <div className="tips__header">
                                <span>{tr('Use Shift key to select multiple members at once')}</span>
                                <Button
                                    type="link"
                                    icon="close"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        popoverRef.current.close();
                                        userActions.updateTour('multiselect');
                                    }}
                                />
                            </div>
                            <img className="br" src={gif} alt="Multiselect" />
                        </>
                    }
                >
                    {div}
                </Popover>
            );
        }
        return div;
    };
    return (
        <div
            className={`list__item ${messageUtils.userListTypeClass(connection)} ${
                connection.is_read ? '' : 'unread'
            } ${connection.remind_at && moment(connection.remind_at).diff(moment(), 'days') <= 0 ? 'remind' : ''}`}
            key={connection.linkedin_id}
        >
            <div
                className="list__row"
                tabIndex={index}
                role="button"
                onClick={() => {
                    setLnUser(connection);
                }}
                onKeyDown={() => null}
            >
                <div className="list__cols">
                    {renderCheckbox()}
                    <div className="list__col list__col--fill list__col--info">
                        <div className="connections__list__user">
                            <Tooltip
                                className="relation-status"
                                text={`${userRelation(connection)} ${
                                    connection.is_starred ? ' - Starred conversation' : ''
                                }`}
                            >
                                <Avatar
                                    className="connections__list__user__avatar"
                                    src={connection.lead.picture}
                                    alt={`${
                                        // eslint-disable-next-line no-nested-ternary
                                        connection.is_connection
                                            ? tr('Connection')
                                            : connection.is_sponsor
                                            ? tr('Sponsored contact')
                                            : tr('Contact')
                                    } - ${connection.firstname} ${connection.lastname}`}
                                />
                                {connection.is_starred && (
                                    <Icon
                                        size={16}
                                        className="starred-conversation"
                                        title="Starred conversation"
                                        icon="star-filled"
                                        fill
                                        yellow
                                    />
                                )}
                            </Tooltip>
                            <div className="connections__list__user__item">
                                <div className="connections__list__user__info">
                                    <span className="connections__list__user__name">
                                        <span className="name">
                                            {!connection.is_groupchat &&
                                            ((connection.is_sponsor &&
                                                connection.lead.linkedin_public_id === 'UNKNOWN') ||
                                                connection.lead.linkedin_public_id === connection.lead.linkedin_id)
                                                ? tr('(No profile) - ')
                                                : ''}
                                            {`${connection.lead.firstname} ${connection.lead.lastname}`}
                                        </span>
                                        {connection.invitation_id && (
                                            <Icon
                                                className="invited"
                                                title={
                                                    connection.invitation_type === 'PENDING'
                                                        ? tr('Connection request received')
                                                        : tr('Connection request sent')
                                                }
                                                size={20}
                                                stroke={1.4}
                                                blue
                                                icon={
                                                    connection.invitation_type === 'PENDING'
                                                        ? 'user-arrow-left'
                                                        : 'user-arrow-right'
                                                }
                                            />
                                        )}
                                        {connection.lead.headline ? (
                                            <span className="headline">&nbsp;- {connection.lead.headline}</span>
                                        ) : null}
                                    </span>
                                </div>
                                <div className="connections__list__user__message">
                                    {connection.is_sponsor ? (
                                        <div className="sponsored-message">
                                            <span className="sponsored">{tr('Sponsored')}</span>
                                            {!messageUtils.lastMessageText(connection) && (
                                                <span className="ellipsis">{tr('Advertisement message')}</span>
                                            )}
                                            {messageUtils.lastMessageText(connection) && (
                                                <span className="ellipsis">
                                                    {messageUtils.lastMessageText(connection)}
                                                </span>
                                            )}
                                        </div>
                                    ) : (
                                        <span className="ellipsis">
                                            {messageUtils.lastMessageText(connection) &&
                                                messageUtils.lastMessageIsUser(connection) &&
                                                tr('You: ')}
                                            {messageUtils.lastMessageText(connection)}
                                        </span>
                                    )}
                                    <span className="connections__list__user__date ellipsis">
                                        {connection.last_message?.last_message_at &&
                                            moment(connection.last_message?.last_message_at).fromNow()}
                                        {connection.last_message?.last_message_at &&
                                            (connection.connected_at || connection.invited_at) &&
                                            ' - '}
                                        {connection.connected_at && (
                                            <>{`${tr('Connection since')} ${moment(connection.connected_at).format(
                                                'LL'
                                            )}`}</>
                                        )}
                                        {connection.invited_at && !connection.connected_at && (
                                            <>{`${tr('Invited on')} ${moment(connection.invited_at).format('LL')}`}</>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        role="button"
                        tabIndex={0}
                        className="list__col list__col--action list__col--label"
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={() => null}
                    >
                        <Labels
                            isIcon
                            isClickable
                            isNoLabel
                            isNoBg
                            isLabelTooltip
                            context={connection.labels.length === 0 ? 'list-nolabel' : 'list'}
                            lnUser={connection}
                            updateLnUser={false}
                        />
                    </div>
                    <div className="list__col list__col--action list__col--board">
                        <div className="list__btn-dropdown">
                            <MoveBoard lnUser={connection} />
                        </div>
                    </div>
                    <div className="list__col list__col--mini list__col--center">
                        {connection.notes_count > 0 && <PopoverNote lnUser={connection} />}
                    </div>
                    <div className="list__col list__col--action date-picker">
                        <Dropdown
                            btnFaceIcon="clock"
                            btnFace="link"
                            btnClassName={connection?.remind_at ? 'bold' : 'btn--reminder'}
                            btnSize={connection?.remind_at ? 'nano' : 'mini'}
                            label={connection?.remind_at ? moment(connection?.remind_at).format('LL') : ''}
                            btnIsLoading={loadingRemind === connection?.id}
                            ref={dropdownMenuRef}
                            onClick={(e) => e.stopPropagation()}
                            onDoubleClick={(e) => e.stopPropagation()}
                        >
                            <Dropdown.Menu>
                                <Dropdown.ButtonItem
                                    label={tr('In 1 day')}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setRemind({ type: '1day', date: new Date() });
                                    }}
                                />
                                <Dropdown.ButtonItem
                                    label={tr('In 3 days')}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setRemind({ type: '3days', date: new Date() });
                                    }}
                                />
                                <Dropdown.ButtonItem
                                    label={tr('In 1 week')}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setRemind({ type: '1week', date: new Date() });
                                    }}
                                />
                                <Dropdown.ButtonItem
                                    label={tr('In 1 month')}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setRemind({ type: '1month', date: new Date() });
                                    }}
                                />
                                <li className="dropdown__menu__item">
                                    <DatePicker
                                        selected={remind.date}
                                        minDate={new Date()}
                                        onChange={(date) => {
                                            setRemind({ type: 'custom', date: moment(date).startOf('day').toDate() });
                                            dropdownMenuRef?.current?.handleClickOutside();
                                        }}
                                        customInput={<Dropdown.ButtonItem label={tr('Pick a date')} />}
                                    />
                                </li>
                                {connection?.remind_at && (
                                    <Dropdown.ButtonItem
                                        label={tr('Remove reminder')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setRemind({ type: 'remove', date: new Date() });
                                        }}
                                    />
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    );
}

function areEqual(prevProps, nextProps) {
    if (prevProps.isSelected !== nextProps.isSelected) {
        return false;
    }
    if (prevProps.connection.is_read !== nextProps.connection.is_read) {
        return false;
    }
    if (prevProps.index !== nextProps.index) {
        return false;
    }

    return utils.deepEqual(prevProps.connection, nextProps.connection);
}
export default React.memo(ConnectionLine, areEqual);
