import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { tr } from '../../common/locale';
import { errorAppsumoState, successAppsumoState } from '../../_states/user.states';
import useUserActions from '../../_actions/user.actions';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Flash from '../../components/Flash';

function Appsumo() {
    const actions = useUserActions();
    const successRef = useRef();
    const navigate = useNavigate();
    const errorAppsumo = useRecoilValue(errorAppsumoState);
    const successAppsumo = useRecoilValue(successAppsumoState);
    const [code, setCode] = useState('');
    useEffect(() => {
        if (successAppsumo) {
            successRef.current.open();
        }
    }, [successAppsumo]);

    return (
        <div className="content subscription subscription--program">
            <Modal
                className="welcome-message"
                labelValidate={tr('Close')}
                ref={successRef}
                closeCb={() => navigate('/subscription')}
                title={tr('Your subscription is updated!')}
            >
                <p className="welcome-message__main">{tr('Thanks for your subscription')}</p>
            </Modal>
            <div className="content__body">
                <div className="subscription__content appsumo">
                    <h1 className="title">{tr('Hey!')}</h1>
                    <p>{tr('Paste your code to redeem in the field below:')}</p>
                    <Input value={code} placeholder={tr('Your code')} onChange={(e) => setCode(e.target.value)} />
                    {errorAppsumo && <Flash color="red">{tr('The code is invalid')}</Flash>}
                    <Button label={tr('Apply code')} onClick={() => actions.redeem(code)} />
                </div>
            </div>
        </div>
    );
}
export default Appsumo;
