import { atom } from 'recoil';
import { syncEffect } from 'recoil-sync';
import { object, string, number, nullable } from '@recoiljs/refine';

const objAtomRefine = nullable(
    object({
        id: number(),
        name: string(),
    })
);

export const loadingState = atom({
    key: 'loadingSearchesState',
    default: true,
});

export const statusImportLeadsState = atom({
    key: 'statusImportLeadsState',
    default: null,
});

export const filterArchiveState = atom({
    key: 'filterArchiveState',
    default: false,
});

export const moveLeadSearchState = atom({
    key: 'moveLeadSearchState',
    default: null,
});

export const filterSearchState = atom({
    key: 'filterSearchState',
    default: '',
});

export const filterSearchLeadsState = atom({
    key: 'filterSearchLeadsState',
    default: '',
});

export const savingState = atom({
    key: 'savingState',
    default: false,
});

export const searchesOfLeadState = atom({
    key: 'searchesOfLeadState',
    default: null,
});

export const leadSearchState = atom({
    key: 'leadSearchState',
    default: null,
});

export const toLnUsersState = atom({
    key: 'toLnUsersState',
    default: false,
});

export const toLnUserState = atom({
    key: 'toLnUserState',
    default: false,
});

export const selectedLeadState = atom({
    key: 'selectedLeadState',
    default: null,
});

export const searchesState = atom({
    key: 'searchesState',
    default: null,
});

export const searchToImportState = atom({
    key: 'searchToImportState',
    default: null,
});

export const searchState = atom({
    key: 'searchState',
    default: null,
    effects: [syncEffect({ refine: objAtomRefine })],
});

export const loadingLeadsState = atom({
    key: 'loadingLeadsState',
    default: true,
});

export const createListState = atom({
    key: 'createListState',
    default: false,
});

export const filterOwnerState = atom({
    key: 'filterOwnerState',
    default: null,
});

export const eventUrlState = atom({
    key: 'eventUrlState',
    default: null,
});

export const scrapeEventStatusState = atom({
    key: 'scrapeEventStatusState',
    default: null,
});

export const salesnavUrlState = atom({
    key: 'salesnavUrlState',
    default: null,
});

export const scrapeSalesnavStatusState = atom({
    key: 'scrapeSalesnavStatusState',
    default: null,
});

export const searchUrlState = atom({
    key: 'searchUrlState',
    default: null,
});

export const scrapeLinkedinSearchStatusState = atom({
    key: 'scrapeLinkedinSearchStatusState',
    default: null,
});

export const postUrlState = atom({
    key: 'postUrlState',
    default: null,
});

export const scrapCommentsState = atom({
    key: 'scrapCommentsState',
    default: true,
});

export const scrapLikesState = atom({
    key: 'scrapLikesState',
    default: true,
});

export const scrapePostStatusState = atom({
    key: 'scrapePostStatusState',
    default: null,
});

export const leadsState = atom({
    key: 'leadsState',
    default: null,
});

export const selectedLeadsState = atom({
    key: 'selectedLeadsState',
    default: [],
});

export const lastSelectState = atom({
    key: 'lastSelectLeadState',
    default: -1,
});

export const csvLeadExportState = atom({
    key: 'csvLeadExportState',
    default: false,
});

export const scrapSearchState = atom({
    key: 'scrapSearchState',
    default: null,
});

export const deleteLeadsState = atom({
    key: 'deleteLeadsState',
    default: false,
});
