import { useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import messageUtils from '../common/messageUtils';
import useFetchWrapper from '../_helpers/fetchWrapper.helpers';
import { tr } from '../common/locale';
import {
    boardsState,
    boardState,
    filterNameState,
    selectedBoardIdState,
    initBoardLanesState,
    filterLabelsState,
    boardsLoadingState,
    noteBoardState,
    boardLaneAllUsersState,
    boardLaneAllUsersLoadingState,
    csvExportBoardState,
} from '../_states/lnusers.states';
import useError from '../_helpers/errorsWrapper.helpers';

export default function useLeadActions() {
    const fetchWrapper = useFetchWrapper();
    const setBoard = useSetRecoilState(boardState);
    const setSelectedBoardId = useSetRecoilState(selectedBoardIdState);
    const setBoards = useSetRecoilState(boardsState);
    const setInitBoardLanes = useSetRecoilState(initBoardLanesState);
    const setCsvExport = useSetRecoilState(csvExportBoardState);
    const setLoading = useSetRecoilState(boardsLoadingState);
    const setBoardLaneAllUsers = useSetRecoilState(boardLaneAllUsersState);
    const setBoardLaneAllUsersLoading = useSetRecoilState(boardLaneAllUsersLoadingState);
    const setNoteBoard = useSetRecoilState(noteBoardState);

    const { fetchNetworkError } = useError();

    function getBoards() {
        setLoading(true);
        return fetchWrapper
            .get('boards')
            .then((data) => {
                setBoards(data);
                const board = getRecoil(boardState);
                if (data.length === 0 && board) {
                    setBoard(null);
                }
                if (data.length === 0) {
                    setLoading(false);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function createBoard(name) {
        return fetchWrapper
            .post('boards', { name })
            .then((data) => {
                setSelectedBoardId(data.id);
                getBoards();
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function updateBoard(name) {
        const board = getRecoil(boardState);
        return fetchWrapper
            .put(`boards/${board.id}`, { name })
            .then(() => {
                const newBoard = { ...board };
                newBoard.name = name;
                setBoard(newBoard);
                getBoards();
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function getBoard(id) {
        const board = getRecoil(boardState);
        const filterName = getRecoil(filterNameState);
        const labelsFilter = getRecoil(filterLabelsState);
        const currentId = id || board?.id;
        if (currentId) {
            return fetchWrapper
                .get(`boards/${currentId}?no_card=true&q=${filterName}&labels=${labelsFilter.join(',')}`)
                .then((data) => {
                    setBoard(data);
                    setLoading(false);
                })
                .catch((e) => fetchNetworkError(null, e));
        }
        return null;
    }

    function listNotesBoard(userId) {
        return fetchWrapper.get(`notes/lnusers/${userId}`).then((data) => {
            setNoteBoard(data);
        });
    }

    function deleteBoard(id) {
        return fetchWrapper
            .delete(`boards/${id}`)
            .then(() => {
                getBoards();
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function mainBoard(id) {
        return fetchWrapper
            .post(`boards/${id}/main`)
            .then(() => {
                const board = getRecoil(boardState);
                const newBoard = { ...board };
                newBoard.is_main = true;
                setInitBoardLanes(false);
                setBoard(newBoard);
                getBoards();
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function updateLane(boardId, laneId, lane) {
        setInitBoardLanes(false);
        return fetchWrapper
            .put(`boards/${boardId}/${laneId}`, lane)
            .then((data) => {
                setBoard(data);
                getBoards();
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function updateLanePosition(boardId, laneId, removedIndex, addedIndex) {
        setInitBoardLanes(false);
        return fetchWrapper
            .put(`boards/${boardId}/${laneId}/position?removed_index=${removedIndex}&added_index=${addedIndex}`)
            .then((data) => {
                setBoard(data);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function moveCard(boardId, fromLaneId, toLaneId, cardId, index) {
        return fetchWrapper
            .put(
                `boards/${boardId}/movecard?from_lane_id=${fromLaneId}&to_lane_id=${toLaneId}&card_id=${cardId}&index=${index}`
            )
            .then((data) => {
                const board = getRecoil(boardState);
                const newBoard = { ...board };
                newBoard.lanes = [...board.lanes];
                const fromLane = board.lanes.find((ln) => ln.id === fromLaneId);
                const newFromLane = data.lanes.find((ln) => ln.id === fromLaneId);

                const toLane = board.lanes.find((ln) => ln.id === toLaneId);
                const newToLane = data.lanes.find((ln) => ln.id === toLaneId);
                if (toLane && newToLane && fromLane && newFromLane) {
                    newToLane.cards = [...toLane.cards];
                    newFromLane.cards = [...fromLane.cards];
                    newBoard.lanes[newBoard.lanes.indexOf(fromLane)] = newFromLane;
                    newBoard.lanes[newBoard.lanes.indexOf(toLane)] = newToLane;
                    const fromCardIndex = newFromLane.cards.findIndex((item) => item.id === cardId);
                    let fromCard = null;
                    if (fromCardIndex !== -1) {
                        fromCard = newFromLane.cards[fromCardIndex];
                        newFromLane.cards.splice(fromCardIndex, 1);
                    }
                    const toCardIndex = newToLane.cards.findIndex((item) => item.id === cardId);
                    if (toCardIndex === -1 && fromCard) {
                        newToLane.cards.splice(index, 0, fromCard);
                    }
                }
                setBoard(newBoard);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function addLane(boardId, lane) {
        return fetchWrapper
            .post(`boards/${boardId}/addlane`, lane)
            .then((data) => {
                setInitBoardLanes(false);
                setBoard(data);
                getBoards();
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function deleteLane(boardId, laneId) {
        return fetchWrapper
            .delete(`boards/${boardId}/${laneId}`)
            .then((data) => {
                setInitBoardLanes(false);
                setBoard(data);
                getBoards();
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function createSalesBoard(name) {
        return fetchWrapper
            .post('boards', {
                name,
                lanes: [
                    { title: tr('Leads') },
                    { title: tr('Contact made') },
                    { title: tr('Needs defined') },
                    { title: tr('Proposal made') },
                    { title: tr('Negociations started') },
                    { title: tr('Deal closed') },
                ],
            })
            .then((data) => {
                setSelectedBoardId(data.id);
                getBoards();
            })
            .catch((e) => fetchNetworkError(null, e));
    }
    function createRecruiterBoard(name) {
        return fetchWrapper
            .post('boards', {
                name,
                lanes: [
                    { title: tr('Candidates') },
                    { title: tr('Shortlisted') },
                    { title: tr('Contacted') },
                    { title: tr('Interview scheduled') },
                    { title: tr('Rejected') },
                    { title: tr('Final interview') },
                    { title: tr('Offer sent') },
                ],
            })
            .then((data) => {
                setSelectedBoardId(data.id);
                getBoards();
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function duplicateLanes(boardId, lanes) {
        const lane = lanes.shift();
        if (lane) {
            fetchWrapper
                .post(`boards/${boardId}/addlane`, lane)
                .then((data) => {
                    if (lanes.length > 0) {
                        duplicateLanes(boardId, lanes);
                    } else {
                        setInitBoardLanes(false);
                        setBoard(data);
                        getBoards();
                    }
                })
                .catch((e) => fetchNetworkError(null, e));
        }
    }

    function duplicateBoard() {
        const board = getRecoil(boardState);
        const currentBoard = board;
        return fetchWrapper
            .post('boards?nolane=true', { name: `${board.name} (${tr('Copy')})` })
            .then((data) => {
                setSelectedBoardId(data.id);
                const lanesToAdd = currentBoard.lanes.map((lane) => lane);
                duplicateLanes(data.id, lanesToAdd);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    async function getUsersAll(boardId, laneId, offset) {
        if (!offset) {
            setBoardLaneAllUsers([]);
            setBoardLaneAllUsersLoading(true);
        }
        fetchWrapper
            .get(`boards/${boardId}/${laneId}/users/all?offset=${offset || 0}`)
            .then((data) => {
                const allUsers = getRecoil(boardLaneAllUsersState);
                const newAllUsers = [...allUsers, ...data.items];
                setBoardLaneAllUsers(newAllUsers);
                if (newAllUsers.length < data.count) {
                    getUsersAll(boardId, laneId, newAllUsers.length);
                } else {
                    setBoardLaneAllUsersLoading(false);
                }
            })
            .catch((e) => {
                fetchNetworkError(null, e);
                setBoardLaneAllUsersLoading(false);
            });
    }

    async function getUsers(boardId, laneId, page, withCount) {
        const filterName = getRecoil(filterNameState);
        const labelsFilter = getRecoil(filterLabelsState);
        const pageParam = page ? `&page=${page}` : '';
        return fetchWrapper
            .get(
                `boards/${boardId}/${laneId}/users${
                    withCount ? 'count' : ''
                }?q=${filterName}&labels=${labelsFilter.join(',')}${pageParam}`
            )
            .catch((e) => fetchNetworkError(null, e));
    }

    function lnUserToCard(lnuser) {
        return {
            id: lnuser.id,
            name: `${lnuser.lead.firstname} ${lnuser.lead.lastname}`,
            headline: lnuser.lead.headline || '',
            is_connection: lnuser.is_connection,
            last_message: messageUtils.lastMessageText(lnuser),
            company: lnuser.lead.company || '',
            picture: lnuser.lead.picture,
            notes_count: lnuser.notes_count,
            labels: lnuser.labels,
            editable: false,
        };
    }

    function getLaneData(boardId, laneId, page) {
        return getUsers(boardId, laneId, page).then((users) => users.map((lnuser) => lnUserToCard(lnuser)));
    }

    function getBoardData() {
        let board = getRecoil(boardState);
        setInitBoardLanes(true);
        board?.lanes?.forEach((current) =>
            getUsers(board.id, current.id, 1, true).then((data) => {
                board = getRecoil(boardState);
                const newBoard = { ...board };
                if (board) {
                    newBoard.lanes = [...board.lanes];
                    const currentLn = board.lanes.find((ln) => ln.id === current.id);
                    const newLane = { ...currentLn };
                    newBoard.lanes[board.lanes.indexOf(currentLn)] = newLane;
                    newLane.cards = data.cards ? data.cards.map((lnuser) => lnUserToCard(lnuser)) : [];
                    newLane.count = data.count;
                    setBoard(newBoard);
                }
            })
        );
    }

    async function csv(boardId, laneId) {
        setCsvExport(true);
        return fetchWrapper
            .download(`boards/${boardId}/${laneId}/users/csv`, 'user_export.csv')
            .then(() => {
                setCsvExport(false);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    return {
        csv,
        createSalesBoard,
        createRecruiterBoard,
        getUsersAll,
        getUsers,
        duplicateBoard,
        mainBoard,
        updateBoard,
        listNotesBoard,
        deleteBoard,
        createBoard,
        getBoards,
        moveCard,
        getBoardData,
        lnUserToCard,
        getLaneData,
        updateLane,
        updateLanePosition,
        addLane,
        deleteLane,
        getBoard,
    };
}
