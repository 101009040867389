import { useState, useEffect, useRef } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import Dropdown from '../../components/Dropdown';
import { tr } from '../../common/locale';
import Input from '../../components/Input';
import Attachments from '../../components/Attachments';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import Flash from '../../components/Flash';
import useLinkdInActions from '../../_actions/linkedin.actions';
import useTemplatesActions from '../../_actions/template.actions';
import {
    templatesState,
    templateState,
    savingTemplateState,
    templateFilesAlternateState,
    templateFilesState,
} from '../../_states/template.states';
import { userState } from '../../_states/user.states';
import { boardLaneAllUsersState, boardLaneAllUsersLoadingState } from '../../_states/lnusers.states';
import messageUtils from '../../common/messageUtils';
import useBoardAction from '../../_actions/board.actions';
import utils from '../../common/utils';

function BulkSendMessageAction({ boardId, laneId, selectedUsers, closeCb, actionCb }) {
    const contentRef = useRef();
    const modalRef = useRef();
    const contentAlternativeRef = useRef();
    const user = useRecoilValue(userState);
    const boardActions = useBoardAction();
    const templateActions = useTemplatesActions();
    const [usersToProcess, setUsersToProcess] = useState(selectedUsers);
    const linkedInActions = useLinkdInActions();
    const [bulkMessage, setBulkMessage] = useState('');
    const [templateFiles, setTemplateFiles] = useRecoilState(templateFilesState);
    const [templateFilesAlternate, setTemplateFilesAlternate] = useRecoilState(templateFilesAlternateState);

    const boardLaneAllUsers = useRecoilValue(boardLaneAllUsersState);
    const boardLaneAllUsersLoading = useRecoilValue(boardLaneAllUsersLoadingState);

    const [title, setTitle] = useState('');
    const [bulkAlternativeMessage, setBulkAlternativeMessage] = useState('');
    const [messageTemplate, setMessageTemplate] = useState(false);
    const [template, setTemplate] = useRecoilState(templateState);
    const savingTemplate = useRecoilValue(savingTemplateState);
    const templates = useRecoilValue(templatesState);
    const disableBulkMessage = selectedUsers?.find((current) => !messageUtils.canSendMessage(user, current));
    const bulkSend = () => {
        if (actionCb) {
            actionCb();
        }
        linkedInActions.bulkSendMessage(
            bulkMessage,
            bulkAlternativeMessage,
            templateFiles,
            templateFilesAlternate,
            messageTemplate,
            usersToProcess
        );
        modalRef.current.close();
    };
    const reset = () => {
        setTitle(template?.title || '');
        setBulkMessage(template?.content || '');
        setMessageTemplate(template?.title || null);
        setBulkAlternativeMessage(template?.alternative || '');
    };
    useEffect(() => {
        reset();
    }, [template]);
    useEffect(
        () => () => {
            setTemplate(null);
        },
        []
    );
    useEffect(() => {
        if (!boardLaneAllUsersLoading && boardLaneAllUsers.length > 0) {
            setUsersToProcess(boardLaneAllUsers);
        }
    }, [boardLaneAllUsersLoading, boardLaneAllUsers]);
    useEffect(() => {
        if (boardId && laneId && !usersToProcess) {
            boardActions.getUsersAll(boardId, laneId);
        }
    }, []);
    const messagesTemplates = templates?.filter((item) => item.type === 'message');
    const header = (
        <>
            <h1>{tr('Send a message')}</h1>
            {messagesTemplates?.length > 0 && (
                <Dropdown
                    btnFaceIcon="message-add"
                    btnFace="secondary"
                    isBordered
                    disabled={boardLaneAllUsersLoading}
                    label={tr('Use a template message')}
                    onClick={(e) => e.stopPropagation()}
                    onDoubleClick={(e) => e.stopPropagation()}
                >
                    <Dropdown.Menu direction="s">
                        {messagesTemplates?.map((element) => (
                            <Dropdown.ButtonItem
                                isSubscriptionPopover
                                key={element?.id}
                                btnLabel={element.title}
                                onClick={() => {
                                    setTemplate(element);
                                }}
                            />
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
    const messageContainJobCompanyVars = utils.hasVariables(bulkMessage);
    return (
        <Modal
            isFull={messageContainJobCompanyVars}
            defaultIsOpen
            ref={modalRef}
            closeCb={closeCb}
            overflow
            header={header}
            className="bulk-actions-modal__message"
            title={tr('Send a message')}
            validateCb={bulkSend}
            labelValidate={tr('Send')}
        >
            {usersToProcess && (
                <div className="bulk-actions-modal__message__body">
                    <Flash color="blue" icon={boardLaneAllUsersLoading ? 'load' : null} className="mb--20">
                        <p>
                            {tr('{{count}} members selected', {
                                count: selectedUsers ? selectedUsers.length : boardLaneAllUsers.length,
                            })}
                        </p>
                    </Flash>
                    {disableBulkMessage && (
                        <div className="bulk-actions-modal__message__body__alert">
                            <Flash fill color="orange" className={!messageContainJobCompanyVars && 'mb--15'}>
                                <div className="mb--10">
                                    {tr('Contacts which are not in your network or Open Profiles will be ignored:')}
                                </div>
                                <ul>
                                    {selectedUsers
                                        ?.filter((current) => !messageUtils.canSendMessage(user, current))
                                        .map((current) => (
                                            <li key={current.id}>
                                                <strong>{`${current.lead.firstname} ${current.lead.lastname}`}</strong>
                                            </li>
                                        ))}
                                </ul>
                            </Flash>
                        </div>
                    )}
                    <div
                        className={`bulk-actions-modal__message__sections${
                            messageContainJobCompanyVars ? ' split' : ''
                        }`}
                    >
                        <div className="bulk-actions-modal__message__section">
                            {messageContainJobCompanyVars && <h2>{tr('Message')}</h2>}
                            {utils.variableButtons(contentRef, true, bulkMessage, setBulkMessage)}
                            <Input
                                type="textarea"
                                value={bulkMessage}
                                ref={contentRef}
                                onChange={(e) => {
                                    if (!e.target.value) {
                                        setMessageTemplate(null);
                                    }
                                    setBulkMessage(e.target.value);
                                }}
                            />
                            <Attachments
                                inputRef={contentRef}
                                message={bulkMessage}
                                setMessage={setBulkMessage}
                                files={templateFiles}
                                setFiles={setTemplateFiles}
                            />
                        </div>

                        {messageContainJobCompanyVars && (
                            <div className="bulk-actions-modal__message__section">
                                <h2>{tr('Alternative message')}</h2>
                                <p>
                                    {tr(
                                        'The alternative message allows you to send a coherent message if one of the variables - "Company Name", "Job Title", "Icebreaker", "Industry" or "Location" - are not filled in on the user\'s profile.'
                                    )}
                                </p>
                                {utils.variableButtons(
                                    contentAlternativeRef,
                                    false,
                                    bulkAlternativeMessage,
                                    setBulkAlternativeMessage
                                )}
                                <Input
                                    type="textarea"
                                    value={bulkAlternativeMessage}
                                    ref={contentAlternativeRef}
                                    onChange={(e) => {
                                        if (!e.target.value) {
                                            setMessageTemplate(null);
                                        }
                                        setBulkAlternativeMessage(e.target.value);
                                    }}
                                />
                                <Attachments
                                    inputRef={contentAlternativeRef}
                                    message={bulkAlternativeMessage}
                                    setMessage={setBulkAlternativeMessage}
                                    files={templateFilesAlternate}
                                    setFiles={setTemplateFilesAlternate}
                                />
                            </div>
                        )}
                    </div>
                    <div className="bulk-actions-modal__message__save">
                        <h3>{tr('Save as Template Message (Optional)')}</h3>
                        <div className="flex mbi--small">
                            <Input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder={tr('Give a name to this message')}
                            />
                            <Button
                                isLoading={savingTemplate}
                                disabled={
                                    (!title && !bulkMessage) ||
                                    (template?.title === title &&
                                        template?.content === bulkMessage &&
                                        template?.alternative === bulkAlternativeMessage)
                                }
                                label={tr('Save')}
                                onClick={() => {
                                    if (template) {
                                        templateActions.update(
                                            template.id,
                                            title,
                                            bulkMessage,
                                            bulkAlternativeMessage,
                                            'message'
                                        );
                                    } else {
                                        templateActions.add(title, bulkMessage, bulkAlternativeMessage, 'message');
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
}
export default BulkSendMessageAction;
