import { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import TooltipPopover from '../../components/TooltipPopover';
import Avatar from '../../components/Avatar';
import Dropdown from '../../components/Dropdown';
import Icon from '../../components/Icon';
import useCampaignActions from '../../_actions/campaign.actions';
import { tr } from '../../common/locale';
import { tasksState, loadingTasksState, campaignState, filterProcessedState } from '../../_states/campaign.states';

function CampaignTask() {
    const actions = useCampaignActions();
    const loadingSchedule = useRecoilValue(loadingTasksState);
    const campaign = useRecoilValue(campaignState);
    const [filterProcessed, setFilterProcessed] = useRecoilState(filterProcessedState);
    const tasks = useRecoilValue(tasksState);
    useEffect(() => {
        if (campaign.id) {
            actions.getTasks(true);
        }
    }, [filterProcessed]);
    const fakeLoader = [];
    for (let i = 0; i <= 20; i++) {
        fakeLoader.push(
            <div key={i} className="list__fake list__fake--team">
                <div className="list__col list__col--spacer" />
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>

                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
            </div>
        );
    }
    const hasMore = tasks && tasks.count > tasks.items.length;
    return (
        <div className="content__body campaign-logs">
            <div className="campaign__header">
                <div className="campaign__header__title">
                    <h2 className="title">{tr('Campaign actions')}</h2>

                    <Dropdown
                        iconRight
                        label={filterProcessed ? tr('Processed tasks') : tr('Task scheduled for today')}
                        onClick={(e) => e.stopPropagation()}
                        onDoubleClick={(e) => e.stopPropagation()}
                    >
                        <Dropdown.Menu direction="se">
                            <Dropdown.ButtonItem
                                btnLabel={tr('Processed tasks')}
                                onClick={() => setFilterProcessed(true)}
                            />
                            <Dropdown.ButtonItem
                                btnLabel={tr('Task scheduled for today')}
                                onClick={() => setFilterProcessed(false)}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <p className="campaign__sub-header">
                    {tr(
                        'Actions are scheduled for the current day. They can be re-scheduled when some events occur (like the creation of another campaign)'
                    )}
                </p>
            </div>
            <div className="list__header">
                <div className="list__cols">
                    <div className="list__col list__col--spacer" />
                    <div className="list__col list__col--fill">{tr('User')}</div>
                    <div className="list__col list__col--large">{tr('Scheduled date')}</div>
                    <div className="list__col list__col--large">{tr('Action')}</div>
                    <div className="list__col">{tr('Status')}</div>
                </div>
            </div>
            <div
                id="scheduleContent"
                className={`connections--list list--pattern ${
                    loadingSchedule || (tasks && tasks.items.length > 0) ? '' : 'list--empty'
                }`}
            >
                {loadingSchedule && (!tasks || tasks?.items.length === 0) && fakeLoader}
                {!loadingSchedule && tasks && tasks.items.length === 0 && (
                    <div className="empty">
                        <Icon isEmpty icon="empty-automations" size={300} />
                        <h1>{tr('No tasks')}</h1>
                    </div>
                )}
                {tasks && tasks?.items.length > 0 && (
                    <InfiniteScroll
                        next={actions.getTasks}
                        dataLength={tasks?.items.length || 0}
                        className="list"
                        hasMore={hasMore}
                        loader={fakeLoader}
                        scrollableTarget="scheduleContent"
                    >
                        {tasks &&
                            tasks.items.map((task) => (
                                <div className="list__item" key={task.id}>
                                    <div className="list__row">
                                        <div className="list__cols">
                                            <div className="list__col list__col--spacer" />
                                            <div className="list__col list__col--fill">
                                                <div className="team-avatar">
                                                    <Avatar
                                                        className="avatar"
                                                        src={task.lnuser?.lead.picture}
                                                        alt={`${task.lnuser?.lead.firstname} ${task.lnuser?.lead.lastname}`}
                                                    />
                                                    {task.lnuser && (
                                                        <span className="ellipsis">
                                                            {task.lnuser.lead.firstname}&nbsp;
                                                            {task.lnuser.lead.lastname}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="list__col list__col--large">
                                                {moment(task.scheduled_at).format('LLL')}
                                            </div>
                                            <div className="list__col list__col--large bold black">
                                                {task.action === 'VISIT' ? tr('Visit Profile') : ''}
                                                {task.action === 'DEL_INVITE' ? tr('Delete Request') : ''}
                                                {task.action === 'CONNECT' ? tr('Request Connection') : ''}
                                                {task.action === 'FOLLOW' ? tr('Follow Member') : ''}
                                                {task.action === 'LIKE' ? tr('Like Post') : ''}
                                                {task.action === 'MESSAGE1' ? tr('Send message') : ''}
                                                {task.action === 'MESSAGE_1' ? tr('Send message') : ''}
                                                {task.action === 'MESSAGE_OP' ? tr('Send message to OP') : ''}
                                                {task.action === 'MESSAGE_2' ? tr('Send Follow-up 1') : ''}
                                                {task.action === 'MESSAGE_3' ? tr('Send Follow-up 2') : ''}
                                                {task.action === 'MESSAGE_4' ? tr('Send Follow-up 3') : ''}
                                                {task.action === 'MESSAGE_5' ? tr('Send Follow-up 4') : ''}
                                                {task.action === 'MESSAGE_6' ? tr('Send Follow-up 5') : ''}
                                                {task.action === 'MESSAGE_7' ? tr('Send Follow-up 6') : ''}
                                            </div>

                                            <div className="list__col">
                                                {task.status === 'ERROR' && (
                                                    <TooltipPopover text={task.error} placement="left-center">
                                                        <span className="status status--red">{task.status}</span>
                                                    </TooltipPopover>
                                                )}
                                                {task.status === 'WARN' && (
                                                    <TooltipPopover text={task.error} placement="left-center">
                                                        <span className="status status--orange">{task.status}</span>
                                                    </TooltipPopover>
                                                )}
                                                {task.status !== 'ERROR' && task.status !== 'WARN' && (
                                                    <span className="status status--green">{task.status}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
}
export default CampaignTask;
