/* eslint-disable camelcase */
import { useRef, useState, useEffect } from 'react';
import { MovableCardWrapper } from 'react-trello/dist/styles/Base';
import { useRecoilState } from 'recoil';
import avatar from '../../images/avatar.svg';
import Avatar from '../../components/Avatar';
import LabelsList from '../lnuser/LabelsList';
import { tr } from '../../common/locale';
import utils from '../../common/utils';
import { noteBoardState } from '../../_states/lnusers.states';
import useLnUserActions from '../../_actions/lnuser.actions';
import useLnUsersActions from '../../_actions/lnusers.actions';
import useBoardActions from '../../_actions/board.actions';
import Dropdown from '../../components/Dropdown';
import Popover from '../../components/Popover';
import Button from '../../components/Button';

const CustomCard = ({
    className,
    id,
    name,
    headline,
    notes_count,
    picture,
    labels,
    cardStyle,
    last_message,
    is_connection,
}) => {
    const actions = useLnUserActions();
    const actionsRef = useRef();
    const notesRef = useRef();
    const usersActions = useLnUsersActions();
    const boardActions = useBoardActions();
    const [note, setNote] = useRecoilState(noteBoardState);
    const [showNote, setShowNote] = useState(false);
    const onClick = () => {
        actions.get(id, true);
    };
    const renderNoteContent = () => (note ? `${tr('Note:')} ${utils.nl2br(note[0].content)}` : tr('Loading note...'));
    useEffect(() => {
        if (showNote) {
            notesRef?.current?.open();
            boardActions.listNotesBoard(id);
        } else {
            notesRef?.current?.close();
            setNote(null);
        }
    }, [showNote]);
    const renderContent = () => (
        <>
            <Dropdown.ConfirmButtonItem
                label={tr('Remove from Pipeline')}
                placement="left-center"
                confirmLabel={tr('Do you confirm you want to remove this card?')}
                onConfirm={() => usersActions.removeboard({ id })}
            />
            <Dropdown.ConfirmButtonItem
                label={tr('Hide from Kanbox')}
                placement="left-center"
                confirmLabel={tr('Do you confirm you want to hide this member and remove from Pipeline?')}
                onConfirm={(e) => {
                    e.stopPropagation();
                    actions.archiveUser(id);
                }}
            />
        </>
    );
    return (
        <MovableCardWrapper
            onClick={onClick}
            style={cardStyle}
            className={`${className} ${is_connection ? 'relation' : 'contact'}`}
        >
            <LabelsList isNoBg isToggleIcon onClick={(e) => e.stopPropagation()} selectedLabels={labels || []} />
            {/* <div className="labels-list">
                <Button type="link" className="label-add" iconSize={22} icon="label-add" />
            </div> */}
            <Popover contentClassName="card-actions" ref={actionsRef} content={renderContent()}>
                <Button
                    icon="menu"
                    className="card__menu"
                    type="link"
                    onClick={(e) => {
                        e.stopPropagation();
                        actionsRef.current.toggle();
                    }}
                />
            </Popover>
            <div className="card__member">
                <Avatar className="card__member__avatar" src={picture || avatar} alt={name} />
                <div className="card__member__name">{name}</div>
            </div>
            <div className="card__headline">{headline}</div>
            {last_message && <div className="card__message">{last_message}</div>}
            {notes_count > 0 && (
                <Popover childClassName="card-notes" ref={notesRef} content={renderNoteContent()}>
                    <Button
                        icon="sticker"
                        className="card__note"
                        iconSize={16}
                        type="link"
                        onMouseEnter={(e) => {
                            e.stopPropagation();
                            setShowNote(true);
                        }}
                        onMouseLeave={(e) => {
                            e.stopPropagation();
                            setShowNote(false);
                        }}
                    />
                </Popover>
            )}
        </MovableCardWrapper>
    );
};

export default CustomCard;
