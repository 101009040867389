/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import moment from 'moment';
import { useSearchParams, Link } from 'react-router-dom';
import { tr, getLocale } from '../../common/locale';
import { userState, plansState, subscriptionsState, stripeState } from '../../_states/user.states';
import useUserActions from '../../_actions/user.actions';
import Switch from '../../components/Switch';
import Flash from '../../components/Flash';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import utils from '../../common/utils';
import ModalSubscribe from './modalsubscribe';
import AppsumoTacos from '../../images/appsumo/appsumo-tacos.webp';
import AppsumoImg from '../../images/appsumo/appsumo.webp';
import AppsumoImgDark from '../../images/appsumo/appsumo-dark.webp';

function Subscription() {
    const actions = useUserActions();
    const user = useRecoilValue(userState);
    const pricing = useRecoilValue(plansState);
    const [selectedPlan, setSelectedPlan] = useState('TRIAL');
    const [packValue, setPackValue] = useState(0);
    const [modalChangeSubscriptionOpen, setModalChangeSubscriptionOpen] = useState(false);
    const subscriptions = useRecoilValue(subscriptionsState);
    const stripe = useRecoilValue(stripeState);
    const [isMonth, setIsMonth] = useState(false);
    const modalSubscribe = useRef();
    const scrollContainerRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const subscriptionParam = searchParams.get('subscription');
    const currencyParam = searchParams.get('currency');
    const priceParam = searchParams.get('price');
    const sessionParam = searchParams.get('session_id');
    const open = searchParams.get('open');
    let currency = 'euro';
    let currencyIcon = '€';
    if (user?.ipinfo_country === 'US' || user?.ipinfo_country === 'CA') {
        currency = 'dollar';
        currencyIcon = '$';
    } else if (user?.ipinfo_country === 'IN') {
        currency = 'roupie';
        currencyIcon = '₹';
    } else if (user?.ipinfo_country === 'GB') {
        currency = 'pound';
        currencyIcon = '£';
    } else if (user?.ipinfo_country === 'PK') {
        currency = 'proupie';
        currencyIcon = 'Rs';
    }
    useEffect(() => {
        actions.getPlans();
        actions.getSubscriptions();
        actions.getStripe();
        actions.updateBrowsing();
    }, []);
    useEffect(() => {
        if (subscriptionParam && modalSubscribe.current) {
            modalSubscribe.current.open();
        }
        if (open) {
            setModalChangeSubscriptionOpen(true);
            if (searchParams.has('open')) {
                searchParams.delete('open');
                setSearchParams(searchParams);
            }
        }
    }, [user]);
    useEffect(() => {
        if (subscriptionParam && currencyParam && priceParam) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'purchase',
                value: `${priceParam}`,
                currency: currencyParam,
                transactionId: `${sessionParam}`,
            });
        }
        if (searchParams.get('bootcamp') && user?.plan?.product === 'TRIAL') {
            actions.checkout(false, 0, { essential: 1 }, false, false, 'LVrKnvk4');
        }
    }, [user]);
    const locale = getLocale();

    const marksPack = {
        0: '1 K',
        20: '5 K',
        40: '10 K',
        60: '20 K',
        80: '30 K',
        100: '50 K',
    };
    const marksPackValue = {
        0: 1000,
        20: 5000,
        40: 10000,
        60: 20000,
        80: 30000,
        100: 50000,
    };
    if (!user || (user.plan?.product === 'TRIAL' && !pricing)) {
        return null;
    }

    const hasSeats = subscriptions?.find(
        (sub) =>
            ['connect', 'scrape', 'fusion', 'admin', 'agency', 'starter', 'essential', 'pro'].indexOf(
                sub.product.toLowerCase()
            ) !== -1
    );

    const diffDayEndTrial = utils.trialEnd(user);
    if (!stripe || !subscriptions) {
        return (
            <div className="flex-axis fullwh">
                <Loader isChat text="Loading subscription..." />
            </div>
        );
    }

    if (!hasSeats && pricing) {
        return (
            <div ref={scrollContainerRef} className="subscription subscription--choose">
                <ModalSubscribe
                    pricing={pricing}
                    defaultIsMonth={isMonth}
                    currency={currency}
                    currencyIcon={currencyIcon}
                    defaultSliderPlanValue={0}
                    selectedPlan={selectedPlan}
                    closeCb={() => setSelectedPlan(user?.plan.product)}
                />
                <div className="subscription__header">
                    <h1>{tr('Subscription')}</h1>
                    {diffDayEndTrial > 0 && (
                        <Flash color="blue">{tr('{{count}} days free trial left.', { count: diffDayEndTrial })}</Flash>
                    )}
                    {diffDayEndTrial <= 0 && <Flash color="red">{tr('Your Trial has expired')}</Flash>}
                </div>
                <div className="billing-switcher">
                    <div className="billing-choice">
                        <div className="btn--tab-switch--group">
                            <Button
                                type="tab-switch"
                                disabled={isMonth}
                                onClick={() => setIsMonth(!isMonth)}
                                label={tr('Pay monthly')}
                            />
                            <Button
                                type="tab-switch"
                                disabled={!isMonth}
                                onClick={() => setIsMonth(!isMonth)}
                                label={tr('Pay annually')}
                            />
                        </div>
                    </div>
                    <h2 className={`title mt--20 ${isMonth && 'underline'}`}>
                        {isMonth
                            ? tr('No commitment, pay monthly, and cancel anytime')
                            : tr('Get advantageous rates with an annual subscription')}
                    </h2>
                </div>
                <div className="pricing__content">
                    <div className="pricing-layer">
                        <div className="pricing-wrapper">
                            {['starter', 'essential', 'pro'].map((current) => (
                                <div className="plans">
                                    {pricing[current].featured > 0 && (
                                        <div className="most-popular">
                                            <span className="most-popular-label">{tr('Most Popular')}</span>
                                        </div>
                                    )}
                                    <div className="plans-name">{pricing[current].name}</div>
                                    <div className="plans-desc">{pricing[current][locale]}</div>
                                    <div className="plans-price-wrapper">
                                        {currency !== 'euro' ? (
                                            <>
                                                {pricing[current].currency[currency].fix_monthly !== 0 && (
                                                    <>
                                                        <div className="plans-price-additionnal">
                                                            <span className="currency">{currencyIcon}</span>{' '}
                                                            <span className="plans-price">
                                                                {!isMonth
                                                                    ? pricing[current].currency[currency].fix_yearly
                                                                    : pricing[current].currency[currency].fix_monthly}
                                                            </span>{' '}
                                                            <span className="plans-price-details">{tr('/month')}</span>
                                                        </div>
                                                        {!isMonth && (
                                                            <div className="plans-yearly-details">
                                                                <span className="normal-price">
                                                                    <span className="currency">{currencyIcon}</span>{' '}
                                                                    {pricing[current].currency[currency].fix_monthly *
                                                                        12}
                                                                </span>{' '}
                                                                <span className="discount-price">
                                                                    <span className="currency">{currencyIcon}</span>{' '}
                                                                    {pricing[current].currency[currency].fix_yearly *
                                                                        12}{' '}
                                                                    <span className="plans-price-details">
                                                                        {tr('/year')}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                <div
                                                    className={`${
                                                        pricing[current].currency[currency].fix_monthly !== 0 &&
                                                        'plans-price-secondary'
                                                    }`}
                                                >
                                                    <span className="currency">{currencyIcon}</span>{' '}
                                                    <span className="plans-price">
                                                        {!isMonth
                                                            ? pricing[current].currency[currency].monthly
                                                            : pricing[current].currency[currency].yearly}
                                                    </span>{' '}
                                                    <span className="plans-price-details">
                                                        {pricing[current].team > 0 ? tr('/user/month') : tr('/month')}
                                                    </span>
                                                </div>
                                                {!isMonth && (
                                                    <div className="plans-yearly-details">
                                                        <span className="normal-price">
                                                            <span className="currency">{currencyIcon}</span>{' '}
                                                            {pricing[current].currency[currency].monthly * 12}
                                                        </span>{' '}
                                                        <span className="discount-price">
                                                            <span className="currency">{currencyIcon}</span>{' '}
                                                            {pricing[current].currency[currency].yearly * 12}{' '}
                                                            <span className="plans-price-details">
                                                                {tr('/user/year')}
                                                            </span>
                                                        </span>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {pricing[current].currency[currency].fix_monthly !== 0 && (
                                                    <>
                                                        <div className="plans-price-additionnal">
                                                            <span className="plans-price">
                                                                {!isMonth
                                                                    ? pricing[current].currency[currency].fix_yearly
                                                                    : pricing[current].currency[currency].fix_monthly}
                                                            </span>{' '}
                                                            <span className="currency">{currencyIcon}</span>{' '}
                                                            <span className="plans-price-details">{tr('/month')}</span>
                                                        </div>
                                                        {!isMonth && (
                                                            <div className="plans-yearly-details">
                                                                <span className="normal-price">
                                                                    {pricing[current].currency[currency].fix_monthly *
                                                                        12}{' '}
                                                                    <span className="currency">{currencyIcon}</span>
                                                                </span>{' '}
                                                                <span className="discount-price">
                                                                    {pricing[current].currency[currency].fix_yearly *
                                                                        12}{' '}
                                                                    <span className="currency">{currencyIcon}</span>{' '}
                                                                    <span className="plans-price-details">
                                                                        {tr('/year')}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        )}
                                                    </>
                                                )}

                                                <div
                                                    className={`plans-price-${
                                                        pricing[current].currency[currency].fix_monthly !== 0 &&
                                                        'secondary'
                                                    }`}
                                                >
                                                    <span className="plans-price">
                                                        {!isMonth
                                                            ? pricing[current].currency[currency].monthly
                                                            : pricing[current].currency[currency].yearly}
                                                    </span>{' '}
                                                    <span className="currency">{currencyIcon}</span>{' '}
                                                    <span className="plans-price-details">
                                                        {pricing[current].team > 0 ? tr('/user/month') : tr('/month')}
                                                    </span>
                                                </div>
                                                {!isMonth && (
                                                    <div className="plans-yearly-details">
                                                        <span className="normal-price">
                                                            {pricing[current].currency[currency].monthly * 12}{' '}
                                                            <span className="currency">{currencyIcon}</span>
                                                        </span>{' '}
                                                        <span className="discount-price">
                                                            {pricing[current].currency[currency].yearly * 12}{' '}
                                                            <span className="currency">{currencyIcon}</span>{' '}
                                                            <span className="plans-price-details">
                                                                {pricing[current].team > 0
                                                                    ? tr('/user/year')
                                                                    : tr('/year')}
                                                            </span>
                                                        </span>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <h4>{tr('Key Features')}</h4>
                                    <ul className="plans-limits">
                                        {pricing[current][`features_${locale}`].map((feat) => (
                                            <li key={feat}>
                                                <Icon size="18" icon="check" />
                                                <span>{feat}</span>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="pricing-cta">
                                        <Button label={tr('Subscribe')} onClick={() => setSelectedPlan(current)} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const subscription = subscriptions?.length > 0 ? subscriptions[0] : null;
    const getQuantity = (plan) => {
        let quantity = 0;
        if (subscriptions) {
            subscriptions.forEach((sub) => {
                if (sub.product.toLowerCase() === plan) {
                    quantity = sub.quantity;
                }
            });
        }
        return quantity;
    };
    const getSeatsUsed = (plan) => {
        let quantity = 0;
        if (subscriptions) {
            subscriptions.forEach((sub) => {
                if (sub.product.toLowerCase() === plan) {
                    quantity = sub.users_count;
                }
            });
        }
        return quantity;
    };

    const createdAt = moment(user?.plan.created_at);
    const renewDate = moment();

    renewDate.date(createdAt.date());
    renewDate.month(moment().month());
    renewDate.year(moment().year());
    if (moment().isAfter(renewDate)) {
        renewDate.month(moment().month() + 1);
    }

    const isTeamOwner = user && user.team && user.team.owner === user.id;
    const isPlanOwner = user && user.plan && user.plan.owner === user.id;
    const isAppsumo = !!user?.appsumo_code1;

    const emailAddonPack = () => (
        <div className="add-on">
            <div className="add-on__intro pb--0">
                <div>
                    <Icon isEmpty icon="empty-email-pack" size={200} />
                </div>
                <div>
                    <h2 className="title">{tr('Buy Email Credits Pack')}</h2>
                    <p className="fs-large mb--10">
                        {tr(
                            'Email Credit Packs do not have a time limit and can be used throughout your subscription to Kanbox.'
                        )}
                    </p>
                    <p className="fs-large blue mb--10">{tr('1 Email found = 1 Credit spent')}</p>
                    <p className="fs-large blue">{tr('No time limit')}</p>
                </div>
            </div>
            <div className="add-on__slider">
                <Slider marks={marksPack} step={null} value={packValue} onChange={(v) => setPackValue(v)} />
            </div>
            <div className="add-on__price">
                <div className="flex-grow">
                    <div className="add-on__price__info">
                        <span className="add-on__price__amount">
                            {currencyIcon} {pricing?.emails?.currency[currency].onetime[marksPack[packValue]]}
                        </span>
                    </div>
                    <div className="add-on__price__details">
                        {tr('Pack of')} {[marksPack[packValue]]} {tr('emails')}
                    </div>
                </div>
                <Button label={tr('Buy this Pack')} onClick={() => actions.checkoutPack(marksPackValue[packValue])} />
            </div>
        </div>
    );
    console.log('selectedPlan', selectedPlan);
    return (
        <div className="content account">
            <ModalSubscribe
                open={modalChangeSubscriptionOpen}
                pricing={pricing}
                currentPlan={user?.plan.product.toLowerCase()}
                currentQuantity={user?.plan.quantity}
                currency={currency}
                currencyIcon={currencyIcon}
                defaultIsMonth={stripe?.is_month}
                defaultSubscriptions={subscriptions}
                closeCb={() => setModalChangeSubscriptionOpen(false)}
            />
            <Modal
                className="welcome-message"
                labelValidate={tr('Close')}
                ref={modalSubscribe}
                closeCb={() => {
                    if (searchParams.has('subscription')) {
                        searchParams.delete('subscription');
                        setSearchParams(searchParams);
                    }
                }}
                title={tr('Your subscription is updated!')}
            >
                <div className="welcome-message__image">
                    <Icon isPlan size={340} icon={`plan-${subscriptionParam}`} />
                </div>
                <p className="welcome-message__main">{tr('Thanks for your subscription!')}</p>
            </Modal>

            <div className="content__body">
                <div className="account__wrapper">
                    <div className="account__right">
                        {isTeamOwner && (
                            <>
                                <div className="account__quotas">
                                    <h2 className="title">{tr('Leads import')}</h2>
                                    <div className="account__quotas__item">
                                        <p className="account__quotas__legend">{tr('Monthly limit')}</p>
                                        <h3 className="account__quotas__box">{user?.quotas.monthly_import_conf}</h3>
                                    </div>
                                    <div className="account__quotas__item">
                                        <p className="account__quotas__legend">{tr('Monthly Import Renew Date')}</p>
                                        <h3 className="account__quotas__box">{renewDate.format('LL')}</h3>
                                    </div>
                                    <h2 className="title">{tr('Subscribed Email Credits')}</h2>
                                    <p className="account__quotas__desc">
                                        {tr('Email enrichment credits allow you to retrieve members email addresses.')}
                                    </p>
                                    {!isAppsumo && (
                                        <p className="account__quotas__info">{tr('1 Email found = 1 Credit spent')}</p>
                                    )}
                                    {getQuantity('email_credits') > 0 && (
                                        <>
                                            <div className="account__quotas__item">
                                                <p className="account__quotas__legend">
                                                    {tr('Email Credits Renew Date')}
                                                </p>
                                                <h3 className="account__quotas__box">
                                                    {moment(stripe.renew_credits_date).format('LL')}
                                                </h3>
                                            </div>
                                            <div className="account__quotas__item">
                                                <p className="account__quotas__legend">
                                                    {tr('Recurring credits every month')}
                                                </p>
                                                <h3 className="account__quotas__box">{getQuantity('email_credits')}</h3>
                                            </div>
                                        </>
                                    )}

                                    <div className="account__quotas__item">
                                        <p className="account__quotas__legend">{tr('Available credits')}</p>
                                        <h3 className="account__quotas__box">{user?.team?.email_credits}</h3>
                                    </div>
                                </div>
                            </>
                        )}
                        {isAppsumo && (
                            <div className="appsumo-review">
                                <h1 className="appsumo-review__title">Hey {user.firstname},</h1>
                                <p className="appsumo-review__subtitle">We need your review!</p>
                                <div className="appsumo-review__image">
                                    <img src={AppsumoTacos} alt="How do you like Kanbox so far?" />
                                </div>
                                <p className="appsumo-review__message">
                                    How do you like Kanbox so far?
                                    <br />
                                    <strong>We need your help to gain visibility.</strong>
                                </p>
                                <a
                                    className="btn btn--primary"
                                    href="https://appsumo.com/products/kanbox/#reviews"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Review us on AppSumo
                                </a>
                            </div>
                        )}
                    </div>

                    <div className="account__left">
                        <div className="account__current">
                            <h1 className="title">
                                <span>
                                    {isAppsumo
                                        ? user.plan?.product === 'FUSION' || user.plan?.product === 'PRO'
                                            ? 'APPSUMO TIER 2'
                                            : 'APPSUMO TIER 1'
                                        : user.plan?.product}
                                </span>
                            </h1>
                            <div className="account__info">
                                {subscription && user.plan?.product !== 'FREE' && (
                                    <>
                                        {stripe && stripe.renew_date && (
                                            <div className="account__date">
                                                <p>{tr('Subscription Renew date:')}</p>
                                                <div className="account__date__box">
                                                    {moment(stripe.renew_date).format('LL')}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                                <div className="account__seats">
                                    <p>{tr('Subscribed seats')}</p>
                                    <div className="account__seats__wrapper">
                                        <div className="account__seat__count">
                                            {getSeatsUsed(user?.plan.product.toLowerCase())} /{' '}
                                            {getQuantity(user?.plan.product.toLowerCase())}
                                        </div>
                                    </div>
                                    <p>{tr(' Used seats')}</p>
                                </div>
                            </div>
                        </div>
                        {isAppsumo && (
                            <div className="account__appsumo">
                                <div className="account__appsumo__top">
                                    <div>
                                        <img
                                            className="appsumo-img-white"
                                            src={AppsumoImg}
                                            alt={tr('AppSumo code subscription')}
                                        />
                                        <img
                                            className="appsumo-img-dark"
                                            src={AppsumoImgDark}
                                            alt={tr('AppSumo code subscription')}
                                        />
                                    </div>
                                    <h2 className="title">You have subscribed to an Appsumo Life-Time Deal</h2>
                                    {user.plan?.product === 'ESSENTIAL' && (
                                        <div>
                                            <Link className="btn btn--primary" to="/appsumo">
                                                <span className="btn__label">{tr('+ Use another Appsumo Code')}</span>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                                <div className="account__appsumo__bottom">
                                    {isAppsumo && isPlanOwner && (
                                        <>
                                            <div className="add-on">
                                                {!stripe?.id && (
                                                    <>
                                                        <div className="add-on__intro">
                                                            <div>
                                                                <Icon isEmpty icon="empty-automations" size={200} />
                                                            </div>
                                                            <div>
                                                                <h2 className="title">
                                                                    {tr('Subscribe to Automations')}
                                                                </h2>
                                                                <p className="fs-large mb--10">
                                                                    {tr(
                                                                        'Automatically trigger profile visits, connection requests, messages and follow-ups. Customize messages using templates and variables. Track campaigns in real-time with automated CRM Pipelines, seamlessly transitioning leads across columns.'
                                                                    )}
                                                                </p>
                                                                <p className="fs-large blue">
                                                                    {tr(
                                                                        'Automations are an add-on that you subscribe to on a monthly basis.'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="add-on__price">
                                                            <div className="flex-grow">
                                                                <div className="add-on__price__info">
                                                                    <span className="add-on__price__amount">
                                                                        {currencyIcon} {isMonth ? '25' : '20'}
                                                                    </span>
                                                                    <span className="add-on__price__period">
                                                                        per month
                                                                    </span>
                                                                </div>
                                                                <div className="add-on__price__switch">
                                                                    <span>{tr('Yearly')}</span>
                                                                    <Switch
                                                                        id="appsumoPricing"
                                                                        checked={isMonth}
                                                                        onChange={(val) => setIsMonth(val)}
                                                                    />
                                                                    <span>{tr('Monthly')}</span>
                                                                </div>
                                                            </div>
                                                            <Button
                                                                label={tr('Subscribe')}
                                                                onClick={() => actions.checkoutAutomations(isMonth)}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {stripe?.id && (
                                                    <>
                                                        <div className="add-on__intro">
                                                            <div>
                                                                <Icon isEmpty icon="empty-automations" size={200} />
                                                            </div>
                                                            <div>
                                                                <h2 className="title mb--30">
                                                                    {tr('You are subscribed to the automations addon.')}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div className="add-on__price">
                                                            <div className="flex-grow" />
                                                            <Button
                                                                label={tr('Manage subscription and invoices')}
                                                                onClick={() => actions.portal()}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {!user?.appsumo_newplan && (
                                        <div className="account__appsumo__changes">
                                            <h3 className="red">Important information regarding your subscription</h3>
                                            {!user?.appsumo_code2 && (
                                                <div className="account__appsumo__plans-compair">
                                                    <div className="account__appsumo__plan">
                                                        You have a <strong>TIER 2 Appsumo</strong> plan with:
                                                        <ul>
                                                            <li>
                                                                <strong>up to 2500 Export Credits / day*</strong>
                                                            </li>
                                                            <li>2,000 Email Credits / month**</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                            {user?.appsumo_code2 && (
                                                <div className="account__appsumo__plans-compair mb--30">
                                                    <div className="account__appsumo__plan">
                                                        You have a <strong>TIER 2 Appsumo</strong> plan with:
                                                        <ul>
                                                            <li>
                                                                <strong>up to 2500 Export Credits / day*</strong>
                                                            </li>
                                                            <li>10,000 Email Credits / month**</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}

                                            <p className="mb--20">
                                                * The exports are limited to 2500 users / day if you have Sales
                                                Navigator and 80 users / day if you have a free Linkedin Account. This
                                                quota is necessary to prevent your account from being banned.
                                            </p>
                                            <p>
                                                <strong className="underline">
                                                    ** Credits are reset every month. 1 credit per one email searched.
                                                </strong>
                                            </p>
                                        </div>
                                    )}
                                    {isPlanOwner && isAppsumo && emailAddonPack()}
                                </div>
                            </div>
                        )}
                        {!isAppsumo && stripe && stripe.is_stripe && (
                            <>
                                <div className="account__features">
                                    <h4>{tr('Key Features')}</h4>
                                    <ul className="plans-limits">
                                        <li>
                                            <Icon size="18" icon="check" />
                                            <span>
                                                {tr('Simultaneous Campaign: {{campaigns}}', {
                                                    campaigns: user?.plan.max_campaigns,
                                                })}
                                            </span>
                                        </li>
                                        <li>
                                            <Icon size="18" icon="check" />
                                            <span>
                                                {tr('Lead Import: {{leads}} /month', {
                                                    leads: user?.quotas.monthly_import_conf,
                                                })}
                                            </span>
                                        </li>
                                        <li>
                                            <Icon size="18" icon="check" />
                                            <span>Cloud-based</span>
                                        </li>
                                        <li>
                                            <Icon size="18" icon="check" />
                                            <span>Email Finder</span>
                                        </li>
                                        <li>
                                            <Icon size="18" icon="check" />
                                            <span>{tr('Smart Linkedin Inbox with Tags and Filters')}</span>
                                        </li>
                                        {user.plan.has_team && (
                                            <li>
                                                <Icon size="18" icon="check" />
                                                <span>{tr('Team / Multi-account (Coming soon)')}</span>
                                            </li>
                                        )}
                                        {user.plan.has_webhooks && (
                                            <li>
                                                <Icon size="18" icon="check" />
                                                <span>Webhooks / API</span>
                                            </li>
                                        )}
                                    </ul>
                                    <div className="account__actions">
                                        {stripe.is_new_subscription && (
                                            <Button
                                                label={tr('Upgrade subscription')}
                                                onClick={() => setModalChangeSubscriptionOpen(true)}
                                            />
                                        )}
                                        <Button
                                            isBordered
                                            type="transparent"
                                            label={tr('See Payment details and invoices')}
                                            onClick={() => actions.portal()}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {isPlanOwner && !isAppsumo && stripe?.is_stripe && emailAddonPack()}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Subscription;
